import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import HomeUpdate from "./HomeUpdate";
import { Link } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import Contextapi from "../../../Contextpage/Contextapi";
import Siteconfigform from "../Subpages/Siteconfigform";

const Homeseo = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [siteConfig, setSiteConfig] = useState(null); 
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };
  const contextData = useContext(Contextapi);
  const domainName = contextData.domainapi;
  const keyId = contextData.keyID;

  const params = useMemo(() => ({
    Client_ID: keyId,
  }), [keyId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainName}/api/get-homepage`, { params });
        setSiteConfig(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);  // Stop loading on error as well
      }
    };
    fetchData();
  }, [formOpen, params, domainName]);

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  if (!siteConfig) {
    return (
      <div className="container">
        <div className="row p-5">
          <div className="col-12 p-1">
            <p>Failed to load the site configuration.</p>
          </div>
        </div>
      </div>
    );
  }
  const renderMedia = (url) => {
    if (!url) return null;

    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return (
        <img
          src={url}
          title={siteConfig.title}
          alt={siteConfig.title}
          className="img-fluid"
        />
      );
    } else if (extension === 'mp4') {
      return (
        <video autoPlay controls className="img-fluid">
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <p>Unsupported media type</p>;
    }
  };
  // console.log("home data ", siteConfig)

  return (
    <section className="upshowing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12" id="nav">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb p-3">
                <li className="breadcrumb-item">
                  <Link
                    to="/admin-dashboard/home/"
                    title="Home page"
                    className="text-decoration-none">
                    Admin
                  </Link>
                </li>
                <li className="ms-1" aria-current="page">
                  {'/'} Home-Section
                </li>
              </ol>
            </nav>
          </div>
        </div>

        {formOpen ? (
          <HomeUpdate handleUpdate={handleUpdate} />
        ) : (
          <div className="">
            <div className="row align-items-center">
              <div className="col-2 upbtn d-flex justify-content-end">
                <button onClick={handleUpdate} className="">
                  <FaPen className="py-0.5" /> Update
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card card_design p-3 mt-3">
                  <div className="row">
                    <div className="col-md-5">
                    {renderMedia(siteConfig.home_image)}
                    </div>
                    <div className="col-md-7">
                      <div className="first">
                        <div className="main_head">
                          <h5>Title: {siteConfig.title}</h5>
                        </div>
                        <div className="content">
                          <h5>Description</h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: siteConfig.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card_design p-3 mt-3">
                  <h3>SEO Parameters</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <h4>SEO Title</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p>{siteConfig.seo_title}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <h4>SEO Keywords</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p>{siteConfig.seo_keywords}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <h4>SEO Description</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p>{siteConfig.seo_description}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <h4>Canonical Links</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p>{siteConfig.canonical_links}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Homeseo;