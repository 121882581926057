import React, { useContext, useEffect, useMemo, useState } from 'react'
import jobdetailbg from '../../../assets/images/job-detail-bg.jpg';
import jobcompany from '../../../assets/images/jobs-company/pic1.jpg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Contextapi from '../../Contextpage/Contextapi';
import axios from 'axios';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { BsCurrencyRupee } from 'react-icons/bs';
import { Helmet } from 'react-helmet';

const JobViewSingle = () => {
  const [loading, setLoading] = useState(true);
  const [Jobs, setJobs] = useState([]);
  const GetEmpid = useMemo(() => localStorage.getItem("id") || "", []);
  const token = useMemo(() => localStorage.getItem("token") || "", []);
  const path = useMemo(() => localStorage.getItem("path") || "", []);

  const pp = path && path ? path.replace(/"/g, "") : "";
  const empid = GetEmpid && GetEmpid ? GetEmpid.replace(/"/g, "") : "";
  const { slug } = useParams();
  const id = slug;
  const [formData, setFormdata] = useState({
    applied_employee_id: empid,
    company_id: Jobs?.company_id ?? '',
    job_post_id: Jobs?.id ?? '',
  })
  const contextData = useContext(Contextapi);
  const domainname = contextData.domainapi;
  const keyid = contextData.keyID;
  const navigate = useNavigate();
  // const params = useMemo(
  //   () => ({
  //     Client_ID: keyid,
  //   }),
  //   [keyid]
  // );
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // const JobsData = await contextData.APIdata.jobs;
        // const singlejobs = JobsData.filter(job => job.id == id);
        const response = await axios.get(`${domainname}/api/get-single-job-post/${id}`, {
          params
            : {
            Client_ID: keyid,
          }
        });

        const singlejobs = response.data
        setJobs(singlejobs[0]);
        setFormdata({
          applied_employee_id: empid,
          company_id: singlejobs[0].company_id,
          job_post_id: singlejobs[0].id
        })
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    fetchCategoryData();
  }, [contextData, id, empid]);



  // console.log("jobs", Jobs);
  const calculateDaysAgo = (dateString) => {
    if (!dateString) return 0;
    const currentDate = new Date();
    const jobDate = new Date(dateString);
    const differenceInTime = currentDate.getTime() - jobDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.floor(differenceInDays);
  };
  if (loading) {
    return (
      <div className="bodyloading">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  const handleapplied = async (e) => {
    e.preventDefault();
    const mypath = 'employee-dashboard/home';
    // console.log(mypath,path)
    if (!(pp == mypath && token)) {
      console.log("this is message show token and path not ")
      Swal.fire({
        icon: "error",
        text: "Please log in to apply for this job",
      });
      navigate('/login')
      return;
    }
    try {
      await axios.post(`${domainname}/api/add-jobapplication`, formData, {
        params
          : {
          Client_ID: keyid,
        }
      });
      // console.log("cvxvxvxv", response);
      Swal.fire({
        position: "top-end",
        icon: "success",
        text: "Suceesfully Applied ",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log("axios-error", error);
      if (error.response && error.response.status === 416) {
        Swal.fire({
          icon: "warning",
          toast: true,
          text: error.response.data.repeatmessage || "An error occurred. Please try again later",
        });
      }
      Swal.fire({
        icon: "warning",
        text: error.response.data.repeatmessage || "error occurred. Please try again later",
      });

    }
  }
  // console.log("single job view", Jobs)

  return (
    <div className="page-content">
      {/* INNER PAGE BANNER */}
      <Helmet>
        <title>{Jobs && Jobs.seo_title ? Jobs.seo_title : 'R&G SHRAMIK MITRA - Find Latest Jobs, Employment Opportunities | Job Portal'}</title>
        <meta name="description" content={Jobs.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
        <meta name="keywords" content={Jobs.seo_keywords || "job portal, latest jobs, employment opportunities, job vacancies, job search, job seekers, career opportunities, city jobs ,hired labor"} />
        <meta name="author" content="Frontend: Rohit Patel, Akash Lowanshi & Backend: Udit Chouhan" />
        {/* <meta name="publisher" content="Rohit Patel" /> */}
      </Helmet>
      <div
        className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center"
      >
        <div className="overlay-main site-bg-white opacity-01" />
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title text-capitalize">{Jobs.name || 'Company  '}</h2>
              </div>
            </div>
            {/* BREADCRUMB ROW */}
            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li>
                  <Link to="/" title='home page'>Home</Link>
                </li>
                <li>{Jobs.job_title || " "}</li>
              </ul>
            </div>
            {/* BREADCRUMB ROW END */}
          </div>
        </div>
      </div>
      {/* INNER PAGE BANNER END */}
      {/* OUR BLOG START */}
      <div className="section-full  p-t120 p-b90 bg-white">
        <div className="container">
          {/* BLOG SECTION START */}
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                {/* Candidate detail START */}
                <div className="cabdidate-de-info">
                  <div className="twm-job-self-wrap">
                    <div className="twm-job-self-info">
                      <div className="twm-job-self-top">
                        <div className="twm-media-bg">
                          <img src={'https://img.freepik.com/free-photo/business-background-design_1156-843.jpg?t=st=1724319953~exp=1724323553~hmac=98795894b115e90ee70cc0eb04687cc3b3a1df9e1680ca6f8269b6d3bae076e6&w=900' || jobdetailbg} alt={Jobs.job_title} title={Jobs.job_title || "Job-image"} />
                          <div className="twm-jobs-category green">
                            <span className="twm-bg-green">{Jobs.job_type || "New"}</span>
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <div className="twm-media d-none">
                            <img src={Jobs.company_logo || jobcompany} alt={Jobs.job_title || "Cmp-logo"} title={Jobs.job_title || "job-logo"} loading='lazy' />
                          </div>
                          <h2 className="twm-job-title">
                            {Jobs.job_title || "Job title is not found !"}
                            <span className="twm-job-post-duration">
                              {' '} / Posted: <span className="twm-job-post-duration">
                                {`${calculateDaysAgo(Jobs.updated_at)} days ago`}
                              </span>
                            </span>
                          </h2>

                          <div className="twm-job-self-mid">
                            <div className="twm-job-self-mid-left">
                              <div className="twm-jobs-amount ">
                                <p > Job Category: <span className='text-secondary mr-1' >{Jobs.job_category || "Job title is not found !"} </span></p>
                              </div>


                              <div className='twm-right-content '>

                                <div className="twm-jobs-amount">
                                  <p >Salary:<BsCurrencyRupee /> <span className='text-secondary'>{Jobs.package || '---'} </span> <span> /Month</span> </p>
                                </div>
                                <div className="twm-jobs-amount">
                                  <p>Eligibility: <span className='text-secondary'>{Jobs.eligibility || '---'} </span></p>
                                </div>

                                <div className="twm-jobs-amount">
                                  <p >Experienced: <span className='text-secondary'>{Jobs.experienced_required || '---'}</span> </p>
                                </div>
                              </div>

                              <div className="twm-jobs-amount">
                                Address: {' '}
                                <span className="twm-job-address text-secondary">

                                  <i className="feather-map-pin" />
                                  {Jobs.job_address || '1363-1385 India'}
                                </span>
                              </div>
                            </div>
                            <div className="twm-job-apllication-area gap-1">
                              Job End Date: {' '}
                              <span className="twm-job-apllication-date">
                                {format(Jobs.job_end_date || "__/__/____", 'd-MMMM-y')}
                              </span>

                              <div className="twm-right-content">
                                <div className="twm-jobs-browse  my-1 ">
                                  Job Mode: <span className="text-secondary">{Jobs.job_mode}</span>
                                </div>
                                <div className="twm-jobs-browse  mb-1 ">
                                  Total Vacancy: <span className="text-secondary">{Jobs.total_post}</span>
                                </div>
                                <div className="twm-jobs-browse  mb-1  ">
                                  State: <span className="text-secondary">{Jobs.job_state}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="twm-job-self-bottom">
                            <button
                              className="site-button"

                              onClick={handleapplied}
                            >
                              Apply Now
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="twm-job-self-bottom applied-btn">
                    <button
                      className="site-button"
                      title='Applied | Apply For This Application'
                      onClick={handleapplied}
                    >
                      Apply Now
                    </button>
                  </div>
                  <h4 className="twm-s-title">Job Description:</h4>
                  <p dangerouslySetInnerHTML={{ __html: Jobs.job_description }} />


                  {/* <h4 className="twm-s-title">Job Description:</h4>
                  <ul className="description-list-2">
                    <li>
                      <i className="feather-check" />
                      <span dangerouslySetInnerHTML={{ __html: Jobs.job_description }} />
                    </li>
                    <li>
                      <i className="feather-check" />
                      Personally passionate and up to date with current trends and
                      technologies, committed to quality and comfortable working
                      with adult media.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Rachelor or Master degree level educational background.
                    </li>
                    <li>
                      <i className="feather-check" />4 years relevant PHP dev
                      experience.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Troubleshooting, testing and maintaining the core product
                      software and databases.
                    </li>
                  </ul>
                  <h4 className="twm-s-title">Responsabilities:</h4>
                  <ul className="description-list-2">
                    <li>
                      <i className="feather-check" />
                      Establish and promote design guidelines, best practices and
                      standards.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Accurately estimate design tickets during planning sessions.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Partnering with product and engineering to translate business
                      and user goals into elegant and practical designs. that can
                      deliver on key business and user metrics.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Create wireframes, storyboards, user flows, process flows and
                      site maps to communicate interaction and design.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Present and defend designs and key deliverables to peers and
                      executive level stakeholders.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Execute all visual design stages from concept to final
                      hand-off to engineering.
                    </li>
                  </ul> */}

                  {/* <h4 className="twm-s-title">Location</h4>

                  <div className="twm-m-map mb-5">
                    <div className="twm-m-map-iframe">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d281700.1440684808!2d76.12283869530656!3d21.71039577027113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd81653da9b0e4d%3A0x6f9dac841c49e7ab!2sPandhana%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1710324594868!5m2!1sen!2sin" height="310" />

                    </div>
                  </div> */}

                </div>
              </div>
              <div className="col-lg-4 col-md-12 rightSidebar">
                <div className="twm-s-info3-wrap mb-5">
                  <div className="twm-s-info3">
                    <div className="twm-s-info-logo-section">
                      <div className="twm-media">
                        <img src={Jobs.company_logo || jobcompany} alt={Jobs.job_title || "Cmp-logo"} title={Jobs.job_title || "job-logo"} loading='lazy' />
                      </div>
                      {/* <h4 className="twm-title"> {Jobs.job_title || "Job title is not found!"} </h4> */}
                    </div>
                    <ul>
                      <li>
                        <div className="twm-s-info-inner">
                          <i className="fas fa-building" />

                          <div className="twm-s-info-discription">

                            {Jobs.name || 'Company'}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="twm-s-info-inner">
                          <i className="fas fa-mobile-alt" />

                          <div className="twm-s-info-discription">
                            {Jobs.mobile || "9131090474"}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="twm-s-info-inner">
                          <i className="fas fa-at" />

                          <div className="twm-s-info-discription">
                            {Jobs.email || 'rgshramikmitra@gmail.com'}
                          </div>
                        </div>
                      </li>

                      {

                        !Jobs.industry_type || Jobs.industry_type === "null" ? " " :
                          <li>
                            <div className="twm-s-info-inner">
                              <i className="fa-solid fa-industry" />

                              <div className="twm-s-info-discription">
                                {Jobs.industry_type}
                              </div>
                            </div>
                          </li>
                      }

                      {
                        !Jobs.company_website || Jobs.company_website === "null" ?
                          " " :
                          <li>
                            <div className="twm-s-info-inner">
                              <i className="fas fa-desktop" />

                              <div className="twm-s-info-discription">
                                <Link
                                  href={Jobs.company_website}
                                  className="twm-job-websites site-text-primary"
                                  target='_blank'
                                >
                                  {Jobs.company_website}
                                </Link>
                              </div>
                            </div>
                          </li>
                      }

                      {
                        !Jobs.job_address || Jobs.job_address === "null" ? "" :
                          <li>
                            <div className="twm-s-info-inner">
                              <i className="fas fa-map-marker-alt" />
                              {/* <span className="twm-title">Address</span> */}
                              <div className="twm-s-info-discription ">
                                {Jobs.job_address}
                              </div>
                            </div>
                          </li>
                      }
                    </ul>
                    <Link
                      to={Jobs.company_website || "https://www.rgshramikmitra.com/"}
                      className=" site-button white "
                      target='_blank'>Vew Profile
                    </Link>
                  </div>
                  {/* <div
                    className="twm-advertisment"
                    style={{ backgroundImage: "url(images/add-bg.jpg)" }}
                  >
                    <div className="overlay my-3" />
                    <h4 className="twm-s-title text-light">Share Profile</h4>
                    <div className="twm-social-tags my-3">
                      <a className="fb-clr">
                        Facebook
                      </a>
                      <a className="tw-clr">
                        Twitter
                      </a>
                      <a className="link-clr">
                        Linkedin
                      </a>
                      <a className="whats-clr">
                        Whatsapp
                      </a>
                      <a className="pinte-clr">
                        Pinterest
                      </a>
                    </div>
                    <h4 className="twm-title">Recruiting?</h4>
                    <p>
                      Get Best Matched Jobs On your
                      !
                    </p>
                    <Link to="/view-jobs" title='all-jobs' className="site-button white">
                      View More
                    </Link>
                  </div> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OUR BLOG END */}
    </div>
  )
}

export default JobViewSingle