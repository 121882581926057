import React, { useState } from 'react';
import loginpic from '../../assets/images/loginpic.png';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

const AdminLogin = () => {
    const [showModal, setShowModal] = useState(false);
    const [emailForReset, setEmailForReset] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [viewPassword, setViewPassword] = useState(false);

    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const res = await axios.post('https://portal.rgshramikmitra.com/api/admin-login', formData);
            // console.log("loginresponse", res);
            const data = res.data;

            if (res.status === 200) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('id', JSON.stringify(data.id));
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Logged in successfully',
                });

                if (data.role === "admin") {
                    localStorage.setItem('path', JSON.stringify('admin-dashboard/home'));
                    navigate('/admin-dashboard/home');
                }

                // else if (data.role === "company") {
                //     localStorage.setItem('path', JSON.stringify('company-dashboard'));
                //     navigate('/company-dashboard');
                // } else if (data.role === "agent") {
                //     localStorage.setItem('path', JSON.stringify('agent-dashboard'));
                //     navigate('/agent-dashboard');
                // } else if (data.role === "employee") {
                //     localStorage.setItem('path', JSON.stringify('employee-dashboard'));
                //     navigate('/employee-dashboard');
                // }
                // console.warn("login data", data);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Try Again",
                    text: 'Useremail or Password Incorrect',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Try Again",
                text: 'Useremail or Password Incorrect',
            });
            console.log(error, 'something is wrong');
        }
    };
    const handleForgotPassword = () => {
        setShowModal(true);
    };
    const handleResetPassword = () => {
        if (!emailForReset) {
            Swal.fire({
                icon: "error",
                title: "Email Required",
                text: 'Please enter your registered email address.',
            });
            return;
        }

        axios.post('https://portal.rgshramikmitra.com/api/reset-admin-password', { email: emailForReset })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: response.data.message || 'Password reset instructions sent',
                    text: 'Please check your email for further instructions.',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                setShowModal(false);
                // console.log("forget response ", response)
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'This Email Not Registered ',
                    text: error.response?.data?.error || 'An error occurred, please try again.',
                });
                console.error("Forget API ", error)
            });
    };
    return (
        <div id="main-wrapper" className="container">
            <div className="row justify-content-center">
                <div className="login col-xl-10">
                    <div className="twm-tabs-style-2 card">
                        <div className="  card-body p-0">
                            <div className="row no-gutters">
                                <div className="col-lg-6">
                                    <div className=" lpding">
                                        <div className="mb-5">
                                            <h3 className="h4 font-weight-bold text-theme">Login</h3>
                                        </div>
                                        <h6 className="h5 mb-0">Welcome back!</h6>
                                        <p className="text-muted mt-2 mb-4">
                                            Enter your email id and password
                                        </p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="text"
                                                    name='email'
                                                    placeholder="Enter your email"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-3 mainpass">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    name='password'
                                                    type={viewPassword ? 'text' : 'password'}
                                                    placeholder="Enter your password"
                                                    className="form-control passfiled"
                                                    onChange={handleChange}
                                                />
                                                <p className='password-eye' onClick={passwordViewHandler}> {viewPassword ? <FaEye /> : <FaRegEyeSlash />} </p>
                                                <button type="button" onClick={handleForgotPassword} className="forgot-link forgot-btn btn float-end my-3">
                                                    Forgot password?
                                                </button>
                                            </div>
                                            <div className="col-md-12">
                                                <button type="submit" className="site-button">
                                                    Login Now
                                                </button>
                                                <p className="text-muted  mt-3 mb-0 ">
                                                    Don't have an account?{" "}
                                                    <Link to="/registration" className="text-primary">
                                                        Register
                                                    </Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-none mt-5 d-lg-inline-block">
                                    <img className='cimg' src={loginpic} alt="Admin Login " title='Login | Admin Access Dashabord ' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* Forgot Password Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} className='forget-content'>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type='email'
                        placeholder='Enter Your Registered Email'
                        className="form-control "
                        value={emailForReset}
                        onChange={(e) => setEmailForReset(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleResetPassword}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        </div>
    );
}

export default AdminLogin;
