import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Contextapi from "../../Contextpage/Contextapi";
import { TiArrowBack } from "react-icons/ti";
const EditAgentprofile = ({ handleUpdate }) => {
  const getid = useMemo(() => localStorage.getItem("id") || "", []);
  const id = getid.replace(/"/g, "");
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyID = contextdata.keyID;

  const [formData, setFormData] = useState({
    agent_id: id,
    name: "",
    email: "",
    mobile: "",
    alternate_mobile: "",
    alternate_email: "",
    city: "",
    state: "",
  });

  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-agent/${id}`, {
          params: {
            Client_ID: keyID,
          },
        });
        setFormData(response.data[0]);
      } catch (error) {
        console.error("Error fetching agent data:", error);
      }
    };
    fetchAgentData();
  }, [domainname, id, keyID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${domainname}/api/update-agent/${id}`,
        formData,
        {
          params: {
            Client_ID: keyID,
          },
        }
      );
      console.log(response)
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.message || "Data updated successfully!",
        });
        handleUpdate();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error in updating the data. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error updating agent data:", error);
      Swal.fire({
        icon: "error",
        text: error.response.data.message || "An error occurred. Please try again later.",
      });
    }
  };
  return (
    <div className="container">
      <div className="upbtn">
        <button onClick={handleUpdate}>
          {" "}
          <TiArrowBack /> back{" "}
        </button>
      </div>
      <form
        className="agent-profile-update shadow px-5 py-4"
        onSubmit={handleSubmit}
        encType="multipart/form-data">
        <h4 className="agent-form-heading">
          Profile Update Form  : -

        </h4>
        <div className="row m-3">
          <div className="col-lg-6 ">
            <label htmlFor="name" className="form-group">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleInputChange}
              value={formData.name}
              className="form-control"
              placeholder="Enter your name"
              readOnly
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="email" className="form-group">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              value={formData.email}
              className="form-control"
              placeholder="Enter your email"
              readOnly
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="mobile" className="form-group">Mobile Number</label>
            <input
              type="tel"
              name="mobile"
              onChange={handleInputChange}
              value={formData.mobile}
              className="form-control"
              placeholder="Enter your mobile number"
              readOnly
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="alternate_mobile" className="form-group">Alternate Mobile Number</label>
            <input
              type="tel"
              name="alternate_mobile"
              onChange={handleInputChange}
              value={formData.alternate_mobile}
              className="form-control"
              placeholder="Enter your alternate mobile number"
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="alternate_email" className="form-group">Alternate Email</label>
            <input
              type="email"
              name="alternate_email"
              onChange={handleInputChange}
              value={formData.alternate_email}
              className="form-control"
              placeholder="Enter your alternate email"
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="city" className="form-group">City</label>
            <input
              type="text"
              name="city"
              onChange={handleInputChange}
              value={formData.city}
              className="form-control"
              placeholder="Enter your city"
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="state" className="form-group">State</label>
            <input
              type="text"
              name="state"
              onChange={handleInputChange}
              value={formData.state}
              className="form-control"
              placeholder="Enter your state"
            />
          </div>
        </div>
        <div className="d-flex float-right">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
export default EditAgentprofile;
