import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Contextapi from "../../Contextpage/Contextapi";

const Galleryhome = () => {
    const [gallery, setGallery] = useState([]);
    const contextData = useContext(Contextapi);
    const [Loading, setLoading] = useState(true)

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    useEffect(() => {
        if (contextData.APIdata && contextData.APIdata.gallery) {
            const data = contextData.APIdata.gallery;
            setGallery(data);
            setLoading(false)
        }
    }, [contextData]);

    const images = gallery.map((item, index) => ({
        src: item.gallery_image,
        width: 4,
        height: 4,
        title: item.title,
    }));
    if (Loading) {
        return (
            <div className="bodyloading">
                <div className="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        );
    }

    return (
        <section className="gallery-block grid-gallery">
            {/* INNER PAGE BANNER */}
            <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer ">
                            <div className="banner-title-name ">
                                <h3 className="wt-title pt-3">Gallery</h3>
                            </div>
                            {/* BREADCRUMB ROW */}
                            <div>
                                <ul className="wt-breadcrumb breadcrumb-style-2 pt-2">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>Gallery</li>
                                </ul>
                            </div>
                            {/* BREADCRUMB ROW END */}
                        </div>
                    </div>
                </div>
            </div>
            {/* INNER PAGE BANNER END */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 my-5">
                        {gallery.length > 0 && (
                            <Gallery photos={images} onClick={openLightbox} />
                        )}
                        <ModalGateway>
                            {viewerIsOpen ? (
                                <Modal onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={gallery.map((item) => ({
                                            src: item.gallery_image || '',
                                            caption: item.title || '',
                                        }))}
                                    />

                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Galleryhome;
