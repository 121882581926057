import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { FaPen } from "react-icons/fa";
// import { FaLocationDot } from "react-icons/fa6";
// import { IoCall } from "react-icons/io5";
// import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import EmpProfileupdate from "./EmpProfileupdate";
import Contextapi from "../../Contextpage/Contextapi";
import { MdBackHand } from "react-icons/md";
import { format } from 'date-fns';
const EmpProfile = () => {
  const Id = useMemo(() => localStorage.getItem("id") || "", []);
  const id = Id.replace(/"/g, "");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [totalJob, setTotalJob] = useState(0);

  const contextData = useContext(Contextapi);
  const domainName = contextData.domainapi;
  const keyId = contextData.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyId,
    }),
    [keyId]
  );

  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          `${domainName}/api/get-employee/${id}`,
          { params }
        );

        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetch();
  }, [formOpen, params, domainName, id]);

  useEffect(() => {

    const getjobs = async () => {
      const res = await axios.get(`${domainName}/api/get-jobpost`, { params });

      // console.log("Alljob post", res.data)
      setTotalJob(res.data.length);
    }

    getjobs();

  }, [])

  // console.log(data)

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="container">
        <div className="row p-5">
          <div className="col-12 p-1">
            <LinearProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container profile_of_student mt-1 mb-5 upshowing common_dash">
      <div className="row">
        <div className="col-lg-8 col-12 " id="nav">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
              <li className="breadcrumb-item">
                <Link
                  to="/emloyee-dashboard/home"
                  title="Home page"
                  className="text-decoration-none"
                >
                  Profile
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {'/'} {data.name ? ` Welcome - ${data.name} ` : 'Profile'} <MdBackHand className="text-warning anim" /> !
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {formOpen ? (
        <EmpProfileupdate handleUpdate={handleUpdate} />
      ) : (
        <div className=" update_btn mt-3">
          <div className="row ">
            <div className="col-12 upbtn d-flex justify-content-end">
              <button onClick={handleUpdate}>
                <FaPen className="px-0.5" /> Update
              </button>
            </div>
          </div>

          <div className="col-12 ">
            <div className="page-wrapper common_dash">
              <div className="px-3 container-fluid profile_of_student">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 form_img">
                    <ul className="nav nav-pills nav_of_stu">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="pill"
                          href="#overview"
                          role="tab"
                          aria-controls="pills-overview"
                          aria-selected="true">
                          Overview
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#address"
                          role="tab"
                          aria-controls="pills-address"
                          aria-selected="false">
                          Address-Details
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#document"
                          role="tab"
                          aria-controls="pills-document"
                          aria-selected="false">
                          Education-Details
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#bank-details"
                          role="tab"
                          aria-controls="pills-bank-details"
                          aria-selected="false">
                          Bank-Details
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-3 ">

                      <div
                        className="tab-pane fade show active"
                        id="overview"
                        role="tabpanel"
                        aria-labelledby="overview-tab">
                        <div className="row">
                          <div className="col-lg-12">
                            <h3 className="hback">Personal Details</h3>

                            <div className="second ">
                              <div id="display" className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="email">Name : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.name ==="null" ? "------":data.name}</span>
                                </div>
                              </div>
                              <div id="display" className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="email">Email : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.email ==="null" ? "------":data.email}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="rel">Mobile : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.mobile ==="null" ? "------":data.mobile}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="gender">Gender : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span className="lowercase">
                                    {data.gender === "null" ? "------" : data.gender}</span>
                                </div>{" "}
                              </div>

                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="dob">D.O.B : </span>{" "}
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{format(data.dob || 11 / 8 / 200, 'd-MMMM-y') || "------- "}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="address"
                        role="tabpanel"
                        aria-labelledby="address-tab">

                        <div className="row">
                          <div className="col-lg-12">
                            <h3 className="hback">Address : </h3>

                            <div className="second">
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-8 col-sm-6">
                                  <span id="rel">City : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.city === "null" ? "------" : data.city}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-8 col-sm-6">
                                  <span id="rel">Current Address : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.address === "null" ? "------" : data.address}</span>
                                </div>
                              </div>

                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-8 col-sm-6">
                                  <span id="rel">State : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.state === "null" ? "------" : data.state}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-8 col-sm-6">
                                  <span id="rel">Country : </span>
                                </div>
                                <div className="col-lg-5 col-md-8 col-sm-6">
                                  <span id="rel">{data.country === "null" ? " -------" : data.country} </span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-8 col-sm-6">
                                  <span id="rel">Zipcode : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.pincode === "null" ? " -------" : data.pincode}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="document"
                        role="tabpanel"
                        aria-labelledby="document-tab">
                        <div className="row">
                          <div className="col-lg-12">
                            <h3 className="hback"> Education</h3>
                            <div className="second ">
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="identity">Resume : </span>{" "}
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <div className=" profile-image">
                                    <img
                                      src={data.resume ? data.resume : ""}
                                      alt={data.name}
                                      title={data.name}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="identity">Marksheet : </span>{" "}
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <div className=" profile-image">
                                    <img
                                      src={data.marksheet ? data.marksheet : ""}
                                      alt={data.name}
                                      title={data.name}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4 ">
                                  <span className="">
                                    Highest Education :
                                  </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span className="ltext">
                                    {data.highest_education === "null" ? "------" : data.highest_education}
                                  </span>
                                </div>
                              </div>
                              <div id="display" className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="email">Experience Years : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.experience_years === "null" ? "------" : data.experience_years}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="gender">Experience Field : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.experience_field === "null" ? "------" : data.experience_field}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="id">Experience Description : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: data.experience_description==="null" ? "------":data.experience_description,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="bank-details"
                        role="tabpanel"
                        aria-labelledby="bank-details-tab">
                        <div className="row">

                          <div className="col-lg-12">
                            <h3 className="hback"> Bank-details</h3>
                            <div className="second ">
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="identity">Identity : </span>{" "}
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6 ">
                                  <div className=" profile-image">
                                    <img
                                    alt={data.account_holder_name}
                                    title={data.account_holder_name}
                                      src={data.identity ? data.identity : ""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="identity">Passbook : </span>{" "}
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6 profile-image">
                                  <div className=" profile-image">
                                    <img
                                     alt={data.account_holder_name}
                                     title={data.account_holder_name}
                                      src={data.passbook ? data.passbook : ""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4 ">
                                  <span className="">Account Holder Name : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span className="ltext">
                                    {data.account_holder_name === "null" ? "------" : data.account_holder_name}
                                  </span>
                                </div>
                              </div>
                              <div id="display" className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="email">Bank Name : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.bank_name === "null" ? "------" : data.bank_name}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="gender">Branch Name : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.branch_name === "null" ? "------" : data.branch_name}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="gender">Account Number  : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span>{data.account_number === "null" ? "------" : data.account_number}</span>
                                </div>
                              </div>
                              <div className="mb-2 borderbt row align-items-center">
                                <div className="col-lg-5 col-md-4 col-sm-4">
                                  <span id="id">IFSC Code : </span>
                                </div>
                                <div className="col-lg-7 col-md-8 col-sm-6">
                                  <span> {data.ifsc_code === "null" ? "------" : data.ifsc_code} </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* </div> */}

                </div>
              </div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default EmpProfile;