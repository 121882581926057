
import React from 'react'
import { Link } from 'react-router-dom';
const PagenotFound = () => {

    return (
        <div>
            <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Page Not Found</h2>
                            </div>
                        </div>
                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li>
                                    <Link title='Home | Back To Home Page' className=''  to="/">Home</Link>
                                </li>
                                <li className=''>Page Not Found</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='error-page'>
                <div className="main-wrapper">
                    <div className="error-box">
                        <h1>404</h1>
                        <h3 className="h2">
                            <i className="fas fa-exclamation-triangle" /> Oops! Page not found!
                        </h3>
                        <p className="h4 font-weight-normal">
                            The page you requested was not found.
                        </p>
                        <Link to="/" title='Page Not Found | Back To Home page ' className="btn text-decoration-none">
                            <button className='site-button'> Back to Home</button>
                        </Link>
                    </div>
                </div>


            </div>
        </div>
    )

}

export default PagenotFound;