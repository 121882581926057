import React from 'react';
import { useParams } from 'react-router-dom';

const Paymentpage = () => {
  // Extracting data from URL parameters
  const { data } = useParams();
  console.log("payment successFull ", data)
  return (
    <div>
      <h2>Payment Successful</h2>

      <p>Payment Data: </p>
      <p>Payment Data: {data}</p>

    </div>
  );
}

export default Paymentpage;
