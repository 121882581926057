import React, { useState, useEffect, useContext, useMemo } from 'react'
import axios from 'axios'
import RichText from '../../Admin-dashboard/pages/Subpages/RichTexteditor';
import Contextapi from '../../Contextpage/Contextapi';
import { FaArrowLeft } from 'react-icons/fa';
import Subscription from '../../Login-reg/Subscription/Subscription';
// import { useLocation } from 'react-router-dom';

const CommpanyAddjob = ({ handleUpdate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [category, setCategory] = useState(null);
    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const id = getid && getid ? getid.replace(/"/g, '') : '';
    const [formData, setFormData] = useState({
        company_id: id,
        job_title: '',
        job_category: '',
        job_mode: '',
        job_type: '',
        total_post: '',
        job_description: '',
        package: '',
        eligibility: '',
        experienced_required: '',
        job_address: '',
        job_city: '',
        job_state: '',
        days: '',
        amount: '1',
        redtUrl: 'https://www.rgshramikmitra.com/company-dashboard/view-jobs',
    }
    )
    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyID = contextdata.keyID;

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await axios.get(`${domainname}/api/get-all-jobcategory`);
                setCategory(response.data)

            } catch (error) {
                console.log(error)
            }
        }
        fetch();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsOpen(!isOpen);
    };
    return (
        <div className='container comapnay_form'>
            <div className='upbtn'>
                <button onClick={handleUpdate}> <FaArrowLeft className='px-0.5' /> back </button>
            </div>
            {isOpen ? (
                <Subscription setFormData={setFormData} jobrequest={isOpen} formData={formData} />
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className='registerWrapper'>
                            <div className="twm-tabs-style-2">
                                <form className="row" onSubmit={handleSubmit}>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="package" className="frmlabel mt-1"> Job Title</label>
                                            <input
                                                type='text'
                                                name="job_title"
                                                placeholder={"Title"}
                                                value={formData.job_title}
                                                onChange={handleChange}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 ">
                                        <div className="form-group mb-3">
                                            <label className='frmlabel mt-1' htmlFor='job_category'> Job Category</label>
                                            <select
                                                name="job_category"
                                                className="form-control form-select"
                                                value={formData.job_category}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value='' disabled>Select Job Category</option>
                                                {category && category.map((category) => (
                                                    <option key={category.id} value={category.id}>
                                                        {category.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ">
                                        <div className="form-group mb-3">
                                            <label className='frmlabel mt-1' htmlFor='job_mode'> Job Mode</label>
                                            <select
                                                name="job_mode"
                                                className="form-control form-select"
                                                value={formData.job_mode}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value='' disabled>Select Job Mode</option>
                                                <option value='Work from home'>
                                                    Work From Home
                                                </option>
                                                <option value='Work from office'>
                                                    Work From Office
                                                </option>
                                                <option value='Hybird'>
                                                    Hybird
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ">
                                        <div className="form-group mb-3">
                                            <label className='frmlabel mt-1' htmlFor='job_type'> Job Type</label>
                                            <select
                                                name="job_type"
                                                className="form-control form-select"
                                                value={formData.job_type}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value='' disabled>Select Job Type</option>

                                                <option value='Full time'>
                                                    Full Time
                                                </option>
                                                <option value='Part time'>
                                                    Part Time
                                                </option>
                                                <option value='Internship'>
                                                    Internship
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="package" className="frmlabel mt-1"> Experince Year</label>
                                            <input
                                                type='number'
                                                name="experienced_required"
                                                placeholder={"Experience Year"}
                                                value={formData.experienced_required}
                                                onChange={handleChange}
                                                defaultValue={'-'}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="package" className="frmlabel mt-1"> Package</label>
                                            <input
                                                type='number'
                                                name="package"
                                                placeholder={"Monthly Package"}
                                                value={formData.package}
                                                onChange={handleChange}
                                                defaultValue={'0'}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="eligibility" className="frmlabel mt-1">Eligibility</label>
                                            <select
                                                name="eligibility"
                                                value={formData.eligibility}
                                                onChange={handleChange}
                                                className="form-select form-control"
                                                required
                                            >
                                                <option value="" disabled>Select Eligibility</option>
                                                <option value="Under 10th">Under 10th</option>
                                                <option value="10th">10th</option>
                                                <option value="12th">12th</option>
                                                <option value="Graduate">Graduate</option>
                                                <option value="Post Graduate">Post Graduate</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-6">
                                    <label htmlFor="package" className="frmlabel mt-1">Job end-date </label>
                                    <input
                                        type='date'
                                        name="job_end_date"
                                        placeholder={"Eligibility"}
                                        value={formData.job_end_date}
                                        onChange={handleChange}
                                        className='form-control'
                                    />
                                </div> */}
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="job_post" className="frmlabel mt-1"> Total Vacancy</label>
                                            <input
                                                type='number'
                                                name="total_post"
                                                placeholder={"Number of Post"}
                                                value={formData.total_post}
                                                defaultValue={'-'}
                                                onChange={handleChange}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="job_address" className="frmlabel mt-1"> City</label>
                                            <input
                                                type='text'
                                                name="job_city"
                                                placeholder={"City"}
                                                value={formData.job_city}
                                                onChange={handleChange}
                                                defaultValue={'-'}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="job_state" className="frmlabel mt-1">State</label>
                                            <select
                                                name="job_state"
                                                value={formData.job_state}
                                                onChange={handleChange}
                                                className="form-select form-control"
                                                required
                                            >
                                                <option value="" disabled>Select State</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="job_address" className="frmlabel mt-1"> Address</label>
                                            <textarea
                                                rows={3}
                                                name="job_address"
                                                placeholder={"Address "}
                                                value={formData.job_address}
                                                onChange={handleChange}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="terms" className="frmlabel mt-1">Job Description</label>
                                            <RichText
                                                name="job_description"
                                                placeholder={"Job Description"}
                                                defaultValue={'-'}
                                                value={formData.job_description}
                                                onChange={handleRichTextChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <button type="submit" className="site-button">
                                           Add Job Request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default CommpanyAddjob;