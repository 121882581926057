import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RichText from "../Subpages/RichTexteditor";
import { FaArrowLeft } from "react-icons/fa";
import Contextapi from "../../../Contextpage/Contextapi";

const HomeUpdate = ({ handleUpdate }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    home_image: null, 
    seo_title: "",
    seo_description: "",
    seo_keywords: "",
    canonical_links: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-homepage`, {
          params,
        });
        setFormData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [params, domainname]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      home_image: e.target.files[0], 
    });
  };

  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = new FormData(); 
    dataToSend.append("title", formData.title);
    dataToSend.append("description", formData.description);
    dataToSend.append("seo_title", formData.seo_title);
    dataToSend.append("seo_description", formData.seo_description);
    dataToSend.append("seo_keywords", formData.seo_keywords);
    dataToSend.append("canonical_links", formData.canonical_links);

    if (formData.home_image) {
   
      dataToSend.append("home_image", formData.home_image);
    }

    try {
      const response = await axios.post(
        `${domainname}/api/update-homepage`,
        dataToSend,
        {
          params: {
            Client_ID: keyid,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: response.data.message || "Data updated successfully!",
        });
       
        handleUpdate();
      } else {
        Swal.fire({
          icon: "warning",
          title: "Error",
          text: response.data.message || "There was an error in updating the data. Please try again.",
          position: "top-end",
          timer: 3000,
          showConfirmButton: true,
          toast: true,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Axios Error",
        text:  error.response.data.message || "An error occurred. Please try again.",
        position: "center",
        timer: 3000,
        showConfirmButton: true,
        toast: true,
      });
      console.log("axios error", error);
    }
  };

  return (
    <div className="container ">
      <div className="upbtn">
        <button onClick={handleUpdate} className="site-button">
          <FaArrowLeft /> Back
        </button>
      </div>
      <form className="shadow p-5" onSubmit={handleSubmit}>
        <div className="row m-3">
          <div className="col-lg-6">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              onChange={handleInputChange}
              value={formData.title}
              className="form-control"
              placeholder="Enter title"
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="home_image" className="form-label">
              Home Image:
            </label>
            <input
              type="file"
              name="home_image"
              onChange={handleFileChange}
              className="form-control form-file"
            />
          </div>

          <div className="col-lg-12">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <RichText
              name="description"
              placeholder={"Enter description"}
              value={formData.description}
              onChange={handleRichTextChange}
            />
          </div>

          <div className="col-lg-4">
            <label htmlFor="seo_title" className="form-label">
              SEO Title
            </label>
            <input
              type="text"
              name="seo_title"
              onChange={handleInputChange}
              value={formData.seo_title}
              className="form-control"
              placeholder="Enter SEO title"
            />
          </div>
          <div className="col-lg-4">
            <label htmlFor="seo_keywords" className="form-label">
              SEO Keywords
            </label>
            <input
              type="text"
              name="seo_keywords"
              onChange={handleInputChange}
              value={formData.seo_keywords}
              className="form-control"
              placeholder="Enter SEO keywords"
            />
          </div>
          <div className="col-lg-4">
            <label htmlFor="canonical_links" className="form-label">
              Canonical Links
            </label>
            <input
              type="text"
              name="canonical_links"
              onChange={handleInputChange}
              value={formData.canonical_links}
              className="form-control"
              placeholder="Enter canonical links"
            />
          </div>

          <div className="col-lg-12">
            <label htmlFor="seo_description" className="form-label">
              SEO Description
            </label>
            <textarea
              type="text"
              rows={3}
              name="seo_description"
              onChange={handleInputChange}
              value={formData.seo_description}
              className="form-control"
              placeholder="Enter SEO description"
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default HomeUpdate;