import React, { useContext, useMemo, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";  
import { Link } from "react-router-dom";
import img from "../../../assets/images/contactform.jpg";
import Swal from "sweetalert2";
import Contextapi from "../../Contextpage/Contextapi";
import axios from "axios";

const Companypassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [viewPassword, setViewPassword] = useState(false);

    const getid = useMemo(() => localStorage.getItem("id") || "", []);
    const id = getid ? getid.replace(/"/g, "") : "";
    const { domainapi: domainname, keyID } = useContext(Contextapi);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            Swal.fire({
                icon: "error",
                title: "Try Again",
                text: "Password and Confirm Password do not match",
            });
            return;
        }

        try {
            const resp = await axios.post(
                `${domainname}/api/change-company-password/${id}/`,
                { Client_ID: keyID, password },
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (resp.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: "Password changed successfully",
                });
            } else {
                throw new Error('Password change failed');
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred. Please try again.",
            });
            console.error("axios error", error);
        }
    };

    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };

    return (
        <div id="main-wrapper" className="container">
            <div className="row justify-content-center">
                <div className="login col-xl-10">
                    <div className="twm-tabs-style-2 card mt-8">
                        <div className="card-body p-0">
                            <div className="row no-gutters">
                                <div className="col-lg-6">
                                    <div className="p-3 lpding">
                                        <div className="mb-5">
                                            <h3 className="h4 font-weight-bold text-theme text-center">
                                                Change Password
                                            </h3>
                                        </div>
                                        <h6 className="h5 mb-0">Welcome back!</h6>
                                        <p className="text-muted mt-2 mb-4">
                                            Create a new password...
                                        </p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type={viewPassword ? "text" : "password"}
                                                    name="password"
                                                    id="password"
                                                    value={password}
                                                    placeholder="Enter your new password"
                                                    className="form-control"
                                                    required
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mb-5 mainpass">
                                                <label htmlFor="confirm-password">Confirm Password</label>
                                                <input
                                                    type={viewPassword ? "text" : "password"}
                                                    name="confirm-password"
                                                    id="confirm-password"
                                                    value={confirmPassword}
                                                    placeholder="Confirm your password"
                                                    className="form-control passfiled"
                                                    required
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <p className="password-eye" onClick={passwordViewHandler}>
                                                    {viewPassword ? <FaEyeSlash /> : <FaEye />}
                                                </p>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <button type="submit" className="site-button">
                                                    Reset Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-none mt-5 d-lg-inline-block">
                                    <img
                                        className="cimg"
                                        src={img}
                                        alt="Login"
                                        title="forgetpage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Companypassword;