import React, { useContext, useEffect, useState } from 'react'
import Contextapi from '../Contextpage/Contextapi';
import { LinearProgress } from "@mui/material";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    const [Privacy, setPrivacy] = useState([]);
    const [loading, setLoading] = useState(true);
    const contextData = useContext(Contextapi);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await contextData.APIdata.privacy;

                // console.log(responseData.policy);
                setPrivacy(responseData);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching site config data:", error);
            }
        };
        fetchData();
    }, [contextData]);
    if (loading) {
        return (
            <div className="container">
                <div className="row p-5">
                    <LinearProgress />
                </div>
            </div>
        );
    }
    return (
        <div className='privacy_content'>
            <Helmet>
                <title>{Privacy && Privacy.seo_title ? Privacy.seo_title : 'Terms and Conditions for Manpower Supply Services'}</title>
                <meta name="description" content={Privacy.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
                <meta property="og:title" content={Privacy.seo_title || "Terms and Conditions"} />
                <meta name="keywords" content={Privacy.seo_keywords || "The company engaging our services is responsible for covering the traveling costs of hired labor"} />

                {Privacy && Privacy.author ? <meta name="author" content={Privacy.author} /> : <meta name="author" content="Rohit Patel " />}
                <meta name="publisher" content={Privacy && Privacy.publisher ? Privacy.publisher : "Sion datamatic india pvt ltd - team as a senior frontend development developer Rohit Patel Akash Lowanshi and backend development Udit Chouhan"} />

                <link rel="canonical" href="https://www.rgshramikmitra.com/" />
            </Helmet>
            <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Privacy-Policy</h2>
                            </div>
                        </div>
                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li>
                                    <Link title='home-page ' to="/">Home</Link>
                                </li>
                                <li>Privacy & Policy </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* <h1 className=''> {Privacy.title || 'Privacy Policy'}</h1> */}
                {Privacy ? (
                    <div className='row'>
                        <div className='col-12 '>
                            <div dangerouslySetInnerHTML={{ __html: Privacy.policy }} />
                        </div>
                    </div>
                ) : (
                    ""
                    // <div className='row'>
                    //     <div className='col-12 '>

                    //         <h3 >Introduction:</h3>
                    //         <p>
                    //             R&G Shramik Mitra OPC Pvt Ltd ("Company," "we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the personal information we collect from you when you use our services.
                    //         </p>

                    //         <h3>Information We Collect:</h3>
                    //         <p> We may collect personal information from you in various ways, including when you visit our website, use our services, or communicate with us. The types of personal information we may collect include:</p>

                    //         <h3>Contact Information: Name, email address, phone number, and mailing address.</h3>
                    //         <p>Professional Information: Work history, qualifications, skills, and employment preferences.
                    //             Financial Information: Bank account details for payment processing.
                    //             Usage Information: Information about your interactions with our website and services.</p>

                    //         <h3>How We Use Your Information:</h3>
                    //         <p> We may use the personal information we collect for various purposes, including:</p>

                    //         <p> Providing and managing our services.
                    //             Communicating with you about your inquiries, requests, and transactions.
                    //             Communicating with you about your inquiries, requests, and transactions.
                    //             Processing payments and invoicing.
                    //             Complying with legal obligations.</p>
                    //         <h4> Complying with legal obligations.</h4>
                    //         <p> We may share your personal information with third parties in the following circumstances:</p>

                    //         <p>  With service providers who assist us in operating our business.
                    //             With business partners and clients in connection with providing our services.
                    //             In response to legal requests or to protect our rights and interests.</p>
                    //         <h3> Data Security:</h3>
                    //         <p> We implement reasonable security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                    //         <h3>Your Choices:</h3>
                    //         <p> You have the right to access, update, or delete your personal information. You may also choose to opt-out of receiving marketing communications from us. Please contact us using the information provided below to exercise your rights.</p>

                    //         <h3> Changes to this Privacy Policy:</h3>
                    //         <p> We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website.
                    //         </p>
                    //         <p>  If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

                    //         <p> R&G Shramik Mitra OPC Pvt Ltd</p>

                    //         <span>Email: </span>
                    //         <span>Phone: </span>
                    //         <span>Address: </span>

                    //         <h3>Changes to this Privacy Policy:</h3>
                    //         <p>
                    //             This Privacy Policy is effective as of [Effective Date] and applies to all personal information collected from you on or after that date.</p>
                    //         <p>
                    //             <i> By using our services, you consent to the collection and use of your personal information as described in this Privacy Policy.</i>
                    //         </p>
                    //     </div>
                    // </div>
                )}
            </div>
        </div>
    )
}

export default PrivacyPolicy;
