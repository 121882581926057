// @collapse
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import OTPInput, { ResendOTP } from "otp-input-react";


const Otp = ({ data }) => {
    const navigate = useNavigate();

    const [OTP, setOTP] = useState("");

    // console.log(data);
    // console.log(OTP);

    const handleOtpSubmit = (e) => {
        e.preventDefault();


        if (OTP.length == 6 && OTP == data.OTP) {
            
            // console.log("otp Verify Successfully....")

            localStorage.setItem('token', data.token.plainTextToken);
            localStorage.setItem('id', JSON.stringify(data.id));
            localStorage.setItem('name', JSON.stringify(data.name));

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'success',
                title: 'Logged in successfully',
            });

            if (data.role === "employee") {
                localStorage.setItem('path', JSON.stringify('employee-dashboard/home'));
                navigate('/employee-dashboard/home');
            }
        } else {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Otp Not Match',
            });
        }
    }

    return (
        <>

            <div className='container bg-transparent'>
                <div className='row'>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center'>
                        <form className='otp-section' onSubmit={handleOtpSubmit} >
                            <div className='text-center py-2'>
                                <h3>Otp Verification </h3>
                            </div>
                            <div>
                                <OTPInput className="otp-input justify-content-center pb-4" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
                            </div>
                            <div className='d-flex justify-content-center  align-items-center gap-3'>
                                <button type='submit' name='submit' value="submit" >Verify</button>
                                <ResendOTP className="resent-otp" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>

    );
};

export default Otp;