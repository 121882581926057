import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import {  FaBars, FaHome, FaRegPlusSquare } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AgentSidesubmenu from "./CompanySidesubmenu";
import AgentHeader from "./CompanyHeader";
import logo from "../../../assets/images/logo-png.png";
import { VscFeedback } from "react-icons/vsc";
import { MdDoNotDisturbOff, MdPending, MdManageHistory } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";

const routes = [
  {
    path: "/company-dashboard/home",
    name: "Profile",
    icon: <FaHome className="text-info" />,
  },
  {
    path: "/company-dashboard/view-jobs",
    name: "All Jobs Request",
    icon: <FaRegPlusSquare className="text-warning" />,
  },

  {
    path: "job-request",
    name: "Success Job Request",
    icon: <FaCheck className="text-success" />
  },
  // {
  //   path: "pending",
  //   name: "pending",
  //   icon: <MdPending className="text-warning" />
  // },
  // {
  //   path: "approved",
  //   name: "Approved",
  //   icon: <FaCheckCircle className="text-primary" />
  // },
  // {
  //   path: "expired-jobs",
  //   name: "Expired Jobs",
  //   icon: <MdPending className="text-warning hover:text-white" />
  // },
  {
    path: "failed-jobs",
    name: "Failed Job Request",
    icon: <MdDoNotDisturbOff className="text-danger hover:text-white" />
  },

  {
    path: "/company-dashboard/subscription-plan",
    name: "Subscription Plan",
    icon: <TbListDetails className="text-primary" />,

  },
  {
    path: "/company-dashboard/view-testimonial",
    name: "Testimonial",
    icon: <VscFeedback className="text-dark" />,
  },
];

const CompanySidebar = ({ children }) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "110px",
      transition: {
        duration: 0.5,
      },
    },
  };

  const location = useLocation();

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "230px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <Link to={'/'} title="Homepage-redireact " aria-label="homepage">
                  <motion.img
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                    src={logo}
                    alt="New Project"
                  />
                </Link>
              )}
            </AnimatePresence>


            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </AnimatePresence>
          </div>

          <section className="routes">
            {routes
              .filter((route) =>
                search.toLowerCase() === '' ? true : route.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((route, index) => {
                if (route.subRoutes) {
                  route.subRoutes.filter((route) =>
                    search.toLowerCase() === '' ? true : route.name.toLowerCase().includes(search.toLowerCase())
                  )
                  return (
                    <AgentSidesubmenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      width={100}
                    />
                  );
                }
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className={`link ${location.pathname === route.path ? 'active' : ''}`}
                    activeClassName="active"
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
          </section>
        </motion.div>

        <main className="centerbar">
          < AgentHeader />

          {children}
          <Outlet />

        </main>
      </div >
    </>
  );
};

export default CompanySidebar;
