import React, { useEffect } from 'react'
import CompanySidebar from './Comman-file/CompanySidebar'
import CompanyFooter from './Comman-file/CompanyFooter'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CompanyLayout = () => {
  const path = localStorage.getItem("path");
  const navigate = useNavigate();
 const Id = "company-dashboard/home";
 
  useEffect(() => {
    if (path !== JSON.stringify(Id)) {
      Swal.fire({
        icon: "error",
        text: "Unauthorized! Please try again",
      })
        navigate('/company-login');    
    }
  }, [path, navigate]);

 
  return (
    <div>
        <CompanySidebar /> 
        <CompanyFooter />
    </div>
  )
}

export default CompanyLayout