import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Contextapi from '../../Contextpage/Contextapi';
import jobcompany from '../../../assets/images/jobs-company/pic1.jpg';
import { LinearProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
const JobsviewCategory = () => {
    const [alljob, setAllJOb] = useState([]);
    const [Jobs, setJobs] = useState([]);
    const [categoryname, setCategoryname] = useState([]);
    const [jobTypeFilter, setJobTypeFilter] = useState('');
    const [jobModeFilter, setJobModeFilter] = useState('');
    const [keyword, setKeyword] = useState('');
    const [location, setLocation] = useState('');
    const [loading, setLoading] = useState(true);
    const contextData = useContext(Contextapi);
    const { slug } = useParams();
    const totaljobs = Jobs.length;

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const JobsData = await contextData.APIdata.jobs;
                const jobcategory = await contextData.APIdata.jobCategory;
                const todayDate = new Date();
                const validJobs = JobsData.filter((item) => {
                    const jobEndDate = new Date(item.job_end_date);
                    return jobEndDate.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0);
                });
                setAllJOb(validJobs);
                const categorydata = jobcategory.filter(cate => cate.title === slug);
                setCategoryname(categorydata[0]);
            } catch (error) {
                console.error("Error fetching category data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategoryData();
    }, [contextData, slug]);
   
    useEffect(() => {
        if (alljob.length > 0) {
            const categoryJobs = alljob.filter(job => job.job_category == categoryname.id);
            // console.log(categoryJobs)
            setJobs(categoryJobs);
        }
    }, [alljob, categoryname]);

    const applyFilters = () => {
        let filteredJobs = [...Jobs];
        if (jobTypeFilter) {
            filteredJobs = filteredJobs.filter(job => job.job_type === jobTypeFilter);
        }

        if (jobModeFilter) {
            filteredJobs = filteredJobs.filter(job => job.job_mode === jobModeFilter);
        }
        if (keyword) {
            filteredJobs = filteredJobs.filter(job => job.job_title.toLowerCase().includes(keyword.toLowerCase()));
        }

        if (location) {
            filteredJobs = filteredJobs.filter(job => job.job_address.toLowerCase().includes(location.toLowerCase()));
        }
        return filteredJobs;
    };

    const filteredJobs = applyFilters();

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-5 my-5 '>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    const calculateDaysAgo = (dateString) => {
        if (!dateString) return 0;
        const currentDate = new Date();
        const jobDate = new Date(dateString);
        const differenceInTime = currentDate.getTime() - jobDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return Math.floor(differenceInDays);
    };


    return (
        <div>
            <div className="page-content">
                <Helmet>
                    <title>{Jobs && Jobs.seo_title ? Jobs.seo_title : 'R&G SHRAMIK MITRA - Find Latest Jobs, Employment Opportunities | Job Portal'}</title>
                    <meta name="description" content={Jobs.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
                    <meta name="keywords" content={Jobs.seo_keywords || "job portal, latest jobs, employment opportunities, job vacancies, job search, job seekers, career opportunities, city jobs ,hired labor"} />
                    <meta name="author" content="Frontend: Rohit Patel, Akash Lowanshi & Backend: Udit Chouhan" />
                </Helmet>
                <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
                    <div className="overlay-main site-bg-white opacity-01" />
                    <div className="container">
                        <div className="wt-bnr-inr-entry">
                            <div className="banner-title-outer">
                                <div className="banner-title-name">
                                    <h2 className="wt-title">The Most Exciting Jobs</h2>
                                </div>
                            </div>
                            <div>
                                <ul className="wt-breadcrumb breadcrumb-style-2">
                                    <li>
                                        <Link to="/" title='Home Page'>Home</Link>
                                    </li>
                                    <li className='lowercase'>Job/ {categoryname ? categoryname.title : 'list'}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-full p-t120 p-b90 site-bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 rightSidebar">
                                <div className="side-bar">
                                    <div className="sidebar-elements search-bx">
                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Keyword</h4>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Job Title or Keyword"
                                                    value={keyword}
                                                    onChange={(e) => setKeyword(e.target.value)}
                                                />
                                                <button className="btn" type="button">
                                                    <i className="feather-search" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Location</h4>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search location"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                />
                                                <button className="btn" type="button">
                                                    <i className="feather-map-pin" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                                    <span className="woocommerce-result-count-left">
                                        Showing {totaljobs} jobs
                                    </span>
                                    <div
                                        className="woocommerce-ordering twm-filter-select"
                                        method="get"
                                    >
                                        <span className="woocommerce-result-count">Sort By</span>
                                        <select
                                            className="wt-select-bar-2"
                                            data-live-search="true"
                                            data-bv-field="size"
                                            onChange={(e) => setJobTypeFilter(e.target.value)}
                                        >
                                            <option value=''>Jobs types</option>
                                            <option value='Full time'>Full time</option>
                                            <option value='Part time'>Part time</option>
                                            <option value='Internship'>Internship</option>
                                        </select>
                                        <select
                                            className="wt-select-bar-2"
                                            data-live-search="true"
                                            data-bv-field="size"
                                            onChange={(e) => setJobModeFilter(e.target.value)}
                                        >
                                            <option value=''>Job mode</option>
                                            <option value='Work from home'>Work from home</option>
                                            <option value='Work from office'>Work from office</option>
                                            <option value='Hybrid'>Hybrid</option>
                                        </select>
                                    </div>
                                </div>
                                {filteredJobs.length > 0 ? (
                                    <div className="row">
                                        {filteredJobs.map(job => (
                                            <div className="col-lg-6 col-md-12 m-b30" key={job.id}>
                                                <div className="twm-jobs-grid-style1">
                                                    <div className="twm-media">
                                                        <img src={job.company_logo ? job.company_logo : jobcompany} alt="jobcmp" title={job.job_title} loading="lazy" />
                                                    </div>
                                                    <span className="twm-job-post-duration">{`${calculateDaysAgo(job.updated_at)} days ago`}</span>
                                                    <div className="twm-jobs-category green">
                                                        <span className="twm-bg-green">{job.job_type}</span>
                                                    </div>
                                                    <div className="twm-mid-content">
                                                        <Link
                                                            to={`/job/${job.slug}`} className="twm-job-title">
                                                            <h4>{job.job_title}</h4>
                                                        </Link>
                                                        <p className="twm-job-address">
                                                            {job.job_address}
                                                        </p>
                                                        <a
                                                            href={job.company_website}
                                                            className="twm-job-websites site-text-primary" target='_blank'
                                                        >
                                                            {job.company_website}
                                                        </a>
                                                    </div>
                                                    <div className="twm-right-content">
                                                        <div className="twm-jobs-amount">
                                                            {job.package}<span>/ Month</span>
                                                        </div>
                                                        <Link
                                                            to={`/job/${job.slug}`}
                                                            className="twm-jobs-browse site-text-primary"
                                                        >
                                                            Browse Job
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='text-center mt-3'>
                                                <p className='text-danger fs-5 fw-semibold'>No jobs found for this category!</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobsviewCategory;