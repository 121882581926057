import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Contextapi from "../../Contextpage/Contextapi";
import { LinearProgress } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Jobcategory = () => {

  const jobcategory = useRef();
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const contextData = useContext(Contextapi);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const categoriesData = await contextData.APIdata.jobCategory;
        const jobsData = await contextData.APIdata.jobs;

        const todayDate = new Date();
        setJobs(jobsData.filter((item) => {
          const jobEndDate = new Date(item.job_end_date);
          return jobEndDate.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0);
        }));
        setCategories(categoriesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    fetchCategoryData();
  }, [contextData]);
  // console.log("category", categories)

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  const getCategoryJobCount = (categoryId) => {
    return jobs.filter(
      (job) => job.job_category == categoryId
    ).length;
  };
  return (
    <div className="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area2" ref={jobcategory}>
      <div className="section-head center wt-small-separator-outer">
        <div className="wt-small-separator site-text-primary">
          <div>Jobs by Categories</div>
        </div>
        <h2 className="wt-title">Choose Your Desired Category</h2>
      </div>
      <div className="container">
        <div className="twm-job-categories-section-2 m-b30">
          <div className="job-categories-style1 m-b30">
            <div className="row justify-content-center">
              {!categories ? (
                <div className="error-msg">
                  {" "}
                  No Cateegory Found <LinearProgress />
                </div>
              ) : (
                categories &&
                categories.slice(0, 8).map((category) => (

                  <div className="col-lg-3 col-md-4 col-sm-12" key={category.id}>
                    <Link
                      to={`/job-by-category/${category.title}`}
                      title={category.title}>
                      <div className="job-categories-block-2 m-b30" >
                        <div className="twm-media">
                          <img
                            src={category.jobcategory_image}
                            alt={category.title}
                            title={category.title}
                          />
                        </div>
                        <div className="twm-content">
                          <div className="twm-jobs-available">
                            Available  {getCategoryJobCount(category.id)}
                          </div>
                          <Link
                            to={`/job-by-category/${category.title}`}
                            title={category.title}>
                            {category.title}
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>

                ))
              )}
            </div>
          </div>
          <div className="text-center job-categories-btn accordion-collapse pb-4">
            <Link to="/view-jobs" className="site-button">
              All Categories
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobcategory;
