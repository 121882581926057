import React from 'react'
import GoogleTranslate from '../Common_files/GoogleTranslate'

const TopBar = () => {
    return (
        <div>
            <div className='container-fluid'>
                <div className='row justify-content-between align-items-center'>
                    <div className=" col-md-6 col-6">
                        <p className="m-0  ">R&G Shramik Mitra OPC Pvt Ltd</p>
                    </div>
                    <div className=" col-md-6 col-6 d-flex     justify-content-end">
                        <div className=" g-translate">
                            <GoogleTranslate />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TopBar
