import React from 'react'
import Header from '../Common_files/header';
import { Outlet } from 'react-router-dom';
import Footer from '../Common_files/Footer';
import TopBar from './TopBar';
function Layout() {
  return (
   <>
  <TopBar />
   <Header />
   <Outlet />
   <Footer />
   </>
  )
}

export default Layout;  