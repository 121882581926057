import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
function RefundPolicy() {

    return (

        <div className="page-content">
            <Helmet>
                <title>RefundPolicy </title>
                <meta name="description" content={"The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
                <meta property="og:title" content={"R&G Shramik - RefundPolicy  "} />
                <meta name="keywords" content={"The company engaging our services is responsible for covering the traveling costs of hired labor"} />
                <meta name="author" content="Rohit Patel" />
                <meta name="publisher" content="Gurjar Rohit patel " />

            </Helmet>
            {/* INNER PAGE BANNER */}
            <div
                className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center"
            >
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Payment Policy</h2>
                            </div>
                        </div>
                        {/* BREADCRUMB ROW */}
                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li>
                                    <Link to="/" title='Home'>Home</Link>
                                </li>
                                <li>Payment Policy</li>
                            </ul>
                        </div>
                        {/* BREADCRUMB ROW END */}
                    </div>
                </div>
            </div>
            {/* INNER PAGE BANNER END */}

            <div className='container'>
                {/* <h1 className=''> {Privacy.title || 'Privacy Policy'}</h1> */}
                {/* {Privacy ? (
                    <div className='row'>
                        <div className='col-12 '>
                            <div dangerouslySetInnerHTML={{ __html: Privacy.policy }} />
                        </div>
                    </div>) : ( */}

                <div className='row'>

                    <div className='col-12 my-3' >
                        <p>
                            <span className='fw-bold fs-6'>
                                Introduction :
                            </span>
                            Welcome to R&G Shramik Mitra. This Payment Gateway Policy outlines the terms and conditions related to payments made through our website. By using our services and making payments through our payment gateway, you agree to comply with this policy.

                        </p>
                        <p>
                            <span className='fw-bold fs-6'>
                                Payment Methods :

                            </span>
                            We accept the following payment methods:

                            Credit/Debit Cards (Visa, MasterCard, American Express)
                            PayPal
                            Other digital wallets (Apple Pay, Google Pay, etc.)
                        </p>
                        <p>
                            <span className='fw-bold fs-6'>

                                Payment Processing :
                            </span>
                            Payments on our website are processed securely through PhonePay. All transactions are encrypted and handled with the utmost security to protect your personal and financial information.
                        </p>

                        <p>
                            <span className='fw-bold fs-6'>   Fees  : </span>
                            R&G Shramik Mitra  does not charge any additional fees for processing payments. However, your bank or payment provider may charge fees which are beyond our control. Please check with your bank or payment provider for details.
                        </p>





                        <p> <span className='fw-bold fs-6'>Refund Policy :</span> At this time, our company does not offer refunds for any services. If you encounter any issues with our services, please contact our customer service team for assistance. We are committed to ensuring customer satisfaction and will do our best to resolve any concerns.</p>

                        <p>

                            <span className='fw-bold fs-6'>
                                Payment Disputes :

                            </span>
                            If you encounter any issues or disputes related to payments, please contact our customer support at [Your Support Email] or [Your Support Phone Number]. We will work with you to resolve the issue promptly.
                        </p>

                        <p>

                            <span className='fw-bold fs-6'>

                                Security :
                            </span>
                            We take the security of your personal and financial information seriously. Our payment gateway provider uses industry-standard security measures, including encryption and secure servers, to protect your data. We do not store your payment information on our servers.
                        </p>

                        <p>
                            <span className='fw-bold fs-6'> Changes to This Policy :  </span>
                            We may update this Payment Gateway Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically to stay informed about our payment practices.
                        </p>


                        <p>
                            <span className='fw-bold fs-6'>Contact Us : </span>
                            If you have any questions or concerns about this Payment Gateway Policy, please contact us at:
                            <ul>
                                <li >

                                    Email:  <span className='fw-semibold text-info'>
                                        rgshramikmitra@gmail.com
                                    </span>
                                </li>
                                <li>

                                    Phone: <span className='fw-semibold text-info'>
                                        6260838381
                                    </span>
                                </li>
                                <li>
                                    Address:

                                    <span className='fw-semibold text-info'>
                                        Vijay Nagar
                                    </span>
                                </li>
                            </ul>

                        </p>

                    </div>

                </div>
                {/* )} */}
            </div>
            {/* EXPLORE NEW LIFE END */}
        </div>

    )
}

export default RefundPolicy;