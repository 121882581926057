import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import AdminFooter from '../Admin-dashboard/Sidebar/AdminFooter';
import SideBar from '../Admin-dashboard/Sidebar/SideBar';

const LayoutAdmin = () => {
  const path = localStorage.getItem("path");
  const navigate = useNavigate();
 const Id = "admin-dashboard/home";
 
  useEffect(() => {
    if (path !== JSON.stringify(Id)) {
      Swal.fire({
        icon: "error",
        text: "Unauthorized! Please try again",
      })
        navigate('/admin-login');
     
    }
  }, [path, navigate]);

  // if (path !== JSON.stringify(Id)) {
  //   return null;
  // }

  return (
    <>
      <SideBar />
      <AdminFooter />
    </>
  );
}

export default LayoutAdmin;