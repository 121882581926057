import React, { useContext, useEffect, useMemo, useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import Contextapi from "../../../Contextpage/Contextapi";

const Siteconfigform = ({ handleUpdate }) => {
  const [formData, setFormData] = useState({
    title: "",
    contact_number: "",
    alternate_contact_number: "",
    email: "",
    alternate_email: "",
    address: "",
    google_map: "",
    facebook: "",
    instagram: "",
    youtube: "",
    twitter: "",
    linkedin: "",
    whatsapp: "",
    logo: null,
    fav_icon: null,
    seo_title: "",
    seo_description: "",
    seo_keywords: "",
    canonical_links: "",
  });

  const contextData = useContext(Contextapi);
  const domainName = contextData.domainapi;
  const keyID = contextData.keyID;
  const params = useMemo(() => ({ Client_ID: keyID }), [keyID]);

  useEffect(() => {
    const fetchSiteConfig = async () => {
      try {
        const response = await axios.get(`${domainName}/api/get-site-config`, { params });
        setFormData(response.data);
      } catch (error) {
        console.error("Error fetching site config:", error);
      }
    };
    fetchSiteConfig();
  }, [domainName, params]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== null && formData[key] !== "") {
          formDataToSend.append(key, formData[key]);
        }
      });

      const response = await axios.post(`${domainName}/api/update-site-config`, formDataToSend, {
        params: { Client_ID: keyID },
      });

      console.log("site config", response)
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Site configuration updated successfully.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        handleUpdate();
      } else {
        throw new Error("Failed to update site configuration");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "There was an error updating the data. Please try again.",
        position: "top-end",
        timer: 2500,
      });
      console.error("Error updating site config:", error);
    }
  };

  return (
    <div className="container">
      <div className="upbtn">
        <button onClick={handleUpdate} className="btn btn-secondary">
          <FaArrowLeft /> Back
        </button>
      </div>
      <form className="shadow siteconfig-form p-5"  onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row my-2 ">
          <div className="col-lg-6 mb-3">
            <label htmlFor="logo" className="form-label lable">
              <MdFileUpload className="image-upload" /> Update Logo
            </label>
            <input
              type="file"
              name="logo"
              className="form-control"
              accept=".jpg,.jpeg,.png,.svg+xml"
              onChange={handleFileChange}
            />
            {formData.logo && typeof formData.logo === "string" && (
              <div className="img-preview mt-3   ">
                <img src={formData.logo} alt="Logo Preview" className="img-fluid" />
              </div>
            )}
          </div>
          <div className="col-lg-6 mb-3">
            <label htmlFor="fav_icon" className="form-label lable">
              <MdFileUpload className="image-upload" /> Update Favicon
            </label>
            <input
              type="file"
              name="fav_icon"
              className="form-control"
              accept=".jpg,.jpeg,.png,.svg+xml"
              onChange={handleFileChange}
            />
            {formData.fav_icon && typeof formData.fav_icon === "string" && (
              <div className="img-preview mt-3">
                <img src={formData.fav_icon} alt="Favicon Preview" className="img-fluid" />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              value={formData.email}
              className="form-control"
              placeholder="Enter email"
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label htmlFor="alternate_email" className="form-label">
              Alternate E-mail
            </label>
            <input
              type="email"
              name="alternate_email"
              onChange={handleInputChange}
              value={formData.alternate_email}
              className="form-control"
              placeholder="Enter alternate email"
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label htmlFor="contact_number" className="form-label">
              Mobile
            </label>
            <input
              type="text"
              name="contact_number"
              onChange={handleInputChange}
              value={formData.contact_number}
              className="form-control"
              placeholder="Enter mobile number"
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label htmlFor="address" className="form-label">
              Address
            </label>
            <input
              type="text"
              name="address"
              onChange={handleInputChange}
              value={formData.address}
              className="form-control"
              placeholder="Enter address"
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label htmlFor="alternate_contact_number" className="form-label">
              Alternate Mobile
            </label>
            <input
              type="text"
              name="alternate_contact_number"
              onChange={handleInputChange}
              value={formData.alternate_contact_number}
              className="form-control"
              placeholder="Enter alternate mobile number"
            />
          </div>

          <div className="row ">
            <h6 className="">Social Media Links</h6>
            {["google_map", "facebook", "instagram", "youtube", "twitter", "linkedin", "whatsapp"].map(
              (field) => (
                <div className="col-lg-4 mb-3" key={field}>
                  <label htmlFor={field} className="form-label">
                    {field.replace("_", " ").charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    type="url"
                    name={field}
                    onChange={handleInputChange}
                    value={formData[field]}
                    className="form-control"
                    placeholder={`Enter ${field.replace("_", " ")}`}
                  />
                </div>
              )
            )}
          </div>

          <div className="col-lg-4 mb-3">
            <label htmlFor="seo_title" className="form-label">
              SEO Title
            </label>
            <input
              type="text"
              name="seo_title"
              onChange={handleInputChange}
              value={formData.seo_title}
              className="form-control"
              placeholder="Enter SEO title"
            />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="seo_keywords" className="form-label">
              SEO Keywords
            </label>
            <input
              type="text"
              name="seo_keywords"
              onChange={handleInputChange}
              value={formData.seo_keywords}
              className="form-control"
              placeholder="Enter SEO keywords"
            />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="canonical_links" className="form-label">
              SEO Canonical Links
            </label>
            <input
              type="text"
              name="canonical_links"
              onChange={handleInputChange}
              value={formData.canonical_links}
              className="form-control"
              placeholder="Enter SEO canonical links"
            />
          </div>
          <div className="col-lg-12 mb-3">
            <label htmlFor="seo_description" className="form-label">
              SEO Description
            </label>
            <textarea
              name="seo_description"
              onChange={handleInputChange}
              value={formData.seo_description}
              rows="4"
              className="form-control"
              placeholder="Enter SEO description"
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Siteconfigform;
