import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaArrowLeft, FaBackward } from 'react-icons/fa';
import Contextapi from '../../Contextpage/Contextapi';
import { LinearProgress } from '@mui/material';

const ProfileEditcmp = ({ handleUpdate }) => {
    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const [loading, setLoading] = useState(true);
    const id = getid && getid ? getid.replace(/"/g, '') : '';
    const [formData, setFormData] = useState({
        company_id: id || "",
        name: "" ,
        email: "",
        mobile: "",
        industry_type: '',
        employee_strength: '',
        company_logo: null,
        alternate_mobile: '',
        alternate_email: '',
        company_website: '',
        city: '',
        location: '',
        state: ''
    });
    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyID = contextdata.keyID;
    
   

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleimage = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
          ...prevData,
          company_logo: imageFile,
        }));
      };
    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get(`${domainname}/api/get-company/${id}`, { params: { Client_ID: keyID } });
               
                setFormData(response.data[0]);
                setLoading(false)
            } catch (error) {
                console.log(error);
            }
        };
        fetchCompanyData();
    }, [id, keyID]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        try {
            const response = await axios.post(
                `${domainname}/api/update-company/${id}`,
                formDataToSend,
                {
                    params: { Client_ID: keyID },
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            );

            if (response.status === 200) {
                Swal.fire({
                    
                    icon: "success",
                    position:"top-end",
                    timerProgressBar:true,
                    timer:25000,
                    text:"your Profile updated successfully!",
                });
                handleUpdate();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error in updating the data. Please try again.",
                });
                handleUpdate();
            }
        } catch (error) {
            console.log("axios", error);
            Swal.fire({
                icon: "error",
                title: "There was an error in updating the data. Please try again.",
            });
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-5'>
                    <LinearProgress />
                </div>
            </div>
        );
    }
    return (
        <div className="container">
            <div className='upbtn'>
                <button onClick={handleUpdate} title='baack'> <FaArrowLeft /> Back </button>
            </div>
            <form className='shadow p-5 company_form' onSubmit={handleSubmit} encType='multipart/form-data'>
                <div className="row m-3 ">
                    <div className="col-lg-4">
                        <label htmlFor="name" className="form-label">Company Name</label>
                        <input
                            type="text"
                            name='name'
                            value={formData.name}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter company name"
                            readOnly
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            name='email'
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter email"
                            readOnly
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="mobile" className="form-label">Mobile Number</label>
                        <input
                            type="tel"
                            name='mobile'
                            value={formData.mobile}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter mobile number"
                            pattern="[0-9]{10}"
                            readOnly
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="company_logo" className="form-label">Company Logo</label>
                        <input
                            type="file"
                            name='company_logo'
                            onChange={handleimage}
                            className="form-control"
                        />
                    </div>
                    
                    <div className="col-lg-4">
                        <label htmlFor="alternate_email" className="form-label">Alternate Email</label>
                        <input
                            type="email"
                            name='alternate_email'
                            value={formData.alternate_email}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter alternate email"
                        />
                    </div>
                    
                    <div className="col-lg-4">
                        <label htmlFor="alternate_mobile" className="form-label">Alternate Mobile Number</label>
                        <input
                            type="tel"
                            name='alternate_mobile'
                            value={formData.alternate_mobile}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter alternate mobile number"
                            pattern="[0-9]{10}"
                        />
                    </div>
                    <div className='row mt-1'>
                    <div className="col-lg-4">
                        <label htmlFor="company_website" className="form-label">Company Website</label>
                        <input
                            type="url"
                            name='company_website'
                            value={formData.company_website}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter website"
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="industry_type" className="form-label">Industry Type</label>
                        <input
                            type="text"
                            name='industry_type'
                            value={formData.industry_type}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter industry type"
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="employee_strength" className="form-label">Employee Strength</label>
                        <input
                            type="text"
                            name='employee_strength'
                            value={formData.employee_strength}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter employeestrength"
                        />
                    </div>
                   
                    {/* <div className="col-lg-4">
                        <label htmlFor="city" className="form-label">City</label>
                        <input
                            type="text"
                            name='city'
                            value={formData.city}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter city"
                        />
                    </div> */}
                    <div className="col-lg-12">
                        <label htmlFor="location" className="form-label">Address</label>
                        <textarea
                            type="text"
                            name='location'
                            rows={3}
                            value={formData.location}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter your full address "
                        />
                    </div>
                    {/* <div className="col-lg-4">
                        <label htmlFor="state" className="form-label">State</label>
                        <input
                            type="text"
                            name='state'
                            value={formData.state}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter state"
                        />
                    </div> */}
                    </div>
                </div>
                <div className='d-flex justify-content-end '><button type='submit' className="btn btn-primary">Submit</button></div>
            </form>
        </div>
    );
};

export default ProfileEditcmp;
