import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        try {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        } catch (error) {
            console.error("Error while scrolling to top:", error);
        }
    }, [pathname]);

    return null;
}
