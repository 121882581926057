import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Apicontext from "./Contextapi";
import "../../assets/css/MyHomecss.css";

const ApiProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [APIdata, setApiData] = useState(null);
  const domainapi = "https://portal.rgshramikmitra.com";
  const usertoken = localStorage.getItem("token");
  const keyID = "9|tdyfgjcgvjhbkvjhbkj@hcgvjh8465132ghvjb";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [
          siteConfigRes,
          sliderRes,
          homeTitleRes,
          galleryRes,
          testimonial,
          jobCategoryRes,
          jobsRes,
          aboutRes,
          termsConditionRes,
          privacyRes,
          
        ] = await Promise.all([
          axios.get(`${domainapi}/api/get-siteconfig`),
          axios.get(`${domainapi}/api/get-slide`),
          axios.get(`${domainapi}/api/get-home-page`),
          axios.get(`${domainapi}/api/get-galleries`),
          axios.get(`${domainapi}/api/get-approved-testimonials`),
          axios.get(`${domainapi}/api/get-all-jobcategory`),
          axios.get(`${domainapi}/api/get-all-jobpost`),
          axios.get(`${domainapi}/api/get-abouts`),
          axios.get(`${domainapi}/api/get-termscondition`),
          axios.get(`${domainapi}/api/get-privacypolicy`),
          // axios.get('https://a33b-2401-4900-8822-7225-4ced-61d2-11b9-4b1b.ngrok-free.app/room/rooms/'),
        ]);
        

        setApiData({
          siteConfig: siteConfigRes.data,
          slider: sliderRes.data,
          homeTitle: homeTitleRes.data,
          gallery: galleryRes.data,
          testimonial: testimonial.data,
          jobCategory: jobCategoryRes.data,
          jobs: jobsRes.data,
          about: aboutRes.data,
          termsCondition: termsConditionRes.data,
          privacy: privacyRes.data,
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops, try again....",
          text: `Server error`,
        });
        console.error(error || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [usertoken]);

  // console.log(APIdata)

  if (loading || APIdata === null) {
    return (
      <div className="bodyloading">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <Apicontext.Provider value={{ APIdata, keyID, domainapi }}>
      {children}
    </Apicontext.Provider>
  );
};

export default ApiProvider;
