import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import Termsupdate from "./TermsUpdate";
import Contextapi from "../../../Contextpage/Contextapi";
import { FaPen } from "react-icons/fa";

const TermsCondition = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [Data, setData] = useState("");
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };
  const context = useContext(Contextapi);

  // console.log("params", context);

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(
    () => {
      //   const params = context.params;

      const fetch = async () => {
        try {
          const response = await axios.get(
            `${domainname}/api/get-terms-condition`,
            { params }
          );

          // console.log("kmdkmdkmkamadkanadknfkf lNKN", response);

          setData(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetch();
    },
    [formOpen, domainname, params],
    context
  );
  if (!Data) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }
  return (
    <section className="upshowing">
      <div className="row">
        <div className="col-lg-12" id="nav">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
              <li className="breadcrumb-item ">
                <Link
                  to="/admin-dashboard/home"
                  title="Home page"
                  className="text-decoration-none ">
                  Admin
                </Link>
              </li>
              <li className="ms-1" aria-current="page">
               {'/'} Terms & Condition
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {formOpen ? (
        <Termsupdate handleUpdate={handleUpdate} />
      ) : (
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-2 upbtn">
              <button onClick={handleUpdate}>
                <FaPen className="py-0.5" /> update
              </button>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-12">
              <div className="card card_design p-3 mt-3">
                {/* <h3>Home content </h3> */}

                <div className="row">
                  <div className="first">
                    <div className="main_head">
                      
                      <h3>Title :  {Data.title}</h3>
                    </div>
                    <div className="content">
                      <h5>Terms & condition :- </h5>
                      <div dangerouslySetInnerHTML={{ __html: Data.terms }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card_design p-3 mt-3">
                <h3>Seo Parameters</h3>
                <div className="row">
                  <div className="first col-md-5">
                    <h4>Seo-Title</h4>
                    <h4>Seo-Keywords</h4>
                    <h4>Seo Description</h4>
                  </div>
                  <div className="second col-md-7">
                    <p>{Data.seo_title}</p>
                    <p>{Data.seo_keywords}</p>
                    <p>{Data.seo_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TermsCondition;
