import React from 'react';
import EmployeeLogin from './EmployeeLogin';
import AgentLogin from './AgentLogin';
import CompanyLogin from './CompanyLogin';

function Login() {
    return (
        <div className='container'>
            <div className="row signupformcontent">
                <div className='col-lg-6'>
                    <ul className="nav nav-tabs emp-tab-pills" id="myTab" role="tablist">
                        {/* Signup Candidate */}
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active emp-tab-pills-btn"
                                id="login-candidate-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#login-candidate"
                                type="button"
                                role="tab"
                                aria-controls="login-candidate"
                                aria-selected="true"
                            >
                                <i className="fas fa-user-tie" />
                                Employee-Login
                            </button>
                        </li>

                        {/* Signup Employer */}
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link emp-tab-pills-btn"
                                id="login-employer-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#login-employer"
                                type="button"
                                role="tab"
                                aria-controls="login-employer"
                                aria-selected="false"
                            >
                                <i className="fas fa-building" />
                                Agent-Login
                            </button>
                        </li>
                        {/* Login Company */}
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link emp-tab-pills-btn"
                                id="login-company-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#login-company"
                                type="button"
                                role="tab"
                                aria-controls="login-company"
                                aria-selected="false"
                            >
                                <i className="fas fa-user-tie" />
                                Company-Login
                            </button>
                        </li>
                    </ul>
                </div>

                <div className='row no-gutters'>
                    <div className="twm-tabs-style-2">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="login-candidate" role="tabpanel" aria-labelledby="login-candidate-tab">
                                <EmployeeLogin />
                            </div>
                            <div className="tab-pane fade" id="login-company" role="tabpanel" aria-labelledby="login-company-tab">
                                <CompanyLogin />
                            </div>
                            <div className="tab-pane fade" id="login-employer" role="tabpanel" aria-labelledby="login-employer-tab">
                                <AgentLogin />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;