import React from 'react'
import { Link } from 'react-router-dom'
import Subscription from '../../Login-reg/Subscription/Subscription'

const CompanyPlan = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className='col-lg-6'>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb  p-3'>
                            <li className='breadcrumb'>
                                <Link to='/' title='home' className='text-decoration-none '>
                                    Home
                                </Link>
                            </li>
                            {' / '}
                            <li className='breadcrumb'>
                                <Link to='/company-dashboard/home' title='company-dashboard' className='text-decoration-none '>
                                    Company-Dashboard
                                </Link>
                            </li>
                            {' / '}
                            <li className='breadcrumb active' aria-current='page'>
                                View-Plan
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <Subscription />
        </div>

    )
}

export default CompanyPlan