
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Reactpaginate from "react-paginate";
import Swal from "sweetalert2";
import { FaCheck, FaEye, FaSearch } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import Contextapi from "../../../Contextpage/Contextapi";
import MUIDataTable from "mui-datatables";
import { RxCross1 } from "react-icons/rx";



const AllCompany = () => {
  const [data, SetData] = useState([]);
  const [Loading, setLoading] = useState(true);

  // const [status, setStatus] = useState(
  //   {
  //     active: "",
  //   }
  // )
  // const [input, Setinput] = useState("");
  // const navigate = useNavigate();
  // const [pageNumber, setPageNumber] = useState(0);
  // const userPerPage = 7;
  // const pageCount = Math.ceil(data.length / userPerPage);
  // const offset = pageNumber * userPerPage;

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;
  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-all-company`, {
          params,
        });
        // console.log("all-comapany",response)
        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [domainname, params]);

  // const handleDelete = async (id) => {
  //   try {
  //     await axios.post(
  //       `${domainname}/api/delete-company/${id}`,
  //       {
  //         Client_ID: keyid,
  //       }
  //     );

  //     const Toast = Swal.mixin({
  //       toast: true,
  //       position: "top-end",
  //       showConfirmButton: false,
  //       timer: 2000,
  //       timerProgressBar: true,
  //       didOpen: (toast) => {
  //         toast.addEventListener("mouseenter", Swal.stopTimer);
  //         toast.addEventListener("mouseleave", Swal.resumeTimer);
  //       },
  //     });
  //     Toast.fire({
  //       icon: "success",
  //       title: "sucessfully Deleted",
  //     });
  //     navigate("/admin-dashboard");
  //   } catch (error) {
  //     console.error("There was a problem with your delete operation:", error);
  //   }
  // };


  // console.log("companyData", data)
  const handleEdit = async (id, value) => {
    const active = value;
    try {
      const res = await axios.post(`${domainname}/api/update-company-status/${id}`, { active }, {

        params: {
          Client_ID: keyid,
        }

      });

      // console.log("data update ", res);
      // console.log("id", id)

      const updatedData = data.map(item =>
        item.company_id === id ? { ...item, active } : item
      );

      // console.log("updatedata", updatedData)
      SetData(updatedData);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: res.data.message,
      });
    } catch (error) {
      console.error("There was a problem with your update operation:", error);
    }
  };


  const columns = [

    {
      name: "company_id",
      label: "S no.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, action) =>
          // console.log("value " , value , action ),
          value ? action.rowIndex + 1 : "",
      },

    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, action) =>
        (
          // console.log(value, action),
          <Link
            title={`${action.rowData[1]
              } view-profile`
            }
            to={`/admin-dashboard/company/all-company/user-profile/${action.rowData[0]
              }`
            }
          >
            <span >{value}</span>
          </Link>
        )
      },
    },


    {
      name: "email",
      label: "Email",

    },

    {
      name: "mobile",
      label: "Mobile",
    },
    {
      name: "employee_strength",
      label: "Employee Strength",
      options: {
        customBodyRender: (value) => value ? value : "-------"
      }
    },
    {
      name: "active",
      label: "Active",
      options: {
        customBodyRender: (item, action) => (
          <select
            className={`form-select ${item == 'true' ? 'text-success' : 'text-danger'}`}
            value={item}
            onChange={(e) => handleEdit(action.rowData[0], e.target.value)}
          >
            <option value='true'>True</option>
            <option value='false'>False</option>
          </select>
        )
      }

    },
    {
      label: "Action",
      name: "action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, action) =>
        (
          // console.log(value, action),
          <Link
            title={`${action.rowData[1]
              } view-profile`
            }
            to={`/admin-dashboard/company/all-company/user-profile/${action.rowData[0]
              }`
            }
          >
            <button
              type="button"
              className="btn btn-primary ms-2"
            >
              <FaEye />
            </button>
          </Link>
        )

      }

    }
  ];
  // console.log("Company Data", data)
  const options = {
    selectableRows: "none",
    filterType: 'checkbox',
    rowsPerPageOptions: [5, 10, 15, 20, 25, 30],
  };

  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }

  return (
    <div className="container-fluid" >
      <div className="row">
        <div className="shadow">
          <div className="row">
            <div className="col-lg-6">
              <nav aria-label=" breadcrumb ">
                <ol className="breadcrumb  p-3">
                  <li className="breadcrumb-item ">
                    <Link
                      to="/admin-dashboard/home"
                      className="text-decoration-none ">
                    Admin
                    </Link>
                  </li>
                  <li className="ms-1 breadcrumb-item active" aria-current="page">
                    {'/'}  Companies
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <MUIDataTable
            title={"Company List"}
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div >
  );
};
export default AllCompany;
