import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slideshow from "../Slideshow";
import Contextapi from "../../Contextpage/Contextapi";
import { Modal, Button } from "react-bootstrap";
import job_pic from '../../../assets/images/jobs-company/pic1.jpg'
import { Helmet } from "react-helmet";
// import video from "../../../assets/video/video1.mp4"
// import hero from "../../../assets/images/Sharmik/hero-main.gif"

const HeroSection = () => {

  const [category, setCategory] = useState([]);
  const [jobs, setJobs] = useState([]);
  // const [siteConfig, setSiteConfig] = useState([]);
  const [Titledata, setTitle] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const contextData = useContext(Contextapi);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await contextData.APIdata.jobCategory;
        const jobsData = await contextData.APIdata.jobs;
        // const Sitedata = await contextData.APIdata.siteConfig;
        const SiteTitle = await contextData.APIdata.homeTitle;
        setCategory(categoryData);
        setJobs(jobsData);
        // setSiteConfig(Sitedata);
        setTitle(SiteTitle);
      }
      catch (error) {
        console.error("Error fetching site config data:", error);
      }
    };
    fetchData();
  }, [contextData]);
  const renderMedia = (url) => {
    if (!url) return null;

    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return (
        <img
          src={url}
          title={Titledata.title}
          alt={Titledata.title}
          className="img-fluid"
        />
      );
    } else if (extension === 'mp4') {
      return (
        <video autoPlay loop muted className="img-fluid">
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

      );
    } else {
      return <p>Unsupported media type</p>;
    }
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  // const filteredJobs = selectedCategory
  //     ? jobs.filter(job => job.job_category === selectedCategory)
  //     : jobs;

  const handleSearch = (e) => {
    e.preventDefault();
    const filteredAllJobs = jobs.filter((job) => {

      const titleMatches = job.job_title.toLowerCase().includes(searchQuery.toLowerCase());


      const categoryMatches = selectedCategory === "All Category" || job.job_category === selectedCategory;

      const typeMatches = !selectedType || job.job_type === selectedType;

      return titleMatches && categoryMatches && typeMatches;
    });


    setFilteredJobs(filteredAllJobs);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>{Titledata && Titledata.seo_title ? Titledata.seo_title : 'R&G SHRAMIK MITRA - Find Latest Jobs, Employment Opportunities | Job Portal'}</title>
        <meta name="description" content={Titledata.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
        <meta name="keywords" content={Titledata.seo_keywords || "job portal, latest jobs, employment opportunities, job vacancies, job search, job seekers, career opportunities, city jobs ,hired labor"} />
        <meta name="author" content=" Frontend: Rohit Patel & backend: Udit Chouhan  " />
        {/* <meta name="publisher" content="Rohit Patel" /> */}
      </Helmet>

      <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
        <div className="container-fluid p-0">

          <div className="main-section-video">

            {renderMedia(Titledata.home_image)}
            {/* <img src={hero} className="img-fluid" alt="her0-gif" title={Titledata.seo_title || "RGShramika mitra It's Easy to Find Your Dream Job"} /> */}
          </div>

          <div className="main-s">
            <div className="twm-bnr-search-bar mt-6">
              <div className="data">
                <p className="text-dark">
                  Search Your Dream Job
                </p>
              </div>

              <form >
                <div className="row align-items-center">
                  <div className="col-xl-3  col-lg-6 col-md-6 col-6">

                    <select
                      className="serchselect"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      required  >
                      <option >Select Category</option>
                      <option value="All Category" >All Category</option>
                      {category &&
                        category.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                    </select>

                  </div>
                  <div className="form-group col-xl-3 col-lg-6 col-md-6 col-6 ">

                    <select
                      className="serchselect"
                      value={selectedType}
                      onChange={handleTypeChange}
                      required>
                      <option >Select Type</option>
                      <option value="Full time">Full-time</option>
                      <option value="Part time">Part-time</option>
                      <option value="Hybrid">Hybrid</option>
                    </select>
                  </div>
                  <div className="form-group col-xl-3 col-lg-6 col-md-6 col-6">

                    <div className="twm-inputicon-box">
                      <input
                        type="text"
                        className="serchselect"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        required
                      />
                      <i className="twm-input-icon fas fa-search" />
                    </div>
                  </div>
                  <div className="form-group col-xl-3 col-lg-6 col-md-6 col-6">
                    <button
                      type="button"
                      className="site-button"
                      onClick={handleSearch}>
                      Find Job
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Filtered Jobs</Modal.Title>
          </Modal.Header>
          <Modal.Body className="header-search-model">
            <ul>
              {!filteredJobs.length > 0 ? (
                <div>
                  <h3 className="text-center py-2 text-danger"> No Job Found ! </h3>
                </div>
              ) :
                filteredJobs &&
                filteredJobs.map((job) => (
                  <div className="card my-3 px-2 " key={job.id}>
                    <img
                      src={job_pic}
                      className="card-img-top pt-2"
                      alt={job.job_title}
                      title={job.job_title}
                    />
                    <div key={job.id}>
                      <Link to={`/job-/${job.slug}`} title={job.job_title}>
                        {" "}
                        <div className="card-title">
                          <h5>{job.job_title}</h5>
                        </div>
                      </Link>
                      <div className="text-right pb-2">
                        <Link
                          to={`/job/${job.slug}`}
                          className="twm-jobs-browse"
                        >
                          <button className="btn btn-primary">
                            Browse Job
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>

      {/* {!token && <section className="home-register">
        <Link to="/registration" title="Registration | Please Register To Create New Account ">
        <button className="ms-1 site-button">Register Now</button>
        </Link>
      </section>
      } */}

      <section className="slide-section">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">{Titledata.title || "R&G Shramik Mitra OPC Pvt Ltd"}  </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <Slideshow />
            </div>
            <div className="col-lg-6">
              <p className="slide-section-data my-2">
                <span dangerouslySetInnerHTML={{ __html: Titledata.description || "your trusted manpower supply partner dedicated to providing employment opportunities for unskilled and less educated individuals. At R&G Shramik Mitra, we understand the challenges faced by those seeking work without formal qualifications, and we are here to bridge that gap.It's Easy to Find Your Dream Job" }} />

              </p>
            </div>
          </div>
        </div>
      </section>



    </>
  );
};

export default HeroSection;