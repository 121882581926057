import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import RichText from "../../Subpages/RichTexteditor";
import Contextapi from "../../../../Contextpage/Contextapi";
import { FaArrowLeft } from "react-icons/fa";

const JobpostEdit = () => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [Company, setCompany] = useState([]);
  const [formData, setFormData] = useState({
    company_id: "",
    job_title: "",
    job_category: "",
    job_mode: "",
    job_type: "",
    total_post: "",
    job_description: "",
    package: "",
    eligibility: "",
    experienced_required: "",
    job_end_date: "",
    job_address: "",
    job_city: "",
    job_state: "",
    seo_title: "",
    seo_description: "",
    seo_keywords: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );
  const { slug } = useParams();
  const navigate = useNavigate();

  const id = slug;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${domainname}/api/get-single-jobpost/${id}`,
          { params }
        );
        const responseData = response.data[0];
        const responsecat = await axios.get(
          `${domainname}/api/get-jobcategory`,
          { params }
        );
        setCategory(responsecat.data);
        const rescompany = await axios.get(
          `${domainname}/api/get-all-company`,
          { params }
        );
        setCompany(rescompany.data);

        setFormData({
          company_id: responseData.company_id || "",
          job_title: responseData.job_title || "",
          job_category: responseData.job_category || "",
          job_mode: responseData.job_mode || "",
          job_type: responseData.job_type || "",
          total_post: responseData.total_post || "",
          eligibility: responseData.eligibility,
          experienced_required: responseData.experienced_required,
          job_end_date: responseData.job_end_date,
          job_description: responseData.job_description || "",
          package: responseData.package || "",
          job_address: responseData.job_address || "",
          job_city: responseData.job_city || "",
          job_state: responseData.job_state || "",
          seo_title: responseData.seo_title || "",
          seo_description: responseData.seo_description || "",
          seo_keywords: responseData.seo_keywords || "",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${domainname}/api/update-jobpost/${id}`,
        formData,
        {
          params: {
            Client_ID: keyid,
          },
        }
      );
      console.log("Updated Job Category:", res);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Successfully",
        text: "Updated Job Category",
      });

      navigate("/admin-dashboard/general-settings/view-jobpost");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Try Again....",
        text: "Server error, not responding",
      });
      console.error("Error updating job category:", error);
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container upshowing comapnay_form">
    
      <div className="row upbtn">
        <Link to={'/admin-dashboard/general-settings/view-jobpost/'} title="no update back"> <button ><FaArrowLeft className="ms-1" /> back </button></Link>
      </div>
      <div className="row mt-2">
        <div className="col-lg-12 mt-5">
          <div className="registerWrapper">
            <div className="twm-tabs-style-2">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-6">
                  <label htmlFor="package" className="form-label">
                    {" "}
                    Job Title
                  </label>
                  <input
                    type="text"
                    name="job_title"
                    placeholder={"Title"}
                    value={formData.job_title}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_category">
                      {" "}
                      Job Company
                    </label>
                    <select
                      name="company_id"
                      className="form-control form-select"
                      value={formData.company_id}
                      onChange={handleChange}>
                      <option value="">Select job Company</option>
                      {Company &&
                        Company.map((category) => (
                          <option key={category.id} value={category.company_id}>
                            {category.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_category">
                      {" "}
                      Job category
                    </label>
                    <select
                      name="job_category"
                      className="form-control form-select"
                      value={formData.job_category}
                      onChange={handleChange}>
                      <option value="">Select job category</option>
                      {category &&
                        category.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_mode">
                      {" "}
                      Job Mode
                    </label>
                    <select
                      name="job_mode"
                      className="form-control form-select"
                      value={formData.job_mode}
                      onChange={handleChange}>
                      <option value="">Select job mode</option>
                      <option value="Work from home">Work from home</option>
                      <option value="Work from office">Work from office</option>
                      <option value="Hybird">Hybird</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_type">
                      {" "}
                      Job Type
                    </label>
                    <select
                      name="job_type"
                      className="form-control form-select"
                      value={formData.job_type}
                      onChange={handleChange}>
                      <option value="">Select job type</option>

                      <option value="Full time">Full time</option>
                      <option value="part time">Part time</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="package" className="form-label">
                    {" "}
                    Experince
                  </label>
                  <input
                    type="text"
                    name="experienced_required"
                    placeholder={"experience"}
                    value={formData.experienced_required}
                    onChange={handleChange}
                    defaultValue={"-"}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="package" className="form-label">
                    {" "}
                    package
                  </label>
                  <input
                    type="text"
                    name="package"
                    placeholder={"package"}
                    value={formData.package}
                    onChange={handleChange}
                    defaultValue={"-"}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="package" className="form-label">
                    Eligibility{" "}
                  </label>
                  <input
                    type="text"
                    name="eligibility"
                    placeholder={"Eligibility"}
                    value={formData.eligibility}
                    onChange={handleChange}
                    defaultValue={"-"}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="package" className="form-label">
                    Job end-date{" "}
                  </label>
                  <input
                    type="date"
                    name="job_end_date"
                    placeholder={"Eligibility"}
                    value={formData.job_end_date}
                    defaultValue={"-"}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>

                <div className="col-lg-6">
                  <label htmlFor="job_post" className="form-label">
                    {" "}
                    Total post
                  </label>
                  <input
                    type="text"
                    name="total_post"
                    placeholder={"number of post"}
                    value={formData.total_post}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-12">
                  <label htmlFor="job_address" className="form-label">
                    {" "}
                    Address
                  </label>
                  <textarea
                    rows={3}
                    name="job_address"
                    placeholder={"jobaddress"}
                    value={formData.job_address}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="job_address" className="form-label">
                    {" "}
                    City
                  </label>
                  <input
                    type="text"
                    name="job_city"
                    placeholder={"City"}
                    value={formData.job_city}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="job_address" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    name="job_state"
                    placeholder={"job state"}
                    value={formData.job_state}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-12">
                  <label htmlFor="terms" className="form-label">
                    Description
                  </label>
                  <RichText
                    name="job_description"
                    placeholder={"description"}
                    value={formData.job_description}
                    onChange={handleRichTextChange}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <label htmlFor="seo_title" className="form-label">
                    Seo-Title
                  </label>
                  <input
                    type="text"
                    name="seo_title"
                    onChange={handleChange}
                    value={formData.seo_title}
                    className="form-control"
                    placeholder="Enter Seo title"
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <label htmlFor="seo_keywords" className="form-label">
                    Seo-Keywords
                  </label>
                  <input
                    type="text"
                    name="seo_keywords"
                    onChange={handleChange}
                    value={formData.seo_keywords}
                    className="form-control"
                    placeholder="Enter Seo keywords"
                  />
                </div>
                <div className="col-lg-12">
                  <label htmlFor="seo_description" className="form-label">
                    Seo-Description
                  </label>
                  <textarea
                    type="text"
                    rows={3}
                    name="seo_description"
                    onChange={handleChange}
                    value={formData.seo_description}
                    className="form-control"
                    placeholder="Enter Seo Description"
                  />
                </div>
                <div className="col-md-12 ">
                  <button type="submit" className="site-button">
                    Update Job Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobpostEdit;
