import React, { useContext, useEffect, useMemo, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { LinearProgress, Card, CardContent, Typography, Grid, Tooltip } from "@mui/material";
import axios from "axios";
import { MdBackHand, } from "react-icons/md";
import Contextapi from "../../Contextpage/Contextapi";
import { Link } from "react-router-dom";

import CountUp from "./Subpages/CountDown";
import agentimg from "../../../assets/images/agent.png"
import employeeimg from "../../../assets/images/employee.png"
import jobapplyimg from "../../../assets/images/job-apply.png"
import companyimg from "../../../assets/images/company.png"

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [jobRequests, setJobRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [agents, setAgents] = useState([]);

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseJobRequests = await axios.get(`
          ${domainname}/api/get-all-jobrequest`,
          { params }
        );
        const responseEmployees = await axios.get(`
          ${domainname}/api/get-all-employee`,
          { params }
        );
        const responseCompanies = await axios.get(`
          ${domainname}/api/get-all-company`,
          { params }
        );
        const responseAgents = await axios.get(`
          ${domainname}/api/get-all-agent`,
          { params }
        );

        setJobRequests(responseJobRequests.data);
        setEmployees(responseEmployees.data);
        setCompanies(responseCompanies.data);
        setAgents(responseAgents.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [params, domainname]);

  const data = [
    { id: 0, value: jobRequests.length, label: "Job Application" },
    { id: 1, value: employees.length, label: "Employees" },
    { id: 2, value: companies.length, label: "Companies" },
    { id: 3, value: agents.length, label: "Agents" },
  ];



  return (
    <div className="container-fluid common_dash">
      <div className="row align-items-center top mt-3">
        <div className="col-lg-6">
          <h3>
            Welcome Admin
            <MdBackHand className="text-warning anim" />!
          </h3>
        </div>
        {/* <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Link to="/admin-dashboard/home" > Home </Link>
            <Breadcrumb.Item active className="">
              {'/'} Admin-Dashboard
            </Breadcrumb.Item>
          </Breadcrumb>
        </div> */}
      </div>
      {loading ? (
        <div className="container">
          <div className="row p-3 py-10">
            <LinearProgress />
          </div>
        </div>
      ) : (
        <div>
          <Grid container spacing={3} className="mt-4">
            <Grid item xs={12} sm={6} md={3}>
              <Link to={'/admin-dashboard/general-settings/view-jobrequest'} title="job application">
                <Card className="card-height">
                  <CardContent className="d-flex align-items-center justify-content-center gap-3" >
                    <Tooltip title="Job Application">
                      <div className="admin-dashboard-img w-25 ">
                        <img src={jobapplyimg} className="img-fluid" alt="Jon-apply-icon" title="job-apply" />
                      </div>
                    </Tooltip>

                    <div>
                      <Typography variant="h5" >
                        {jobRequests.length === 0 ? "0" : <CountUp target={jobRequests.length} />}
                      </Typography>
                      <Typography variant="subtitle1">Job Requests</Typography>
                    </div>

                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Link to={'/admin-dashboard/employees/all-employees'} title="employees">
                <Card className="card-height">
                  <CardContent className="d-flex align-items-center justify-content-center gap-3">
                    <Tooltip title="Employees">
                      {/* <MdPeople size={30} /> */}
                      <div className="admin-dashboard-img w-25 ">
                        <img src={employeeimg} className="img-fluid" alt="employee-icon" title="employee" />
                      </div>
                    </Tooltip>
                    <div>
                      <Typography variant="h5">
                        {employees.length === 0 ? "0" : <CountUp target={employees.length} />}
                      </Typography>
                      <Typography variant="subtitle1">Employees</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Link to={'/admin-dashboard/company/all-company'} title="view-Companies">
                <Card className="card-height">
                  <CardContent className="d-flex align-items-center justify-content-center gap-3">
                    <Tooltip title="Companies">
                      {/* <MdBusiness size={30} /> */}
                      <div className="admin-dashboard-img w-25 ">
                        <img src={companyimg} className="img-fluid" alt="company-icon" title="company" />
                      </div>
                    </Tooltip>
                    <div >

                      <Typography variant="h5">
                        {companies.length === 0 ? "0" : <CountUp target={companies.length} />}
                      </Typography>
                      <Typography variant="subtitle1">Companies</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Link to={'/admin-dashboard/agent/all-agent'} title="view-agents">
                <Card className="card-height">
                  <CardContent className="d-flex align-items-center justify-content-center gap-3">
                    <Tooltip title="Agents">
                      {/* <MdPerson size={30} />
                       */}
                      <div className="admin-dashboard-img w-25 ">
                        <img src={agentimg} className="img-fluid" alt="agent-icon" title="agent" />
                      </div>
                    </Tooltip>
                    <div>
                      <Typography variant="h5">
                        {agents.length === 0 ? "0" : <CountUp target={agents.length} />}
                      </Typography>
                      <Typography variant="subtitle1">Agents</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
          <div className="row mt-4">
            <div className="col-lg-6">
              <PieChart
                series={[
                  {
                    data,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={200}
              />
            </div>
            <div className="col-lg-6">
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: [
                      "Job Application",
                      "Employees",
                      "Companies",
                      "Agents",
                    ],
                  },
                ]}
                series={[
                  {
                    data: [
                      jobRequests.length,
                      employees.length,
                      companies.length,
                      agents.length,
                    ],
                  },
                ]}
                width={500}
                height={300}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;