import React, { useContext, useEffect, useMemo, useState } from "react";
import Reactpaginate from "react-paginate";
import Swal from "sweetalert2";
import { FaEye, FaPen, FaSearch, FaTrash } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import AddJobpost from "./AddJobpost";
import Contextapi from "../../../../Contextpage/Contextapi";
import MUIDataTable from "mui-datatables";
import { format, setDate } from 'date-fns';

const ViewJobPost = () => {
  const [data, SetData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Addform, setAddform] = useState(false);
  const [category, setCategory] = useState([]);
  const [Company, setCompany] = useState([]);
  const handleUpdate = () => {
    setAddform(!Addform);
  };
  
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-jobpost`, {
          params,
        });
        const rescategory = await axios.get(
          ` ${domainname}/api/get-jobcategory`,
          { params }
        );
        setCategory(rescategory.data);
        const rescompany = await axios.get(
          `${domainname}/api/get-all-company`,
          { params }
        );
        setCompany(rescompany.data);
        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [domainname, params, Addform]);

  const handleDelete = async (id) => {
    try {
      // Display a confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  
      // If the user confirms, proceed with the deletion
      if (result.isConfirmed) {
        await axios.post(
          `${domainname}/api/delete-jobpost/${id}`,
          {
            Client_ID: keyid,
          }
        );
  
        
        Swal.fire({
          icon: 'success',
          position: 'top-end',
          title: 'Deleted!',
          text: 'Your job post has been deleted.',
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
        });
  
        // Trigger an update to refresh the data
        SetData((pre)=> pre.filter(item => item.id !== id ))
      }
    } catch (error) {
      console.error("There was a problem with your delete operation:", error);
  
      // Show error notification
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.response?.data?.message || 'There was an error deleting the job post. Please try again.',
      });
    }
  }; 


  // console.log("Job Data", data);

  const columns = [
    {
      label: "SNo",
      name: "id",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, action) => action.rowIndex + 1
      }
    },
    {
      label: "Job Id",
      name: "job_request_id",
      options: {
        display:false,
      }
    },
    {
      label: "Company",
      name: "company_id",
      options: {
        customBodyRender: (value) => {
          const company = Company.find((c) => c.company_id === value);
          return company ? company.name : "Unknown";
        }
      }
    },
    {
      label: "Job Title",
      name: "job_title"
    },
    {
      label: "Type",
      name: "job_type"
    },
    {
      label: "Vacancy",
      name: "total_post"
    },
    {
      label: "Address",
      name: "job_address"
    },
    {
      label: "End Date",
      name: "job_end_date",
      options: {
        customBodyRender: (value) => {
          return format(value, 'dd-MM-yyyy')
        }
      }
    },
    {
      label: "Action",
      name: "action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, action) => (
          <div className="d-flex gap-1">
            <Link
              to={`/admin-dashboard/general-settings/jobpost-edit/${action.rowData[0]}`}
              type="button"
              className="btn-sm btn-primary ">
              <FaPen />
            </Link>
           {action.rowData[1] === null && <button
              type="button"
              title="delete job"
              className="btn-sm btn-danger "
              onClick={() => handleDelete(action.rowData[0])}>
              <FaTrash />
            </button>
      }
            <Link
             to={`/admin-dashboard/general-settings/Job`}
             state={{ job: action.rowData }}
             title={action.rowData[2] || "title"}
              type="button"
              className="btn-sm btn-info ">
              <FaEye />
            </Link>
          </div>
        )
      }
    }
  ];
  
  const options = {
    selectableRows: "none",
    // elevation: "none",
    rowsPerPageOptions: [5, 10, 15, 20, 30]
  }


  if (Loading) {
    <div className="container">
      <div className="row p-3 py-10">
        <LinearProgress />
      </div>
    </div>;
  }

  if (!data) {
    <div className="container">
      <div className="row p-5 mt-10">
        <LinearProgress />
      </div>
    </div>;
  }

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label=" breadcrumb ">
            <ol className="breadcrumb  p-3">
              <li className="breadcrumb-item ">
                <Link to="/admin-dashboard" className="text-decoration-none ">
                  Admin
                </Link>
              </li>
              <li className="ms-1 breadcrumb-item active" aria-current="page">
                {"/"} Jobs-Post
              </li>
            </ol>
          </nav>
        </div>
        {Addform ? (
          <AddJobpost handleUpdate={handleUpdate} />
        ) : (
          <div className="row">
            <div className="">
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="row align-items-center ">
                  <div className=" upbtn d-flex justify-content-end">
                    <button onClick={handleUpdate} className="site-button">Add</button>
                  </div>
                </div>
                
              </div>
            </div>
           
            <MUIDataTable

              title={"Job List"}
              data={data}
              columns={columns}
              options={options}

            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewJobPost;
