import React, { useContext, useEffect, useMemo, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { MdBackHand } from "react-icons/md";
import Contextapi from "../../Contextpage/Contextapi";
import employeeimg from "../../../assets/images/employee.png";
import companyimg from "../../../assets/images/company.png";
import { Link } from "react-router-dom";

const AgentHome = () => {
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getid = useMemo(() => localStorage.getItem("id") || "", []);
  const id = getid && getid ? getid.replace(/"/g, "") : "";
  const contextdata = useContext(Contextapi);

  const domainname = contextdata.domainapi;
  const keyID = contextdata.keyID;
  const params = useMemo(
    () => ({
      Client_ID: keyID,
    }),
    [keyID]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseEmployees = await axios.get(
          `${domainname}/api/get-my-employee/${id}`,
          { params }
        );
        const responseCompanies = await axios.get(
          `${domainname}/api/get-all-company`,
          { params }
        );
        setEmployees(responseEmployees.data);
        setCompanies(responseCompanies.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [params, domainname]);

  const data = [
    { id: 1, value: employees.length, label: "Employees" },
    { id: 2, value: companies.length, label: "Companies" },
  ];

  // console.log("kk", jobRequests);

  return (
    <div className="container-fluid common_dash">
      <div className="row align-items-center top mt-3">
        <div className="col-lg-6">
          <h3>
            Welcome Agent
            <MdBackHand className="text-warning anim" /> !
          </h3>
        </div>

      </div>
      {loading ? (
        <div className="container">
          <div className="row p-3 py-10">
            <LinearProgress />
          </div>
        </div>
      ) : (
        <div>
          <div className="row mt-4 justify-content-center">
            <div className="col-lg-3">
              <Link to={'/agent-dashboard/employees'} title="view employee">
                <div className="card card-height flex-row">
                  <div className="cart-logo w-25">
                    <img className="img-fluid" src={employeeimg} alt="employee" title="employee" />
                  </div>
                  <div>
                    <span>{employees.length === 0 ? "0" : employees.length}</span>
                    <h6>My Employees</h6>
                  </div>
                </div>
              </Link>
            </div>
            {/* <Link to={'/agent-dashabord/companies'} title="view company" > */}
            <div className="col-lg-3">
              <div className="card card-height flex-row">
                <div className="cart-logo w-25">
                  <img className="img-fluid" src={companyimg} alt="employee" title="employee" />
                </div>
                <div >
                  <span>{companies.length === 0 ? "0" : companies.length}</span>
                  <h6> Companies</h6>
                </div>
              </div>

            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <PieChart
                series={[
                  {
                    data,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={200}
              />
            </div>
            <div className="col-lg-6">
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: ["Employees", "Companies"],
                  },
                ]}
                series={[
                  {
                    data: [employees.length, companies.length],
                  },
                ]}
                width={500}
                height={300}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentHome;
