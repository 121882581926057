import React from 'react'

import EmpFooter from './Comman-file/EmpFooter'
import EmpSidebar from './Comman-file/EmpSidebar'

const EmpLayout = () => {
  return (
    <div>
      <EmpSidebar />
      {/* <EmpFooter  /> */}
    </div>
  )
}
export default EmpLayout