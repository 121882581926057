import { Link, NavLink, Outlet } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaImage,
  FaInfo,
  FaInfoCircle,
  FaLock,
  FaMoneyBill,
  FaUser,
  FaUsersCog,
} from "react-icons/fa";
import { MdContactPhone, MdMessage,  MdOutlineSupportAgent, MdPriceChange } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { VscFeedback } from "react-icons/vsc";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {  FcNews } from "react-icons/fc";
import { ImOffice } from "react-icons/im";
import SidebarMenu from "./SidebarMenu";
import Tobbar from "../Topbar/Tobbar";
import logo from "../../../assets/images/logo-png.png";
import rejectJob from "../../../assets/images/rejected.png";
import approvedJob from "../../../assets/images/approved.png"
import pandingJob from "../../../assets/images/panding.png"
import allJobs from "../../../assets/images/all-Job.png"
import jobsApplication from "../../../assets/images/job-application.png"
import jobsPost from "../../../assets/images/job-post.png"
import jobsRequest from "../../../assets/images/job-request.png"

const routes = [
  {
    path: "/admin-dashboard/home",
    name: "Dashboard",
    icon: <FaHome />,
  },

  {
    path: "/settings",
    name: "Site Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
      {
        path: "/admin-dashboard/site-settings/site-config",
        name: "Site Config ",
        icon: <FaInfoCircle />,
      },
      {
        path: "/admin-dashboard/site-settings/home-page",
        name: "Home Page ",
        icon: <FaInfo />,
      },
      {
        path: "/admin-dashboard/site-settings/slider",
        name: "Slider Image ",
        icon: <FaImage />,
      },
      {
        path: "/admin-dashboard/site-settings/gallery",
        name: "Gallery",
        icon: <FaImage />,
      },
      {
        path: "/admin-dashboard/site-settings/about-page",
        name: "About",
        icon: <FaMoneyBill />,
      },

      {
        path: "/admin-dashboard/site-settings/terms-condition",
        name: "Terms & Condition",
        icon: <MdMessage />,
      },
      {
        path: "/admin-dashboard/site-settings/privacy-policy",
        name: "Privacy Policy",
        icon: <FaLock />,

      },
    ],
  },

  {
    path: "/admin-dashboard/company",
    name: "Comapany",
    icon: <ImOffice />,
    subRoutes: [
      {
        path: "/admin-dashboard/company/add-company",
        name: "Add Company",
        icon: <FaLock />,
      },

      {
        path: "/admin-dashboard/company/all-company",
        name: "All Companies",
        icon: <FaUser />,
      },
    ],
  },

  {
    path: "/admin-dashboard/agent",
    name: "Agent",
    icon: <MdOutlineSupportAgent />,
    subRoutes: [
      {
        path: "/admin-dashboard/agent/add-agent",
        name: "Add Agent",
        icon: <FaLock />,
      },

      {
        path: "/admin-dashboard/agent/all-agent",
        name: "All Agents",
        icon: <FaUser />,
      },
    ],
  },

  {
    path: "/admin-dashboard/employees",
    name: "Employees",
    icon: <FaUsersCog />,
    subRoutes: [
      {
        path: "/admin-dashboard/employees/add-employees",
        name: "Add Employee",
        icon: <FaLock />,
      },

      {
        path: "/admin-dashboard/employees/all-employees",
        name: "All Employees",
        icon: <FaUser />,
      },
    ],
  },


  {
    path: "/admin-dashboard/general-settings/view-jobcategory",
    name: "Job Category",
    icon: <FaMoneyBill />,
  },

  {
    path: "/admin-dashboard/general-settings/view-jobpost",
    name: "Job Post",
    icon: <img src={jobsPost} title=" Add & View Jobs equest" alt="job-approved" className="admin-sidebar-png-image" />,
  },
  {
    path: "/admin-dashboard/general-settings/view-jobrequest",
    name: "View Job Requests",
    icon: <img src={jobsRequest} alt="job-approved" className="admin-sidebar-png-image" />,
  },

  {
    path: "/admin-dashboard/job-application",
    name: "Jobs Application",
    icon: <img src={jobsApplication} alt="job-approved" className="admin-sidebar-png-image" />,

    subRoutes: [
      {
        path: "/admin-dashboard/job-application/all-jobs-applications",
        name: "All Application",
        icon: <img src={allJobs} alt="job-approved" className="admin-sidebar-png-image" />,
      },

      {
        path: "/admin-dashboard/job-application/pending-job",
        name: "Jobs Pending",
        icon: <img src={pandingJob} alt="job-approved" className="admin-sidebar-png-image" />,
      },
      {
        path: "/admin-dashboard/job-application/apporved-job",
        name: "Jobs Approved",
        icon: <img src={approvedJob} alt="job-approved" className="admin-sidebar-png-image" />,
      },
      {
        path: "/admin-dashboard/job-application/reject-job",
        name: "Jobs Rejected",
        icon: <img src={rejectJob} alt="job-reject" className="admin-sidebar-png-image" />,
      },
    ],
  },

  {
    path: "/admin-dashboard/subscription/view-subscription",
    name: "Subscription Plan ",
    icon: <MdPriceChange className="text-dark " />,
  },
  {
    path: "/admin-dashboard/testimonial",
    name: "Testimonial",
    icon: <VscFeedback />,
  },
  {
    path: "/admin-dashboard/contact",
    name: "Contact-Us",
    icon: <MdContactPhone className="text-dark" />,
  },
  {
    path: "/admin-dashboard/news-letter",
    name: "NewsLetter",
    icon: <FcNews className="text-light" />,
  },
];

const SideBar = ({ children }) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "110px",
      transition: {
        duration: 0.5,
      },
    },
  };

  
  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "230px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}>
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <Link
                  to={"/"}
                  title="Homepage-redireact "
                  aria-label="homepage navigate link">
                  <motion.img
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                    src={logo}
                    alt="New Project"
                  />
                </Link>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </AnimatePresence>
          </div>

          <section className="routes">
            {routes
              .filter((route) =>
                search.toLowerCase() === ""
                  ? true
                  : route.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((route, index) => {
                if (route.subRoutes) {
                  route.subRoutes.filter((route) =>
                    search.toLowerCase() === ""
                      ? true
                      : route.name.toLowerCase().includes(search.toLowerCase())
                  );
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      width={100}
                    />
                  );
                }
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeClassName="active">
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text">
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
          </section>
        </motion.div>

        <main className="centerbar">
          <Tobbar />
          {children}
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default SideBar;
