import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Contextapi from "../Contextpage/Contextapi";


function Contact() {
  const [data, setData] = useState(null);
 
  const [formData, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(() => ({ Client_ID: keyid }), [keyid]);

  const handleFormChange = (e) => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${domainname}/api/add-contact`,
        formData,
        { params }
      );

      setFormdata({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      })
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });

    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "warning",
        title: error.response.data.message
      });
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const data = contextdata.APIdata.siteConfig;
    setData(data);
  }, [contextdata]);


  return (
    <>
      {/* CONTENT START */}
      <div className="page-content">
        <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
          <div className="overlay-main site-bg-white opacity-01" />
          <div className="container">
            <div className="wt-bnr-inr-entry">
              <div className="banner-title-outer">
                <div className="banner-title-name">
                  <h2 className="wt-title">Contact Us</h2>
                </div>
              </div>
              <div>
                <ul className="wt-breadcrumb breadcrumb-style-2">
                  <li>
                    <Link to="/" title="Home Page">Home</Link>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* CONTACT FORM */}
        <div className="section-full twm-contact-one">
          <div className="section-content">
            <div className="container">
              {/* CONTACT FORM*/}
              <div className="contact-one-inner">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="contact-form-outer">
                      <div className="section-head left wt-small-separator-outer">
                        <h2 className="wt-title">Send Us a Message</h2>
                        <p>
                          Feel free to contact us and we will get back to you as
                          soon as we can.
                        </p>
                      </div>
                      <form
                        className="cons-contact-form"
                        onSubmit={sendMessage}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-3">
                              <input
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleFormChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-3">
                              <input
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleFormChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-3">
                              <input
                                name="phone"
                                type="number"
                                className="form-control"
                                placeholder="Mobile No"
                                maxLength="10"
                                value={formData.phone}
                                onChange={handleFormChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-3">
                              <input
                                name="subject"
                                type="text"
                                className="form-control"
                                placeholder="Subject"
                                value={formData.subject}
                                onChange={handleFormChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <textarea
                                name="message"
                                className="form-control"
                                rows={3}
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleFormChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button type="submit" className="site-button">
                            Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="contact-info-wrap">
                      <div className="contact-info">
                        {!data ? (
                          <div className="contact-info-section">
                            <div className="c-info-column">
                              <div className="c-info-icon">
                                <i className="fas fa-map-marker-alt" />
                              </div>
                              <h3 className="twm-title">Address</h3>
                              <p>
                                R&G SHRAMIK MITRA (OPC) PRIVATE LIMITED 221
                                Landmark- 1 Pandhana, Road, Mansingh Chouraha,
                                Khandwa, Khandwa, East Nimar- 450001, Madhya
                                Pradesh
                              </p>
                            </div>
                            <div className="c-info-column">
                              <div className="c-info-icon custome-size">
                                <i className="fas fa-mobile-alt" />
                              </div>
                              <h3 className="twm-title">
                                Feel free to contact us
                              </h3>
                              <p>
                                <a href="tel:6265861847">
                                  6265861847
                                </a>
                              </p>
                              <p>
                                <a href="tel:6265861847">
                                  6265861847
                                </a>
                              </p>
                            </div>
                            <div className="c-info-column">
                              <div className="c-info-icon">
                                <i className="fas fa-envelope" />
                              </div>
                              <h3 className="twm-title">Support</h3>
                              <p>
                                <a href="mailto:rgshramikmitra@gmail.com">
                                  rgshramikmitra@gmail.com
                                </a>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="contact-info-section">
                            <div className="c-info-column">
                              <div className="c-info-icon">
                                <i className="fas fa-map-marker-alt" />
                              </div>
                              <h3 className="twm-title">Address</h3>
                              <p>
                                {data.address || 'R&G SHRAMIK MITRA (OPC) PRIVATE LIMITED 221 Landmark- 1 Pandhana, Road, Mansingh Chouraha, Khandwa, Khandwa, East Nimar- 450001, Madhya Pradesh'}
                              </p>
                            </div>
                            <div className="c-info-column">
                              <div className="c-info-icon custome-size">
                                <i className="fas fa-mobile-alt" />
                              </div>
                              <h3 className="twm-title">
                                Feel free to contact us
                              </h3>
                              <p>
                                <a href={`tel:${data.contact_number}`}>
                                  {data.contact_number}
                                </a>
                              </p>
                              <p>
                                <a href={`tel:${data.alternate_contact_number}`}>
                                  {data.alternate_contact_number}
                                </a>
                              </p>
                            </div>
                            <div className="c-info-column">
                              <div className="c-info-icon">
                                <i className="fas fa-envelope" />
                              </div>
                              <h3 className="twm-title">Support</h3>
                              <p>
                                <a href={`mailto:${data.email}`}>
                                  {data.email}
                                </a>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CONTENT END */}
    </>
  );
}

export default Contact;