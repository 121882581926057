import React, { useContext, useEffect, useMemo, useState } from "react";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import Contextapi from "../../Contextpage/Contextapi";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const columns = [
  {
    name: '#',
    selector: (row, index) => index + 1,
    sortable: true,
  },
  {
    name: 'Name',
    selector: row => row.name || '--',
    sortable: true,
  },
  {
    name: 'Email',
    selector: row => row.email || '--',
    sortable: true,
  },
  {
    name: 'Mobile',
    selector: row => row.phone || "91-988989898",
    sortable: false,
  },
  {
    name: 'Subject',
    selector: row => row.subject || '--',
    sortable: true,
  },
  {
    name: 'Description',
    selector: row => row.message || '--',
    sortable: true,
  },

];
const customStyles = {
  headCells: {
    style: {

      fontWeight: 'bold',
      color: 'gray',
      backgroundColor: '#f1f1f1',
    },
  },
};
const Contactadmin = () => {
  const [data, SetData] = useState("");
  const [Loading, setLoading] = useState(true);


  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllJobs");
    XLSX.writeFile(wb, "AllJobs.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("All Jobs Report", 20, 10);
    doc.autoTable({
      head: [['S No', 'Name', 'Email', 'Phone', 'Subject', 'Message']],
      body: data.map((item, index) => [
        index + 1,
        item.name || '--',
        item.email || '--',
        item.phone || '--',
        item.subject || '--',
        item.message || '--',
       
      ]),
    });
    doc.save('AllJobs.pdf');
  };
  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-contact`, {
          params,
        });

        // console.log("contact", response);

        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [params, domainname]);


  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }
  if (!data) {
    return null;
  }

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label=" breadcrumb ">
            <ol className="breadcrumb  p-3">
              <li className="breadcrumb-item ">
                <Link to="/admin-dashboard/home" className="text-decoration-none ">
                  Admin{'/'}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              Contact-Us
              </li>
            </ol>
          </nav>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <button className="btn btn-success me-2" onClick={exportToExcel}>Export to Excel</button>
          <button className="btn btn-danger" onClick={exportToPDF}>Export to PDF</button>
        </div>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          paginationPerPage={7}
          customStyles={customStyles}
        />


      </div>
    </div>

  );
};

export default Contactadmin;
