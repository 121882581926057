import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-png.png";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import Swal from "sweetalert2";
import { Dropdown } from "react-bootstrap";

import Contextapi from "../Contextpage/Contextapi";

function Header() {
 
  const Contextdata = useContext(Contextapi);
  const [siteconfig, setSiteconfig] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const path = localStorage.getItem("path");
  const navigate = useNavigate();
  const pathdashboard = path && path ? path.replace(/"/g, "") : "";
  const isUserLoggedIn = () => {
    return localStorage.getItem("token") !== null;
  };
  useEffect(()=>{
    const fetchdata = async()=>{
      const responseSite = await Contextdata.APIdata.siteConfig;
      setSiteconfig(responseSite);
    }
    fetchdata()
  },[Contextdata])

  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("path");
    localStorage.removeItem("role");
    localStorage.removeItem('name');
    localStorage.removeItem('id');

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1200,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Log-out successfully",
    });
    navigate("/");
  };


  return (
    <>
      {/* HEADER START */}
      <div className="top_the_sticky">

        <header
          className={`site-header header-style-3 h-page-15-hdr mobile-sider-drawer-menu ${isActive ? "active" : ""
            }`}>
          <div className="sticky-header main-bar-wraper  navbar-expand-lg">
            <div className="main-bar">
              <div className="container-fluid clearfix">
                <div className="logo-header d-flex  align-items-center">
                  <div className="logo-header-inner logo-header-one">
                    <Link to="/" title="Home Page">
                      <img
                        src={siteconfig.logo || logo}
                        alt={siteconfig.seo_title || "R&G Shramik Mitra"}
                        title={siteconfig.seo_title || "R&G Shramik Mitra"}
                      />
                    </Link>
                  </div>
                  {/* <div className="header-heading">
                    <p className="m-0  ">R&G Shramik Mitra OPC Pvt Ltd</p>
                  </div> */}
                </div>
                {/* NAV Toggle Button */}
                <button
                  title="Toggel menu"
                  id="mobile-side-drawer"
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggler collapsed"
                  onClick={toggleActive}>
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar icon-bar-first"></span>
                  <span className="icon-bar icon-bar-two"></span>
                  <span className="icon-bar icon-bar-three"></span>
                </button>
                {/* MAIN Vav */}
                <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                  <ul className="nav navbar-nav">
                    <li className="has-mega-menu">
                      <NavLink
                        to="/"
                        className={isActive ? "active" : ""}
                      >
                      Home
                      </NavLink>
                    </li>
                    <li className="has-mega-menu">
                      <NavLink
                        to="/view-jobs"
                        className={isActive ? "active" : ""}
                      >
                       Jobs
                      </NavLink>
                    </li>
                   
                    <li>
                      <NavLink
                        to="/about-us"
                        title="about us"
                        className={isActive ? "active" : ""}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/gallery"
                        title="gallery"
                        className={isActive ? "active" : ""}
                      >
                        Gallery
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contact-us"
                        title="contact us"
                        className={isActive ? "active" : ""}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>

                </div>
                {/* Header Right Section*/}
                <div className="extra-nav header-2-nav">
                  
                  <div className="extra-cell">
                    <div className="header-nav-btn-section">
                      {isUserLoggedIn() ? (
                        <div className="twm-nav-btn-right">
                          <Dropdown>
                            <Dropdown.Toggle title="" variant="light" id="user-dropdown">
                              <FaUser />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link
                                  to={`/${pathdashboard}`}
                                  title={`${pathdashboard}`}
                                  aria-label={`${pathdashboard}`}
                                  className="">
                                  <FaUser /> Dashboard
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item onClick={handleLogout}>
                                <FaSignOutAlt /> Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <div className="twm-nav-btn-left">
                          <NavLink
                            className=""
                            aria-label="Signin page 
                            & Login page"
                            title="Login | Acccess for dashboard"
                            to="/login"
                            role="button ">
                            <button className="textlogin site-button">Login</button>

                            {/* <i className="fa-solid fa-arrow-right-from-bracket"> <span className="textlogin">Login</span> </i> */}

                          </NavLink>
                          <NavLink
                            className=""
                            aria-label="Signup page 
                            & Login page"
                            title="Registration | Acccess for dashboard"
                            to="/registration"
                            role="button ">
                            <button className="textlogin site-button">Signup</button>

                            {/* <i className="fa-solid fa-arrow-right-from-bracket"> <span className="textlogin">Login</span> </i> */}

                          </NavLink>

                        </div>
                      )}

                    </div>
                  </div>
                  <div className="extra-cell g-translate">
                  {/* <div className="google_translate" id="google_translate_element"></div> */}
                  {/* <GoogleTranslate  /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* SITE Search */}
          </div >
        </header >
      </div >
      {/* HEADER END */}
    </>
  );
}

export default Header;