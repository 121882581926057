import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Reactpaginate from "react-paginate";
import Swal from "sweetalert2";
import { FaEye, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Contextapi from "../../../Contextpage/Contextapi";
import Profileofemployeeuser from "./Profileofemployeeuser";
import MUIDataTable from "mui-datatables";

const Allemployees = () => {
  const [data, SetData] = useState([]);
  const [Loading, setLoading] = useState(true);
  // const [input, Setinput] = useState("");
  // const navigate = useNavigate();

  // const [pageNumber, setPageNumber] = useState(0);
  // const userPerPage = 5;
  // const pageCount = Math.ceil(data.length / userPerPage);
  // const offset = pageNumber * userPerPage;

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  // console.log("EmployeeData", data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-all-employee`, {
          params,
        });

        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [params, domainname]);

  const handleEdit = async (id, value) => {


    const active = value;

    try {
      const res = await axios.post(`${domainname}/api/update-employee-status/${id}`, { active }, {

        params: {
          Client_ID: keyid,
        }

      });


      const updatedData = data.map(item =>
        item.employee_id === id ? { ...item, active } : item
      );
      SetData(updatedData);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: res.data.message,
      });
    } catch (error) {

      console.error("There was a problem with your update operation:", error);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: error.res.data.message,
      });
    }
  };






  const columns = [
    {
      name: "employee_id",
      label: "S No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, action) =>
          // console.log(action),
          value ? action.rowIndex + 1 : " "
      }

    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, action) =>
        (
          <Link title={`${action.rowData[1]} view-profile`}
            // employee name
            to={`/admin-dashboard/employees/all-employees/user-profile/emp/${action.rowData[0]}`}>
            {/* employee_id */}
            <span>{value} </span>
          </Link >)
      }

    },
    {
      name: "email",
      label: "Email",

    },
    {
      name: "mobile",
      label: "Mobile",

    },

    {
      name: "registered_by",
      label: "Registered By",

    },
    {
      name: "profession",
      label: "Profession",

    },
    {
      name: "active",
      label: "Active",
      options: {
        customBodyRender: (item, action) => (

          <select
            className={`form-select ${item == 'true' ? 'text-success' : 'text-danger'}`}
            value={item}
            onChange={(e) => handleEdit(action.rowData[0], e.target.value)}
          >
            <option value='true'>True</option>
            <option value='false'>False</option>
          </select>
        )
      }

    },

    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, action) => (
          <Link title={`${action.rowData[1]} view-profile`}
            // employee name
            to={`/admin-dashboard/employees/all-employees/user-profile/emp/${action.rowData[0]}`}>
            {/* employee_id */}
            <button type="button" className="btn btn-primary ms-2"><FaEye /></button>
          </Link >)

      },
    }
  ];
  const options = {
    selectableRows: "none",
    filterType: 'checkbox',
    rowsPerPageOptions: [5, 10, 15, 20, 25, 30],
  };
  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }

  // console.log("Employee data", data)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" upshowing">
          <div className="row">
            <div className="col-lg-5">
              <nav aria-label=" breadcrumb ">
                <ol className="breadcrumb  p-3">
                  <li className="breadcrumb-item ">
                    <Link
                      to="/admin-dashboard/home"
                      className="text-decoration-none ">
                      Admin
                    </Link>
                  </li>
                  <li className="ms-1 " aria-current="page">
                    {"/"}  Employees 
                  </li>
                </ol>
              </nav>
            </div>

          </div>

          <MUIDataTable
            title={"Employee List"}
            data={data}
            columns={columns}
            options={options}

          />

        </div>
      </div>
    </div>
  );
};
export default Allemployees;
