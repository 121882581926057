import React, { lazy, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import girllarge from '../../assets/images/gir-large.png';
import { LinearProgress } from "@mui/material";
import Contextapi from '../Contextpage/Contextapi';
import { Helmet } from "react-helmet";
function About() {
  const [Aboutdata, setAboutdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const contextData = useContext(Contextapi);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await contextData.APIdata.about;
        setAboutdata(responseData);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching site config data:", error);
      }
    };
    fetchData();
  }, [contextData]);
  // console.log("about", Aboutdata)

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }
  // console.log("About us ",Aboutdata);
  return (

    <div className="page-content">
      <Helmet>
        <title>{Aboutdata.seo_title === null || Aboutdata.seo_title === "null" ?  'About page title' :Aboutdata.seo_title }</title>
        <meta name="description" content={Aboutdata.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
        <meta property="og:title" content={Aboutdata.seo_title || "R&G Shramik - About page "} />
        <meta name="keywords" content={Aboutdata.seo_keywords || "The company engaging our services is responsible for covering the traveling costs of hired labor"} />

        {Aboutdata && Aboutdata.author ? <meta name="author" content={Aboutdata.author} /> : <meta name="author" content=" Rohit Patel" />}
        {Aboutdata && Aboutdata.publisher ? <meta name="publisher" content={Aboutdata.publisher} /> : <meta name="publisher" content="SDMIPL Team Frontend - Rohit patel and Backend Udit Chouhan " />}
        <link rel="canonical" href="https://www.rgshramikmitra.com/" />
      </Helmet>
      {/* INNER PAGE BANNER */}
      <div
        className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center"
      >
        <div className="overlay-main site-bg-white opacity-01" />
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title">About Us</h2>
              </div>
            </div>
            {/* BREADCRUMB ROW */}
            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About Us</li>
              </ul>
            </div>
            {/* BREADCRUMB ROW END */}
          </div>
        </div>
      </div>
      {/* INNER PAGE BANNER END */}

      <div className="aboutus section-full p-t120 p-b120 twm-explore-area bg-cover">
        <div className="container">

          <div className="section-content">

            <div className="twm-explore-media-wrap pe-3">

              <img className='rounded-3' src={`${Aboutdata.about_image || girllarge}`} alt="aboutimg" title='About-Page' onLoad={lazy} />

            </div>



            <div className="who-we-are-content ">
              <span>Who We Are</span>
              <h2>
                {Aboutdata.title}
              </h2>
              <p className='text-justify mb-0' dangerouslySetInnerHTML={{ __html: Aboutdata.description }}>

              </p>

            </div>


          </div>
        </div>


      </div>
      {/* EXPLORE NEW LIFE END */}
    </div>

  )
}

export default About;