import React, { useRef, useEffect } from 'react';
import searchimg from "../../../assets/images/job-search.png";
import applyimg from "../../../assets/images/job-apply.png";
import userimg from "../../../assets/images/user.png";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const ApplyProcess = () => {
    const applyprocess1 = useRef(null);
    const applyprocess2 = useRef(null);
    const applyprocess3 = useRef(null);

    useEffect(() => {
        const sections = [applyprocess1.current, applyprocess2.current, applyprocess3.current];
        gsap.fromTo(sections, 
            { opacity: 0, y: 50 }, 
            {
                opacity: 1,
                y: 0,
                duration: 1,
                stagger: 0.3,
                scrollTrigger: {
                    trigger: sections,
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none none'
                }
            }
        );
    }, []);

    return (
        <div className='apply-process'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <p className='text-center text-danger fs-3 fw-semi-bold'>Apply Process</p>
                        <h3 className='apply-process-heading text-center mb-5'>How To Apply</h3>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='card' ref={applyprocess1}>
                            <div className='card-image text-center'>
                                <img src={searchimg} alt='Search for a job' className='img-fluid' title='Search for a job' />
                            </div>
                            <div className='card-content text-center'>
                                <h3>1. Search for a Job</h3>
                                <p>Browse through our extensive job listings to find the perfect role that matches your skills and preferences.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='card' ref={applyprocess2}>
                            <div className='card-image text-center'>
                                <img src={applyimg} alt='Apply for a job' className='img-fluid' title='Apply for a job' />
                            </div>
                            <div className='card-content text-center'>
                                <h3>2. Apply for the Job</h3>
                                <p>Submit your application with a tailored resume and cover letter to stand out from the competition.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='card' ref={applyprocess3}>
                            <div className='card-image text-center'>
                                <img src={userimg} alt='Get your job' className='img-fluid' title='Get your job' />
                            </div>
                            <div className='card-content text-center'>
                                <h3>3. Get the Job</h3>
                                <p>Once you’re selected, start your new career and achieve your professional goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplyProcess;
