import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import job_pic from '../../../assets/images/jobs-company/pic1.jpg';
import Contextapi from '../../Contextpage/Contextapi';
import ReactPaginate from 'react-paginate';
import { FaArrowRight } from 'react-icons/fa';


const ViewJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [Category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage] = useState(7);
    const [jobTypeFilter, setJobTypeFilter] = useState('');
    const [jobModeFilter, setJobModeFilter] = useState('');
    const [jobTypeFilters, setJobTypeFilters] = useState([]);
    const [locationFilter, setLocationFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [titleFilter, setTitleFilter] = useState('');
    const [fullTimeJob, setFullTimeJob] = useState(0);
    const [partTimeJob, setPartTimeJob] = useState(0);
    const [internshipJob, setInternshipJob] = useState(0);
    const contextData = useContext(Contextapi);

    // console.log("view jobs ", jobs);

    useEffect(() => {
        if (contextData && contextData.APIdata) {
            const categoryData = contextData.APIdata.jobCategory;
            const jobsData = contextData.APIdata.jobs;
            setCategory(categoryData);
            const todayDate = new Date();
            setJobs(jobsData.filter((item) => {
                const jobEndDate = new Date(item.job_end_date);
                return jobEndDate.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0);
            }));
            setLoading(false);
            // simulateNewNotification();
        }
    }, [contextData]);
    const filteredJobs = jobs.filter(job => {
        if (jobTypeFilter && jobTypeFilter !== 'Jobs types' && jobTypeFilter !== job.job_type) {
            return false;
        }
        if (jobModeFilter && jobModeFilter !== 'job mode' && jobModeFilter !== job.job_mode) {
            return false;
        }
        if (locationFilter && job.job_city.toLowerCase().indexOf(locationFilter.toLowerCase()) === -1) {
            return false;
        }
        if (titleFilter && job.job_title.toLowerCase().indexOf(titleFilter.toLowerCase()) === -1) {
            return false;
        }
        if (categoryFilter && categoryFilter !== 'All Category' && categoryFilter !== job.industry_type) {
            // console.log("Job Category ",job);
            return false;
        }
        if (jobTypeFilters.length === 0) {
            return true;
        }
        return jobTypeFilters.includes(job.job_type);
    });

    const handleJobTypeFilterChange = (event) => {
        const selectedJobType = event.target.value;
        if (event.target.checked) {
            setJobTypeFilters([...jobTypeFilters, selectedJobType]);
        } else {
            setJobTypeFilters(jobTypeFilters.filter(jobType => jobType !== selectedJobType));
        }
    };
    const handleLocationFilterChange = (event) => {
        setLocationFilter(event.target.value);
    };
    const hanndletitlefilter = (e) => {
        setTitleFilter(e.target.value)
    }
    const handleCategoryFilterChange = (event) => {
        setCategoryFilter(event.target.value);
    };
    // const handleDateFilterChange = (days) => {
    //     const currentDate = new Date();
    //     const filteredJobs = jobs.filter(job => {
    //         const jobDate = new Date(job.updated_at);
    //         const differenceInTime = currentDate.getTime() - jobDate.getTime();
    //         const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    //         return differenceInDays <= days;
    //     });
    //     console.log("filter", filteredJobs)
    //     return (filteredJobs);
    // };


    useEffect(() => {
        const fullTimeTypeJob = jobs.filter((item) => item.job_type === "Full time");
        setFullTimeJob(fullTimeTypeJob.length)

        const partTimeTypeJob = jobs.filter((item) => item.job_type === "Part time");
        setPartTimeJob(partTimeTypeJob.length);

        const internshiTypeJob = jobs.filter((item) => item.job_type === "Internship");
        setInternshipJob(internshiTypeJob.length);
    }, [jobs])

    const totaljobs = filteredJobs.length;

    const calculateDaysAgo = (dateString) => {
        if (!dateString) return 0;
        const currentDate = new Date();
        const jobDate = new Date(dateString);
        const differenceInTime = currentDate.getTime() - jobDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return Math.floor(differenceInDays);
    };

    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
    // console.log("current ",currentJobs)

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };
    // console.log("jobs", jobs)

    return (
        <div>
            <div className="page-content">
                <div
                    className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center"
                >
                    <div className=" overlay-main site-bg-white opacity-01" />
                    <div className="container">
                        <div className="wt-bnr-inr-entry">
                            <div className="banner-title-outer">
                                <div className="banner-title-name">
                                    <h2 className="wt-title">The Most Exciting Jobs</h2>
                                </div>
                            </div>
                            {/* BREADCRUMB ROW */}
                            <div>
                                <ul className=" wt-breadcrumb breadcrumb-style-2">
                                    <li>
                                        <Link to="/" title='Home page'>Home</Link>
                                    </li>
                                    <li>Jobs List</li>
                                </ul>
                            </div>
                            {/* BREADCRUMB ROW END */}
                        </div>
                    </div>
                </div>
                {/* INNER PAGE BANNER END */}
                {/* OUR BLOG START */}

                <div className="section-full p-t120  p-b90 site-bg-white">
                    <div className="container">
                        {/* <div className='row'>
                            <div className="notification-section">
                                <h3><IoIosNotifications className='' title='Job-Alert' /> {unreadNotificationCount > 0 && <p> ({unreadNotificationCount}) </p>} </h3>
                                <button className='btn p-2 btn-warning' onClick={markAsRead}>Mark All as Read</button>
                                {renderNotifications()}
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 rightSidebar">
                                <div className="side-bar">
                                    <div className="sidebar-elements search-bx">
                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Keyword</h4>
                                            <div className="input-group sidebar_icon">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={titleFilter}
                                                    onChange={hanndletitlefilter}
                                                    placeholder="Serch-keywords "
                                                />

                                                <i className="feather-search" />

                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Location</h4>
                                            <div className="input-group sidebar_icon">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search location"
                                                    value={locationFilter}
                                                    onChange={handleLocationFilterChange}
                                                />

                                                <i className="feather-map-pin" />
                                            </div>
                                        </div>
                                        {Category &&
                                            <div className="form-group mb-4" >
                                                <h4 className="section-head-small mb-4">Category</h4>
                                                <select
                                                    className="wt-select-bar-large"
                                                    data-live-search="true"
                                                    data-bv-field="size"
                                                    onChange={handleCategoryFilterChange}
                                                >
                                                    <option value={''}>All Category</option>
                                                    {Category.map((cate) => (
                                                        <option key={cate.id} value={cate.title}>{cate.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }

                                        <div className="twm-sidebar-ele-filter">
                                            <h4 className="section-head-small mb-4">Job Type</h4>
                                            <ul>
                                                <li>
                                                    <div className=" form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value="Full time"
                                                            onChange={handleJobTypeFilterChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="fullTimeCheckbox">
                                                            Full Time
                                                        </label>
                                                    </div>
                                                    <span className="twm-job-type-count">{fullTimeJob}</span>
                                                </li>
                                                <li>
                                                    <div className=" form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="internshipCheckbox"
                                                            value="Internship"
                                                            onChange={handleJobTypeFilterChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="internshipCheckbox">
                                                            Internship
                                                        </label>
                                                    </div>
                                                    <span className="twm-job-type-count">{internshipJob}</span>
                                                </li>
                                                <li>
                                                    <div className=" form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value="Part time"
                                                            onChange={handleJobTypeFilterChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="partTimeCheckbox">
                                                            Part Time
                                                        </label>
                                                    </div>
                                                    <span className="twm-job-type-count">{partTimeJob}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="twm-sidebar-ele-filter">
                                            <h4 className="section-head-small mb-4">Date Posts</h4>
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            name='daysfilter'
                                                            className="form-check-input"
                                                            id="exampleradio1"
                                                          onChange={() => handleDateFilterChange(1)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleradio1"
                                                        >
                                                            Last hour
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            name='daysfilter'
                                                            className="form-check-input"
                                                            id="exampleradio2"
                                                        onChange={() => handleDateFilterChange(1)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleradio2"
                                                        >
                                                            Last 24 hours
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="exampleradio3"
                                                            name='daysfilter'
                                                        onChange={() => handleDateFilterChange(7)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleradio3"
                                                        >
                                                            Last 7 days
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            name='daysfilter'
                                                            className="form-check-input"
                                                        onChange={() => handleDateFilterChange(14)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleradio4"
                                                        >
                                                            Last 14 days
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name='daysfilter'
                                                        onChange={() => handleDateFilterChange(30)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleradio5"
                                                        >
                                                            Last 30 days
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name='daysfilter'
                                                        onChange={() => handleDateFilterChange(9999)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleradio6"
                                                        >
                                                            All
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                   
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-9 col-12">
                                <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                                    <span className="woocommerce-result-count-left text-secondary">
                                        Showing {totaljobs} Jobs
                                    </span>
                                    <div
                                        className="woocommerce-ordering twm-filter-select"
                                        method="get"
                                    >
                                        
                                        <select
                                            className="wt-select-bar-2"
                                            data-live-search="true"
                                            data-bv-field="size"
                                            onChange={(e) => setJobTypeFilter(e.target.value)}
                                        >

                                            <option value='' disabled>
                                                Select Jobs Type
                                            </option>
                                            <option value='' >
                                              All 
                                            </option>
                                            <option value='Full time'>
                                                Full Time
                                            </option>
                                            <option value='Part time'>
                                                Part Time
                                            </option>
                                            <option value='Internship'>
                                                Internship
                                            </option>
                                        </select>
                                        <select
                                            className="wt-select-bar-2"
                                            data-live-search="true"
                                            data-bv-field="size"
                                            onChange={(e) => setJobModeFilter(e.target.value)}
                                        > 
                                            <option value='' disabled > Select Job Mode</option>
                                            <option value='' >
                                              All 
                                            </option>
                                            <option value='Work from home'>
                                                Work From Home
                                            </option>
                                            <option value='Work from office'>
                                                Work From Office
                                            </option>
                                            <option value='Hybird'>
                                                Hybird
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                {filteredJobs.length === 0 ? (
                                    <div className='twm-jobs-list-wrap'>
                                        <div className="twm-jobs-list-style1 mb-5 justify-content-center align-itmes-center">
                                            <p className='h1 heading_nofound'>No Job Found.</p>
                                        </div>
                                    </div>
                                ) : (

                                    <div className="twm-jobs-list-wrap">
                                        <ul>
                                            {filteredJobs && currentJobs.map(job => (
                                                <li key={job.id}>
                                                    <div className="twm-jobs-list-style1 mb-5">
                                                        <div className="twm-media">
                                                            <img src={job.company_logo ? job.company_logo : job_pic} alt="jobcmp" title={job.job_title} loading="lazy" />
                                                        </div>
                                                        <div className="twm-mid-content">
                                                            <Link to="#" className="twm-job-title">
                                                                <h4>
                                                                    {job.job_title} {'   '}
                                                                    <span className="twm-job-post-duration">
                                                                        / Posted:  {' '} {`${calculateDaysAgo(job.updated_at)} days ago`}
                                                                    </span>
                                                                </h4>
                                                            </Link>
                                                            <p className="twm-job-address">
                                                                {job.job_address}
                                                            </p>
                                                            <Link
                                                                href="https://themeforest.net/user/thewebmax/portfolio"
                                                                className="twm-job-websites site-text-primary" target='_blank'
                                                            >
                                                                {job.company_website}
                                                            </Link>
                                                        </div>
                                                        <div className="twm-right-content">
                                                            <div className="twm-jobs-category green">
                                                                <span className="twm text-secondary">{job.job_mode}</span>
                                                            </div>
                                                            <div className="twm-jobs-amount">
                                                                {job.package}{''} <span>/ Month</span>
                                                            </div>
                                                            <Link
                                                                to={`/job/${job.slug}`}
                                                                title={job.job_title}
                                                                className="twm-jobs-browse btn site-text-primary"
                                                            >
                                                                <p className="twm-jobs-category sky">
                                                                    <span className="twm-bg-sky"> Browse Job <FaArrowRight className='' /> </span>
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <div className="pagination pagination-lg justify-content-center text-center mt-3">
                                    <div className="pagination-outer pb-3">
                                        <div className="">
                                            <ReactPaginate
                                                pageCount={Math.ceil(jobs.length / jobsPerPage)}
                                                onPageChange={handlePageChange}
                                                containerClassName="pagination justify-content-center pagination-lg 
                                                pagination-sm pagination-col"
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active pb-3"
                                                pageClassName="page-item ml-1 mr-1"
                                                pageLinkClassName="page-link"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewJobs