import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FaPen, FaPlus } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import Contextapi from '../../Contextpage/Contextapi';
import CmpAddTestimonial from './CmpAddTestimonial';
import { Link } from 'react-router-dom';
import RichText from '../../Admin-dashboard/pages/Subpages/RichTexteditor';
import StarRating from '../../Pages/Star/RatingStar';

const CompanyTestiview = () => {
    const [data, setData] = useState(null);
    const [Profile, setProfile] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [addFormVisible, setAddFormVisible] = useState(false);
    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const id = getid.replace(/"/g, '');
    const contextData = useContext(Contextapi);
    const domainName = contextData.domainapi;
    const keyID = contextData.keyID;

    const [formData, setFormData] = useState({
        rating: '',
        description: '',
    });

 
    const params = useMemo(() => ({
        Client_ID: keyID,
    }), [keyID]);

    useEffect(() => {
        fetchData();
    }, [params, addFormVisible]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${domainName}/api/get-company-testimonial/${id}`, { params });
            setData(response.data || null);
            const profile = await axios.get(`${domainName}/api/get-company/${id}`, { params });
            setProfile(profile.data[0]);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const handleImageChange = (e) => {
    //     const imageFile = e.target.files[0];
    //     setFormData(prevData => ({
    //         ...prevData,
    //         testimonial_image: imageFile,
    //     }));
    // };

    const handleRichTextChange = (name, value) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAdd = () => {
        setAddFormVisible(!addFormVisible);
    };

    const handleUpdate = (testimonial) => {
        setFormData({
            rating: testimonial.rating,
            description: testimonial.description,
        });
        setModalShow(true);
    };
    const handleCloseModal = () => {
        setModalShow(false);
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('description', formData.description);
            formDataToSend.append('rating', formData.rating);
            // if (formData.testimonial_image) {
            //     formDataToSend.append('testimonial_image', formData.testimonial_image);
            // }

            const res = await axios.post(
                `${domainName}/api/update-company-testimonial/${id}`,
                formDataToSend,
                { params: { Client_ID: keyID } }
            );

            Swal.fire({
                icon: 'success',
                title: 'Testimonial updated successfully!',
                showConfirmButton: false,
                timer: 2000,
            });

            fetchData();
            handleCloseModal();
        } catch (error) {
            console.error('Error updating testimonial:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Please try again.',
            });
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }
    // console.log("testimonial", data)
    return (
        <div className='container-fluid upshowing'>
            <div className='row'>
                <div className='col-lg-6'>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb  p-3'>
                            <li className='breadcrumb'>
                                <Link to='/' title='home' className='text-decoration-none '>
                                    Home
                                </Link>
                            </li>
                            {' / '}
                            <li className='breadcrumb'>
                                <Link to='/company-dashboard/home' title='company-dashboard' className='text-decoration-none '>
                                    Company-Dashboard
                                </Link>
                            </li>
                            {' / '}
                            <li className='breadcrumb active' aria-current='page'>
                                Testimonial
                            </li>
                        </ol>
                    </nav>
                </div>
                {!addFormVisible && <div className='col-lg-6 d-flex justify-content-end upbtn'>
                    {(!data || data.length === 0) && <button className='d-flex align-items-center gap-2' onClick={handleAdd}> <FaPlus className='p-1'/>Add Testimonial</button>}
                </div>
}
            </div>
            {addFormVisible ? (
                <CmpAddTestimonial handleAdd={handleAdd} profiledata={Profile} />
            ) : (
                <div className='row'>
                    <div className='col-lg-12'>
                        <table className='table table-striped table-hover text-center'>
                            <thead>
                                <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>Image</th>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Rating</th>
                                    <th scope='col'>Description</th>
                                    <th scope='col'>Action</th>
                                </tr>
                            </thead>
                            {!(!data || data.length === 0) ? (
                                <tbody>
                                    <tr className='content-related align-middle'>
                                        <td>{'1'}</td>
                                        <td>
                                            <img className='testiimage' src={Profile.company_logo || 'https://www.rgshramikmitra.com/static/media/logo-png.6aa7f2a0bf0a46bceda7.png'} title={data.title} alt={data.title} />
                                        </td>
                                        <td>{data.name}</td>
                                        <td> <div className='rating_star_testi '><StarRating rating={data.rating} /> </div></td>
                                        <td>
                                            <span className='discription_content' dangerouslySetInnerHTML={{ __html: data.description }} />
                                        </td>
                                        <td>
                                            <button className='btn btn-primary' onClick={() => handleUpdate(data)}>
                                                <FaPen />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="12" className="no-data-message">No testimonial available</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            )}
            <Modal show={modalShow} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Testimonial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='shadow p-2' onSubmit={handleEdit} encType='multipart/form-data'>
                        <div className="row m-3">
                            <div className="col-lg-12">
                                <label htmlFor="rating" className="form-label">Rating</label>
                                <select
                                    name='rating'
                                    onChange={handleInputChange}
                                    value={formData.rating}
                                    className="form-select"
                                    required
                                >
                                    <option value="">Select Rating</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            {/* <div className="col-lg-6">
                                <label htmlFor="testimonial_image" className="form-label">Image</label>
                                <input
                                    type="file"
                                    name='testimonial_image'
                                    onChange={handleImageChange}
                                    className="form-control"
                                    accept='image/*'
                                />
                            </div> */}
                            <div className="col-lg-12">
                                <label htmlFor="description" className="form-label">Description</label>
                                <RichText
                                    name="description"
                                    placeholder="Enter description"
                                    value={formData.description}
                                    onChange={handleRichTextChange}
                                    required
                                />
                            </div>
                        </div>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={handleCloseModal}>
                                Close
                            </Button>
                            <Button variant='primary' type='submit'>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CompanyTestiview;