import React from 'react';
import { Country, State, City } from 'country-state-city';

const AddressForm = ({ formData, setFormData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCountryChange = (e) => {
        const isoCode = e.target.value;
       
        const countryInfo = State.getStatesOfCountry(isoCode);
        setFormData({
            ...formData,
            country: isoCode,
            state: null, 
            city: null, 
            states: countryInfo,
        });
    };
    

    const handleStateChange = (e) => {
        const stateIsoCode = e.target.value;
    
        const cities = City.getCitiesOfState('IN', stateIsoCode); // Assuming 'IN' is the ISO code for India
        setFormData({
            ...formData,
            state: stateIsoCode,
            city: '', // Reset city when state changes
            cities: cities,
        });
    };
    

    const handleCityChange = (e) => {
        const cityName = e.target.value;
        setFormData({
            ...formData,
            city: cityName,
        });
    };

    return (
        <div className='row'>
            <div className='col-lg-6 '>
                <div className=" form-group mb-3">
                    <label htmlFor="address">Address</label>
                    <input
                        type="text"
                        className="form-control"
                        name="address"
                        placeholder="Enter your address"
                        value={formData.address}
                        onChange={handleChange}
                        
                    />
                </div>
            </div>
            <div className='col-lg-6 '>
                <div className=" form-group mb-3">
                    <label htmlFor="pincode">Zip Code</label>
                    <input
                        type="text"
                        className="form-control"
                        name="pincode"
                        placeholder="Enter your pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        
                    />
                </div>
            </div>
            <div className='col-lg-6 '>
                {/* Country */}
                <div className=" form-group mb-3">
                    <label htmlFor="country">Country</label>
                    <select
                        className="form-control form-select"
                        name="country"
                        value={formData.country}
                        onChange={handleCountryChange}
                    >
                        <option value="">Select Country</option>
                        {Country.getAllCountries().map((country, index) => (
                            <option key={index} value={country.isoCode}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='col-lg-6 '>
                {/* State */}
                <div className=" form-group mb-3">
                    <label htmlFor="state">State</label>
                    <select
                        className="form-control  form-select"
                        name="state"
                        value={formData.state}
                        onChange={handleStateChange}
                    >
                        <option value="">Select State</option>
                        {formData.states &&
                            formData.states.map((state, index) => (
                                <option key={index} value={state.isoCode}>
                                    {state.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className='col-lg-6 '>
                <div className=" form-group mb-3">
                    <label htmlFor="city">City</label>
                    <select
                        className="form-control  form-select"
                        name="city"
                        value={formData.city}
                        onChange={handleCityChange}
                    >
                        <option value="">Select City</option>
                        {formData.cities &&
                            formData.cities.map((city, index) => (
                                <option key={index} value={city.name}>
                                    {city.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default AddressForm;
