import React, { useState ,useEffect, useMemo, useContext} from 'react';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TiArrowBack } from 'react-icons/ti';
import { FaCheck, } from 'react-icons/fa';
import { RxCross2 } from "react-icons/rx";
import Contextapi from '../../../../Contextpage/Contextapi';
import axios from 'axios';

const ViewSingleJob = () => {
    const location = useLocation();
    const { job: jobFromState } = location.state || {};
    const [job, setJob] = useState(jobFromState || null);
    const [jobData, setJobData] = useState([]);
    const [category, setCategory] = useState([]);
    const [Loading, setLoading] = useState(true);

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyid = contextdata.keyID;
  
    const params = useMemo(
      () => ({
        Client_ID: keyid,
      }),
      [keyid]
    );
    // console.log(Company)
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${domainname}/api/get-jobpost`, {
              params,
            });
            const rescategory = await axios.get(
              ` ${domainname}/api/get-jobcategory`,
              { params }
            );
            setCategory(rescategory.data);
            // const rescompany = await axios.get(
            //   `${domainname}/api/get-all-company`,
            //   { params }
            // );
           
            setJobData(response.data.filter((item)=>item.id === job[0])?.[0]);
            setLoading(false);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }, [domainname,]);
    if (!job) {
        return <div>No job details available</div>;
    }
    if (Loading) {
        return ;
    }


    return (
        <section className="admin_profile comany-dashboard">
            <div className="container">
                <div className="row">
                    <Breadcrumb className="p-3">
                        <Breadcrumb.Item as={Link} to="/admin-dashboard/home">
                            Home /
                        </Breadcrumb.Item>
                        <Breadcrumb.Item as={Link} to="/admin-dashboard/company/all-company">
                            All jobs /
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                    </Breadcrumb>
                    <Link to="/admin-dashboard/general-settings/view-jobpost" title='view jobs-request'>
                        <div className="back-button d-flex justify-content-end me-3 mb-3">
                            <button className="site-button px-2 py-1  d-flex gap-2 align-items-center">
                                <TiArrowBack className="text-light fs-5" />
                                <span>
                                    Back
                                </span>
                            </button>

                        </div>
                    </Link>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col ">
                        <div className="company-view-details shadow py-4 mb-3">
                            <h5 className="job-view"> View Job Details    :  </h5>
                            <div className="personal-details">
                                <div className="comapny-details">
                                    <div className='row'>
                                        <div className='col-4 ' title='Job Title'>
                                            <strong>Job Title : </strong> <sapn> {jobData.job_title || "No Title"} </sapn>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Job Type :</strong>  <span>{jobData.job_type || "No Type"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Job Mode :</strong>  <span>{jobData.job_mode || "No Mode"}</span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Job Category : </strong>  <span> {category.find((cat) => cat.id == jobData.job_category)?.title || "No Category"} </span>
                                            
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong> Package:</strong>  <span>{jobData.package || "No Package"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Total Jobs:</strong>  <span>{jobData.total_post || "No Total Jobs"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Eligibility:</strong>  <span>{jobData.eligibility || "No Eligibility"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Experience Required:</strong>  <span>{jobData.experienced_required || "No Experience Required"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Subscription Amount:</strong>  <span> <b>₹</b> {jobData.amount || "No Amount"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Payment Status:</strong>  <span>  {jobData.payment_status ? <FaCheck className='text-success fs-5 fw-bold ' title='success' /> : <RxCross2 className='text-danger fs-5 fw-bold' title='failed' />} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Address:</strong>  <span>{jobData.job_address || "No Address"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>City:</strong>  <span>{jobData.job_city || "No City"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>State:</strong>  <span>{jobData.job_state || "No State"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Days:</strong>  <span>{jobData.days || "No Days"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong >Status:</strong>  <span>{jobData.status || "No Status"} </span>
                                        </div>
                                        <div className="col-md-12 d-flex align-items-center" color="text-secondary">
                                            <strong>Description:</strong>  <span dangerouslySetInnerHTML={{ __html: jobData.job_description }} ></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default ViewSingleJob