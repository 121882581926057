import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleTranslate = () => {
  const location = useLocation();
  useEffect(() => {
    const googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'en,hi',
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: true,
          },
          'google_translate_element'
        );
      } else {
        console.error('Google Translate library is not loaded.');
      }
    };
    googleTranslateElementInit();
  }, [location.pathname]);
  return (
    <div className="google_translate" id="google_translate_element"></div>
  );
};

export default GoogleTranslate;