import { Link, NavLink, Outlet } from "react-router-dom";
import { FaBars, FaHome, FaUser } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AgentSidesubmenu from "./AgentSidesubmenu";
import AgentHeader from "./AgentHeader";
import { FcComments } from "react-icons/fc";
// import logo from "../../../Assets/LOGO.jpg"
import logo from "../../../assets/images/logo-png.png";
const routes = [
  {
    path: "/agent-dashboard/home",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/agent-dashboard/profile",
    name: "Profile",
    icon: <FaUser />,
  },
  {
    path: "/agent-dashboard/employees",
    name: "My Employess",
    icon: <FcComments />,
  },
  // {
  //   path: "/agent-dashboard/companies",
  //   name: "Company list",
  //   icon: <FcComments />,
  // },
];

const AgentSidebar = ({ children }) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "110px",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "230px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}>
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <Link
                  to={"/"}
                  title="Homepage-redireact "
                  aria-label="homepage navigate link">
                  <motion.img
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                    src={logo}
                    alt="New Project"
                  />
                </Link>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </AnimatePresence>
          </div>

          <section className="routes">
            {routes
              .filter((route) =>
                search.toLowerCase() === ""
                  ? true
                  : route.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((route, index) => {
                if (route.subRoutes) {
                  route.subRoutes.filter((route) =>
                    search.toLowerCase() === ""
                      ? true
                      : route.name.toLowerCase().includes(search.toLowerCase())
                  );
                  return (
                    <AgentSidesubmenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      width={100}
                    />
                  );
                }
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeClassName="active">
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text">
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
          </section>
        </motion.div>

        <main className="centerbar">
          <AgentHeader />

          {children}
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default AgentSidebar;
