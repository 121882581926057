import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TiArrowBack } from 'react-icons/ti';
import { FaCheck, } from 'react-icons/fa';
import { RxCross2 } from "react-icons/rx";
const JobDetail = () => {
    const location = useLocation();
    const { job: jobFromState } = location.state || {};
    const [job, setJob] = useState(jobFromState || null);
    // console.log("job details", location);

    if (!job) {
        return <div>No job details available</div>;
    }

    return (
        <section className="admin_profile comany-dashboard">
            <div className="container">
                <div className="row">
                    <Breadcrumb className="p-3">
                        <Breadcrumb.Item as={Link} to="/admin-dashboard">
                            Home /
                        </Breadcrumb.Item>
                        <Breadcrumb.Item as={Link} to="/admin-dashboard/company/all-company">
                            All Companies /
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                    </Breadcrumb>
                    <Link to="/company-dashboard/job-request" title='view jobs-request'>
                        <div className="back-button d-flex justify-content-end me-3 mb-3">
                            <button className="site-button px-2 py-1  d-flex gap-2 align-items-center">
                                <TiArrowBack className="text-light fs-5" />
                                <span>
                                    Back
                                </span>
                            </button>

                        </div>
                    </Link>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col ">
                        <div className="company-view-details shadow py-4 mb-3">
                            <h5 className="job-view"> View Job Details    :  </h5>
                            <div className="personal-details">
                                <div className="comapny-details">
                                    <div className='row'>
                                        <div className='col-4 ' title='Job Title'>
                                            <strong>Job Title : </strong> <sapn> {job.job_title || "No Title"} </sapn>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Job Type :</strong>  <span>{job.job_type || "No Type"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Job Mode :</strong>  <span>{job.job_mode || "No Mode"}</span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Job Category : </strong>  <span>{job.job_category || "No Category"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong> Package:</strong>  <span>{job.package || "No Package"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Total Jobs:</strong>  <span>{job.total_post || "No Total Jobs"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Eligibility:</strong>  <span>{job.eligibility || "No Eligibility"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Experience Required:</strong>  <span>{job.experienced_required || "No Experience Required"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Subscription Amount:</strong>  <span> <b>₹</b> {job.amount || "No Amount"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Payment Status:</strong>  <span>  {job.payment_status ? <FaCheck className='text-success fs-5 fw-bold ' title='success' /> : <RxCross2 className='text-danger fs-5 fw-bold' title='failed' />} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Address:</strong>  <span>{job.job_address || "No Address"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>City:</strong>  <span>{job.job_city || "No City"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>State:</strong>  <span>{job.job_state || "No State"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong>Days:</strong>  <span>{job.days || "No Days"} </span>
                                        </div>
                                        <div className="col-md-4" color="text-secondary">
                                            <strong >Status:</strong>  <span>{job.status || "No Status"} </span>
                                        </div>
                                        <div className="col-md-12 d-flex align-items-center" color="text-secondary">
                                            <strong>Description:</strong>  <span dangerouslySetInnerHTML={{ __html: job.job_description }} ></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default JobDetail;