import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { FaMapMarkedAlt, FaPen } from "react-icons/fa";
import EditAgentprofile from "./EditAgent";
import { Link } from "react-router-dom";
import Contextapi from "../../Contextpage/Contextapi";
import { MdBackHand } from "react-icons/md";

const ProfileAgent = () => {
  const [Loading, setLoading] = useState(true);
  const [Data, setData] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const getid = useMemo(() => localStorage.getItem("id") || "", []);
  const setname = useMemo(() => localStorage.getItem("name") || "", []);
  const id = getid && getid ? getid.replace(/"/g, "") : "";
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyID = contextdata.keyID;
  const params = useMemo(
    () => ({
      Client_ID: keyID,
    }),
    [keyID]
  )
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-agent/${id}`, {
          params,
        });
        // console.log("agentprofile", response)
        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [formOpen, domainname, params, id]);
  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }
  if (!Data) {
    return (
      <div className="container">
        <div className="row p-5">
          <div className="col-12 p-1">
            <LinearProgress />
          </div>
        </div>
      </div>
    );
  }
  // console.log("agent profile data ", Data);
  return (
    <div className=" container profile_of_student my-4 upshowing">
      <div className="row common_dash">
        <div className="col-lg-12" id="nav">
          <nav aria-label="breadcrumb">
            <h3>
              Welcome {setname ? setname : ''}
              <MdBackHand className="text-warning anim" /> !
            </h3>
            <ol className="breadcrumb my-3">
              <li className="breadcrumb-item ">
                <Link
                  to="/agent-dashboard/home"
                  title="Home page"
                  className="text-decoration-none ">
                  Agent
                </Link>
                {"  / "}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Profile
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {formOpen ? (
        <EditAgentprofile handleUpdate={handleUpdate} />
      ) : (
        <div className="agent-dashboard-profile">
          <div className="row align-items-center ">
            <div className="col-12 upbtn d-flex justify-content-end">
              <button onClick={handleUpdate} className="site-button">
                {" "}
                <FaPen className="py-0.5" /> Update profile
              </button>
            </div>
          </div>

          <div className="row justify-content-center shadow p-3">
            <div className="col-12 text-center d-flex justify-content-center">
              <div className="agent-profile-pic ">
                {/* <img src="https://img.freepik.com/free-photo/html-system-websites-collage-design_23-2150432963.jpg?t=st=1710146930~exp=1710150530~hmac=9d062679e34b54a60eb687d36e0541f2c33b4a54122283295d3cc91f50bbe3b3&w=740" title={Data.name} alt={Data.name} /> */}
                {/* <img src={Data.image} title={Data.name} alt={Data.name} /> */}
                <div className="image-name ">
                  <h3 className="text-capitalize">{Data.name}</h3>
                </div>
                <div className="location ms-3 mb-3">
                  <span className="icon mx-2">
                    <FaMapMarkedAlt />
                  </span>
                  <span className="text-capitalize">{Data.name}</span>
                </div>
              </div>

            </div>

            <div className="col-lg-4 col-md-6 ">
              <div className="card  align-items-center card-3">
                <div className="second ">

                  <div id="display" className="mb-2">
                    {" "}
                    <span id="email">Email : </span>
                    <span>{Data.email}</span>
                  </div>
                  <div id="display" className="mb-2">
                    {" "}
                    <span id="email">Alternate Email : </span>
                    <span>{Data.alternate_mobile || "dd/mm/yy"}</span>
                  </div>


                </div>
              </div>

            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card  align-items-center card-3">
                <div className="second ">
                  <div className="mb-2">
                    {" "}
                    <span id="id">Mobile : </span>{" "}
                    <span>{Data.mobile}</span>
                  </div>
                  <div className="mb-2">
                    {" "}
                    <span id="id">AlternateMobile : </span>{" "}
                    <span>{Data.alternate_mobile}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card  align-items-center card-3">
                <div className="second">
                  <div className="mb-2">
                    {" "}
                    <span id="rel">city : </span>{" "}
                    <span className="lowercase">{Data.city}</span>
                  </div>
                  <div className="mb-2">
                    {" "}
                    <span id="rel">State : </span>{" "}
                    <span className="lowercase">{Data.state ? Data.state : "******"}</span>
                  </div>
                  {/* <div className="mb-2">
                    {" "}
                    <span id="rel">Address : </span>{" "}
                    <span className="lowercase">
                      {Data.current_address || "indore"}
                    </span>
                  </div> */}

                </div>
              </div>
            </div>

          </div>

        </div>
      )}
    </div>
  );
};

export default ProfileAgent;
