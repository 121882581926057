import React from 'react'
import { Link } from 'react-router-dom'

const JoinUsToday = () => {
    return (
        <div className='join-us-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-12 '>
                        <div className='join-us-content'>
                            <h3 className="d-flex justify-content-center my-3">Join us today to develop your career</h3>
                            <p className='join-us-para'>
                                Welcome to our job portal! This is your go-to platform for connecting with top talent or finding the perfect job opportunity. We are urgently seeking candidates and employers to join our community. Whether you are looking to advance your career or in need of qualified employees, we provide the resources and support to help you succeed. Don't wait – take the next step in your career journey or find the ideal candidates for your company today.
                            </p>

                            <div className='join-buttons d-flex justify-content-center gap-5 '>
                                <Link to={'/registration'} title='Registration-candidate'> <button className='site-button'>I Am A Candidate </button></Link>
                                <Link to={'/registration'} title='Registration-employer'> <button className='site-button'> We Are An Employer</button> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinUsToday