import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';

const Companyregistartion = () => {
    const [viewPassword, setViewPassword] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };
    const navigate = useNavigate();
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const validateMobile = (mobile) => {
        const mobileRegex = /^\d{10}$/;
        return mobileRegex.test(mobile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { mobile, password } = formData;

        if (!validateMobile(mobile)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Phone Number',
                text: 'Please enter a valid 10-digit phone number.',
            });
            return;
        }

        if (!validatePassword(password)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Password',
                text: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
            });
            return;
        }

        try {
            const res = await axios.post('https://portal.rgshramikmitra.com/api/add-employer', formData);
            console.log('Employer', res.data);

            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                text: 'Company-Registration Successfully',
            });
            navigate('/login')
        } catch (error) {
            console.error('Error during registration:', error);
            Swal.fire({
                icon: 'error',
                title: 'Registration Failed',
                text: 'Something went wrong. Please try again later.',
            });
        }
    };
    return (

        <form className="row" onSubmit={handleSubmit} >
            <div className="col-lg-12">
                <div className="form-group mb-3">
                    <label className='frmlabel' htmlFor='name'> Full Name:</label>
                    <input
                        name="name"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Full Name"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group mb-3">
                    <label className='frmlabel' htmlFor='email'>Email:</label>
                    <input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group mb-3">
                    <label className='frmlabel' htmlFor='phone'>Mobile No:</label>
                    <input
                        name="mobile"
                        type="text"
                        className="form-control"
                        required
                        placeholder="Phone"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group mb-5 mainpass">
                    <label htmlFor="password">Password</label>
                    <input
                        name='password'
                        type={viewPassword ? 'text' : 'password'}
                        placeholder="Enter your password"
                        className="form-control passfiled"
                        onChange={handleChange}
                    />
                    <p className='password-eye' onClick={passwordViewHandler}> {viewPassword ? <FaEye /> : <FaRegEyeSlash />} </p>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group mb-3">
                    <div className=" form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="agree2"
                        />
                        <label className='frmlabel' htmlFor="agree2">
                            I agree to the{" "}
                            <Link to="/empregistration">Terms and conditions</Link>
                        </label>
                        <p>
                            Already registered?
                            <Link to={'/login'} className='loginhere'
                            >
                                Login here
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <button type="submit" className="site-button">
                    Sign Up
                </button>
            </div>
        </form>
    )
}

export default Companyregistartion;