import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RichText from "../Subpages/RichTexteditor";
import { LinearProgress } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import Contextapi from "../../../Contextpage/Contextapi";

const Privacypolicyupdate = ({ handleUpdate }) => {
  const [Loding, setLoding] = useState(true);

  const [formData, setFormData] = useState({
    title: "",
    policy: "",
    seo_title: "",
    seo_description: "",
    seo_keywords: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  // const navigate = useNavigate();
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          `${domainname}/api/get-privacy-policy`,
          { params }
        );
        // console.log(response);
        setFormData(response.data);
        setLoding(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [params, domainname]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handelsubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${domainname}/api/update-privacy-policy`,
        formData,
        {
          params: {
            Client_ID: keyid,
          },
        }
      );

      // console.log("res", response);

      if (response.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: response.data.message || "Data updated successfully!",
        });
       
        handleUpdate();
      } else {
        Swal.fire({
          icon: "warning",
          title: "Error",
          text: response.data.message || "There was an error in updating the data. Please try again.",
          position: "top-end",
          timer: 3000,
          showConfirmButton: true,
          toast: true,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Axios Error",
        text:  error.response.data.message || "An error occurred. Please try again.",
        position: "center",
        timer: 3000,
        showConfirmButton: true,
        toast: true,
      });
      console.log("axios error", error);
    }
  };
  if (Loding) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }
  return (
    <div className="container ">
      <div className="upbtn">
        <button onClick={handleUpdate}>
          {" "}
          <FaArrowLeft /> back{" "}
        </button>
      </div>
      <form
        className="shadow p-2 "
        onSubmit={handelsubmit}
        encType="multipart/form-data">
        <div className="row m-3">
          <div className="col-lg-12">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              onChange={handleInputChange}
              value={formData.title}
              className="form-control"
              placeholder="Enter title"
            />
          </div>

          <div className="col-lg-12">
            <label htmlFor="policy" className="form-label">
              Privacy-Policy
            </label>
            <RichText
              name="policy"
              placeholder={"privacy-policy"}
              value={formData.policy}
              onChange={handleRichTextChange}
            />
          </div>

          <div className="row mt-3 ">
            <h6 className="py-2">Seo-Parameters</h6>
            <div className="col-lg-6">
              <label htmlFor="seo_title" className="form-label">
                Seo-Title
              </label>
              <input
                type="text"
                name="seo_title"
                onChange={handleInputChange}
                value={formData.seo_title}
                className="form-control"
                placeholder="Enter Seo link"
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="seo_keywords" className="form-label">
                Seo-Keywords
              </label>
              <input
                type="text"
                name="seo_keywords"
                onChange={handleInputChange}
                value={formData.seo_keywords}
                className="form-control"
                placeholder="Enter Seo keywords"
              />
            </div>

            <div className="col-lg-12">
              <label htmlFor="seo_description" className="form-label">
                Seo-Description
              </label>
              <textarea
                type="text"
                rows={3}
                name="seo_description"
                onChange={handleInputChange}
                value={formData.seo_description}
                className="form-control"
                placeholder="Enter Seo Description"
              />
            </div>
          </div>
        </div>
        <div className="d-flex float-right">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
export default Privacypolicyupdate;
