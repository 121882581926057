import "./App.css";
import "./assets/css/Admin.css";
import "./assets/css/Dashboard.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout/layout";
import Home from "./Components/Pages/Homepage/Home";
import About from "./Components/Pages/about";
import Contact from "./Components/Pages/contact";
import Galleryhome from "./Components/Pages/Homepage/gallery";
import Content from "./Components/Category/content";
import TermsCondition from "./Components/Pages/termscondition";
import PrivacyPolicy from "./Components/Pages/privacypolicy";
import ScrollToTop from "./Components/Pages/ScrollToTop";

import Dashboard from "./Components/Admin-dashboard/pages/Dashboard";
import SiteConfig from "./Components/Admin-dashboard/pages/homepage/SiteConfig";
import Slider from "./Components/Admin-dashboard/pages/homepage/Slider";
import Homeseo from "./Components/Admin-dashboard/pages/homepage/HomeSeo";
import Privacypolice from "./Components/Admin-dashboard/pages/homepage/Privacypolice";
import TermsConditionadmin from "./Components/Admin-dashboard/pages/homepage/TermsCondition";
import Gallery from "./Components/Admin-dashboard/pages/homepage/Gallery";
import Abouts from "./Components/Admin-dashboard/pages/About/About";

import AllCompany from "./Components/Admin-dashboard/pages/Company/AllCompany";
import Companysignup from "./Components/Admin-dashboard/pages/Company/Companysignup";
import AllAdvisor from "./Components/Admin-dashboard/pages/Advisor/AllAdvisor";
import AdvisorSignup from "./Components/Admin-dashboard/pages/Advisor/Advisorsignup";
import Allemployees from "./Components/Admin-dashboard/pages/Employees/Allemployees";
import Employeesadd from "./Components/Admin-dashboard/pages/Employees/Employeesadd";
import JobCategory from "./Components/Admin-dashboard/pages/General-setting/JobCategory";
import JobcategeoryEdit from "./Components/Admin-dashboard/pages/General-setting/JobcategeoryEdit";
import ViewJobPost from "./Components/Admin-dashboard/pages/General-setting/Jobpost/ViewJobPost";
import Jobrequest from "./Components/Admin-dashboard/pages/General-setting/JobReq/Jobrequest";
import Contactadmin from "./Components/Admin-dashboard/pages/Contactadmin";

import AgentLayout from "./Components/Agent-dashboard/Layout";
import AgentHome from "./Components/Agent-dashboard/Agent-Page/AgentHome";
import ProfileAgent from "./Components/Agent-dashboard/Agent-Page/ProfileAgent";
import Viewemployee from "./Components/Agent-dashboard/Agent-Page/Employee/Agentemployee";
import CompanyLayout from "./Components/Company-dashboard/CompanyLayout";
import CompanyProfile from "./Components/Company-dashboard/Pages/CompanyProfile";
import EmpLayout from "./Components/Employee-dashboard/EmpLayout";
import LayoutAdmin from "./Components/Layout/LayoutAdmin";
import EmpProfile from "./Components/Employee-dashboard/Employee-page/EmpProfile";
import Viewjobemp from "./Components/Employee-dashboard/Employee-page/Viewjobemp";
import ApplyApplication from "./Components/Employee-dashboard/Employee-page/ApplyApplication";
import JobpostEdit from "./Components/Admin-dashboard/pages/General-setting/Jobpost/JobpostEdit";
import JobsviewCategory from "./Components/Pages/Jobs-Application/JobsviewCategory";
import ViewJobs from "./Components/Pages/Jobs-Application/ViewJobs";
import JobViewSingle from "./Components/Pages/Jobs-Application/JobViewsingle";  //singlejobs 

import ViewTestimonial from "./Components/Admin-dashboard/pages/Testimonial/ViewTestimonial";

// import CompanyHome from "./Components/Company-dashboard/Pages/CompanyHome";
import CompanyViewJobs from "./Components/Company-dashboard/Pages/CompanyViewJobs";

import CommpanyAddjob from "./Components/Company-dashboard/Pages/CompanyAddjob";
import Companytestiview from "./Components/Company-dashboard/Pages/Companytestiview";
import CompanyjobreqEdit from "./Components/Company-dashboard/Pages/CompanyjobEdit";
import Login from "./Components/Login-reg/Login";
import Registration from "./Components/Login-reg/registration";

import Paymentpage from "./Components/Login-reg/Paymentpage";
import FindJobsPage from "./Components/Pages/Homepage/Findjobs";
import Otp from "./Components/Login-reg/Otp";
import AdminLogin from "./Components/Login-reg/AdminLogin";
import Adminpassword from "./Components/Admin-dashboard/pages/Adminpassword";
import Companypassword from "./Components/Company-dashboard/Comman-file/Companypassword";
import Agentpassword from "./Components/Agent-dashboard/Comman-file/Agentpassword";
import Employeepassword from "./Components/Employee-dashboard/Comman-file/Employeepassword";
import JobrequestStatus from "./Components/Company-dashboard/Pages/JobRequestStaus";
import Profileofadviseruser from "./Components/Admin-dashboard/pages/Advisor/Profileofadviseruser";
import Profileofcompanyuser from "./Components/Admin-dashboard/pages/Company/Profileofcompanyuser";
import Profileofemployeeuser from "./Components/Admin-dashboard/pages/Employees/Profileofemployeeuser";
import AppliedJobs from "./Components/Admin-dashboard/pages/General-setting/JobReq/Appliedjobs";
import Newsletter from "./Components/Admin-dashboard/pages/Newsletter";
import PagenotFound from "./Components/Common_files/PagenotFound";
import Broucher from "./Components/Pages/Homepage/Findjobs";
import RefundPolicy from "./Components/Pages/RefundPolicy";
import Subscription from "./Components/Login-reg/Subscription/Subscription";
import CompanyPlan from "./Components/Company-dashboard/Pages/CompanyPlan";
import AdminViewSubscription from "./Components/Admin-dashboard/pages/Subscription/AdminViewSubscription";
import AdminPlanDetails from "./Components/Admin-dashboard/pages/Subscription/AdminPlanDetails";
import JobDetail from "./Components/Company-dashboard/Pages/JobDetail";
import ExpiredJobs from "./Components/Company-dashboard/Pages/ExpiredJobs";
import FailedJobs from "./Components/Company-dashboard/Pages/FailedJobs";
import AllJobs from "./Components/Admin-dashboard/pages/JobApplication/AllJobs";
import PandingJobs from "./Components/Admin-dashboard/pages/JobApplication/PandingJobs";
import RejectJobs from "./Components/Admin-dashboard/pages/JobApplication/RejectJobs";
import ApproveJob from "./Components/Admin-dashboard/pages/JobApplication/ApproveJob";
import ViewSingleJob from "./Components/Admin-dashboard/pages/General-setting/Jobpost/ViewSingleJob";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandle from "./Components/Common_files/ErrorHandle";
import UrlMessageHandler from "./Components/Common_files/UrlMessageHandler";
// import GoogleTranslate from "./Components/Common_files/GoogleTranslate";


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <UrlMessageHandler />
        {/* <GoogleTranslate /> */}
        <ErrorBoundary FallbackComponent={ErrorHandle}>
          <Routes>
            {/* Home Page......... start  */}
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/broucher" element={<Broucher />} />
              <Route path="/job-by-category/:slug" element={<JobsviewCategory />} />
              <Route path="/view-jobs" element={<ViewJobs />} />
              <Route path="/job/:slug" element={<JobViewSingle />} />

              <Route path="/find-jobs" element={<FindJobsPage />} />
              <Route path="/content" element={<Content />} />
              <Route path="/terms-condition" element={<TermsCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/payment-policy" element={<RefundPolicy />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/login" element={<Login />} />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/payment/success:data" element={<Paymentpage />} />

              <Route path="/gallery" element={<Galleryhome />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="*" element={<PagenotFound />} />
            </Route>
            {/* Home Page......... end  */}
            {/* ............................................................................................ */}

            {/* Admin Dashboard......... start  */}


            <Route path="/admin-dashboard/*" element={<LayoutAdmin />}>
              <Route index path="home" element={<Dashboard />} />

              <Route path="site-settings/site-config" element={<SiteConfig />} />
              <Route path="site-settings/home-page" element={<Homeseo />} />
              <Route path="site-settings/slider" element={<Slider />} />
              <Route path="site-settings/gallery" element={<Gallery />} />
              <Route path="site-settings/privacy-policy" element={<Privacypolice />} />
              <Route path="site-settings/terms-condition" element={<TermsConditionadmin />} />
              <Route path="site-settings/about-page" element={<Abouts />} />

              <Route path="company/add-company" element={<Companysignup />} />
              <Route path="company/all-company" element={<AllCompany />} />

              <Route path="agent/add-agent" element={<AdvisorSignup />} />
              <Route path="agent/all-agent" element={<AllAdvisor />} />
              <Route path="agent/all-agent/user-profile/advisor/:id" element={<Profileofadviseruser />} />

              <Route path="employees/add-employees" element={<Employeesadd />} />
              <Route path="employees/all-employees" element={<Allemployees />} />
              <Route path="employees/all-employees/user-profile/emp/:id" element={<Profileofemployeeuser />} />

              <Route path="general-settings/view-jobcategory" element={<JobCategory />} />
              <Route path="general-settings/jobcategory-edit/:slug" element={<JobcategeoryEdit />} />
              <Route path="general-settings/view-jobpost" element={<ViewJobPost />} />
              <Route path="general-settings/job" element={<ViewSingleJob />} />

              <Route path="general-settings/jobpost-edit/:slug" element={<JobpostEdit />} />
              <Route path="general-settings/view-jobrequest" element={<Jobrequest />} />
              <Route path="general-settings/view-applied-jobs" element={<AppliedJobs />} />
              <Route path="job-application/all-jobs-applications" element={<AllJobs />} />
              <Route path="job-application/pending-job" element={<PandingJobs />} />
              <Route path="job-application/apporved-job" element={<ApproveJob />} />
              <Route path="job-application/reject-job" element={<RejectJobs />} />
              <Route path="contact" element={<Contactadmin />} />
              <Route path="news-letter" element={<Newsletter />} />
              <Route path="admin-change-password" element={<Adminpassword />} />
              <Route path="testimonial" element={<ViewTestimonial />} />
              <Route path="subscription/view-subscription" element={<AdminViewSubscription />} />
              <Route path="subscription/view-Plan-details" element={<AdminPlanDetails />} />
              <Route path="company/all-company/user-profile/:company_id" element={<Profileofcompanyuser />} />
            </Route>


            {/* Admin Dashboard......... end  */}
            {/* ............................................................................................ */}

            {/* Agent Dashboard......... start  */}

            <Route path="/agent-dashboard/*" element={<AgentLayout />}>
              <Route index path="home" element={<AgentHome />} />
              <Route path="profile" element={<ProfileAgent />} />
              <Route path="employees" element={<Viewemployee />} />
              <Route path="agent-change-password/" element={<Agentpassword />} />
              <Route path="*" element={<> not found</>} />
            </Route>

            {/* Agent Dashboard......... end  */}
            {/* ............................................................................................ */}


            {/* Company Dashboard......... Start  */}

            <Route path="/company-dashboard/*" element={<CompanyLayout />}>
              {/* <Route index element={<CompanyHome />} /> */}
              <Route index path="home" element={<CompanyProfile />} />
              <Route path="add-jobs" element={<CommpanyAddjob />} />
              <Route path="job/" element={<JobDetail />} />
              <Route path="view-jobs" element={<CompanyViewJobs />} />
              <Route
                path="view-jobreqedit/:slug"
                element={<CompanyjobreqEdit />}
              />
              <Route
                path="view-testimonial"
                element={<Companytestiview />}
              />
              <Route
                path="subscription-plan"
                element={<CompanyPlan />}
              />
              <Route path="job-request" element={<JobrequestStatus />} />
              {/* <Route path="pending" element={<PendingJobs />} />
            <Route path="approved" element={<ApprovedJobs />} /> */}
              <Route path="expired-jobs" element={<ExpiredJobs />} />
              <Route path="failed-jobs" element={<FailedJobs />} />
              <Route path="company-change-password" element={<Companypassword />} />
              <Route path="*" element={<> not found</>} />
            </Route>

            {/* Company Dashboard......... End  */}
            {/* ............................................................................................ */}



            {/* Employee Dashboard......... Start  */}
            <Route path="/employee-dashboard/*" element={<EmpLayout />}>
              <Route index path="home" element={<EmpProfile />} />
              <Route
                path="view-jobs"
                element={<Viewjobemp />}
              />
              <Route
                path="view-jobapplication"
                element={<ApplyApplication />}
              />
              {/* <Route path="employees/" element={<Viewemployee />} /> */}
              <Route path="employee-change-password/" element={<Employeepassword />} />
              <Route path="*" element={<> not found</>} />
            </Route>


            {/* Employee Dashboard......... end  */}
            {/* ............................................................................................ */}

            <Route path="*" element={<PagenotFound />} />
          </Routes>
        </ErrorBoundary >
      </Router>
    </>
  );
}

export default App;



//code by rohit patel