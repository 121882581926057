import  { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const UrlMessageHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Capture the message parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');

        // If the message exists, display it using SweetAlert
        if (message) {
            Swal.fire({
                icon: 'success',
                title: 'Success | Your Password Reset',
                text: message,
                showConfirmButton: false,
                timer: 4500,
            });

            // Remove the query parameters from the URL after displaying the message
            navigate(window.location.pathname, { replace: true });
        }
    }, [navigate]);

    return null; 
};

export default UrlMessageHandler;
