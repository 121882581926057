import React, { useContext, useEffect, useState } from 'react'
import jobpic3 from '../../../assets/images/jobs-company/pic3.jpg';
import { Link } from 'react-router-dom';
import Contextapi from '../../Contextpage/Contextapi';
import { LinearProgress } from '@mui/material';
import { FaArrowRight } from 'react-icons/fa';
const JobListing = () => {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const contextData = useContext(Contextapi);

    useEffect(() => {
        const jobsData = contextData.APIdata.jobs;
        const todayDate = new Date();
        setJobs(jobsData.filter((item) => {
            const jobEndDate = new Date(item.job_end_date);
            return jobEndDate.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0);
        }));
        setLoading(false);
    }, [contextData]);
    const calculateDaysAgo = (dateString) => {
        if (!dateString) return 0;
        const currentDate = new Date();
        const jobDate = new Date(dateString);
        const differenceInTime = currentDate.getTime() - jobDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return Math.floor(differenceInDays);
    };



    if (loading) {
        return (
            <div className='container'>
                <div className='row p-5'>
                    <LinearProgress />
                </div>
            </div>
        );
    }
    return (
        <div className="section-full p-t120 p-b90 site-bg-light-purple twm-bg-ring-wrap">
            <div className="twm-bg-ring-right" />
            <div className="twm-bg-ring-left" />
            <div className="container">
                {/* TITLE START*/}
                <div className="section-head center wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                        <div>All Jobs Post</div>
                    </div>
                    <h2 className="wt-title">Find Your Career You Deserve it</h2>
                </div>
                {/* TITLE END*/}
                <div className="section-content">
                    <div className="twm-jobs-list-wrap">
                        <ul>
                            <li>
                                {jobs && jobs.slice(0, 4).map((job) => (
                                    <div className="twm-jobs-list-style1 mb-5" key={job.id}>
                                        <div className="twm-media">
                                            <img src={job.company_logo ? job.company_logo : jobpic3} alt="jobcmp" title={job.job_title} loading="lazy" />
                                        </div>
                                        <div className="twm-mid-content">
                                            <Link to={`/job/${job.slug}`} title={job.job_title} className="twm-job-title">
                                                <h4>
                                                    {job.job_title}{"  "}
                                                    <span className="twm-job-post-duration">
                                                        / Posted:  {' '}  {`${calculateDaysAgo(job.updated_at)} days ago`}
                                                    </span>
                                                </h4>
                                            </Link>
                                            <p className="twm-job-address">
                                                {job.job_address}
                                            </p>
                                            <a
                                                href="https://themeforest.net/user/thewebmax/portfolio"
                                                className="twm-job-websites site-text-primary"
                                                target='_blank'>
                                                {job.company_website}
                                            </a>
                                        </div>
                                        <div className="twm-right-content">
                                            <div className="twm-jobs-category  ">
                                                <span className="text-secondary">{job.job_mode}</span>
                                            </div>
                                            <div className="twm-jobs-amount">
                                                {job.package} <span>/ Month</span>
                                            </div>
                                            <Link
                                                to={`/job/${job.slug}`}
                                                title={job.job_title}
                                                className="twm-jobs-browse btn site-text-primary"
                                            >
                                                <p className="twm-jobs-category sky">
                                                    <span className="twm-bg-sky"> Browse Job <FaArrowRight className='' /> </span>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </li>


                        </ul>
                        <div className="text-center m-b30">
                            <Link to="/view-jobs" className=" site-button">
                                Browse All Jobs
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default JobListing