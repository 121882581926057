import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Contextapi from "../Contextpage/Contextapi";
import { LinearProgress } from "@mui/material";
const Slideshow = () => {
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);

  const owlOptions = {
    autoplay: true,
    rewind: true,
    transition: 400,
    margin: 20,
    dots: false,
    responsiveClass: true,
    autoplayTimeout: 6000,
    smartSpeed: 3000,
    nav: false,
    responsive: {
      0: { items: 1 },
      600: { items: 1 },
      1024: { items: 1 },
      1366: { items: 1 },
    },
  };

  const contextData = useContext(Contextapi);

  useEffect(() => {
    setSliderData(contextData.APIdata.slider || []);
    setLoading(false)
  }, [contextData]);


  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">
        {loading ? (

          <div className="row p-5 ">
            <LinearProgress />
          </div>

        ) : (
          <OwlCarousel className="owl-theme" {...owlOptions}>
            {sliderData && sliderData.map((slider, index) => (
              <div className="slide" key={`${slider.id}-${index}`}>
                <img
                  src={slider.slider_image}
                  className="rounded img-fluid"
                  alt={slider.alt_text}
                  title={slider.alt_text}
                  loading="lazy"
                />
              </div>
            ))}

          </OwlCarousel>
        )}
      </div>
    </section>
  );
};

export default Slideshow;