import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Contextapi from "../../Contextpage/Contextapi";
import StarRating from "../Star/RatingStar";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";
import mainLogo from "../../../assets/images/logo-png.png"

const owlOptions = {
  autoplay: true,
  rewind: true,
  transition: 400,
  margin: 20,
  dots: false,
  responsiveClass: true,
  autoplayTimeout: 6000,
  smartSpeed: 3000,
  nav: true,
  navText: ["<", ">"],
  responsive: {
    0: { items: 1 },
    600: { items: 1 },
    1024: { items: 2 },
    1366: { items: 2 },
  },
};

const Testimonial = () => {
  const [Loading, setLoading] = useState(true);
  const [testi, setTestimonials] = useState([]);
  const contextData = useContext(Contextapi);

  useEffect(() => {
    const fetchdata = async () => {
      if (contextData.APIdata?.testimonial) {
        setTestimonials(contextData.APIdata.testimonial);
        setLoading(false)
      }
    }
    fetchdata();
  }, [contextData]);
  if (Loading) {
    return (
      <>Loading</>
    )
  }

  return (
    <section id="testimonials" className="testimonials section-bg">
      {testi.status === false ? " " :
        <div className="section-title">
          <h3 className="text-center mt-4">Testimonials</h3>
        </div>
      }
      <div className="container">
        <div className="row">

          {testi.length === 0 ?
            ""
            : (
              <OwlCarousel className="owl-theme" {...owlOptions}>
                {testi && testi.length > 0 ? testi.map((data) => (
                  <div className="testimonial-wrap" key={data.id}>
                    <div className="testimonial-item">
                      <img
                        src={data.testimonial_image ? data.testimonial_image : mainLogo}
                        className="testimonial-img"
                        alt={data.name}
                        title={data.name}
                      />
                      <h3>{data.name}</h3>
                      <div className="rating_star">
                        <StarRating rating={data.rating} />
                      </div>
                      <div className="testi_description">
                        <FaQuoteLeft className="quote-icon-left" />
                        <p dangerouslySetInnerHTML={{ __html: data.description || "-----------" }} />
                        <FaQuoteRight className="quote-icon-right" />
                      </div>
                    </div>
                  </div>
                )) :
                  ""}
              </OwlCarousel>
            )}

        </div>
      </div>
    </section>
  );
};

export default Testimonial;
