import React, { useState } from 'react'
import contactform from '../../assets/images/contactform.jpg';
import axios from 'axios'
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
import Companyregistartion from './Companyegistartion';

function Registration() {
    const [viewPassword, setViewPassword] = useState(false);
    const [formData, setFormData] = useState(
        {
            name: '',
            email: '',
            mobile: '',
            password: '',
            profession: '',
        }
    )
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };
    const navigate = useNavigate();
    const validatePassword = (password) => {
        // Add your custom password validation logic here
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };
    const validateMobile = (mobile) => {
        const mobileRegex = /^\d{10}$/;
        return mobileRegex.test(mobile);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateMobile(formData.mobile)) {
            Swal.fire({
                icon: 'error',
                timer: 3000,
                title: 'Invalid Mobile Number',
                text: 'Mobile number must have exactly 10 digits',
            });
            return;
        }
        if (!validatePassword(formData.password)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Password',

                text: 'Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one digit, and one special character',
            });
            return;
        }
        try {
            const res = await axios.post('https://portal.rgshramikmitra.com/api/add-employee', formData);
            // console.log('Employee', res);

            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                text: 'Candidate-Registration Successfully',
            });
            navigate('/login')
        }
        catch (error) {
            Swal.fire({
                icon: "error",
                title: "try Again....",
                text: 'server error this not responding',
            });
            console.log(error, 'something is wrong')
        }
    };
    return (
        <>
            <div className="row signupformcontent">
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <img className='cimg' src={contactform} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className='registerWrapper'>
                        <div className="twm-tabs-style-2">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {/*Signup Candidate*/}
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        data-bs-target="#sign-candidate"
                                        type="button"
                                    >
                                        <i className="fas fa-user-tie" />
                                       Employee
                                    </button>
                                </li>
                                {/*Signup Employer*/}
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        data-bs-target="#sign-Employer"
                                        type="button"
                                    >
                                        <i className="fas fa-building" />
                                       company
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {/*Signup Candidate Content*/}
                                <div className="tab-pane fade show active" id="sign-candidate">
                                    <form className="row" onSubmit={handleSubmit}>
                                        <div className="col-lg-12 ">
                                            <div className="form-group mb-3">
                                                <label className='frmlabel' htmlFor='username'> Candidate Name</label>
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Candidate Name"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mb-3">
                                                <label className='frmlabel' htmlFor='email'>Email</label>
                                                <input
                                                    name="email"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mb-3">
                                                <label className='frmlabel' htmlFor='Profession'>Mobile No</label>
                                                <input
                                                    name="mobile"
                                                    type="number"
                                                    maxLength={10}
                                                    inputMode='numeric'
                                                    className="form-control"
                                                    placeholder="Mobile-number"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mb-3 mainpass">
                                                <label className='frmlabel' htmlFor="password">Password</label>
                                                <input
                                                    name='password'
                                                    type={viewPassword ? 'text' : 'password'}
                                                    placeholder="Enter your password"
                                                    className="form-control passfiled"
                                                    onChange={handleChange} required
                                                />
                                                <p className='password-eye' onClick={passwordViewHandler}> {viewPassword ? <FaEye /> : <FaRegEyeSlash />} </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mb-3">
                                                <label className="frmlabel" htmlFor="profession">
                                                    Profession
                                                </label>
                                                <input
                                                    name="profession"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Ex: carpeneter, painter "
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group mb-3">
                                                <div className=" form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="agree1" required
                                                    />
                                                    <label className='frmlabel' htmlFor="agree1">
                                                        I agree to the{" "}
                                                        <Link to="javascript:;">Terms and conditions</Link>
                                                    </label>
                                                    <p>
                                                        Already registered ?
                                                        <Link to={'/login'} className='loginhere'
                                                        >
                                                            Login here
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="site-button">
                                                Sign Up
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/*Signup Employer Content*/}
                                <div className="tab-pane fade" id="sign-Employer">
                                    <Companyregistartion />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default Registration;