import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import Contextapi from '../../Contextpage/Contextapi';
import { FaEye, FaSearch } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const FailedJobs = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);

    const jobsPerPage = 10;
    const pagesVisited = pageNumber * jobsPerPage;

    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const id = getid && getid ? getid.replace(/"/g, '') : '';
    const contextData = useContext(Contextapi);
    const domainName = contextData.domainapi;
    const keyID = contextData.keyID;
    const params = useMemo(() => ({ Client_ID: keyID }), [keyID]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${domainName}/api/get-jobrequest/${id}`, { params });
                const categoryResponse = await axios.get(`${domainName}/api/get-jobcategory`, { params });
                setCategory(categoryResponse.data);
                setData(response.data.filter(item => item.payment_status === 'failed' || item.payment_status == null));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [domainName, params, id]);

    const filteredData = data.filter(item =>
        item.job_title.toLowerCase().includes(input.toLowerCase()) ||
        category.some(categoryItem => categoryItem.id == item.job_category && categoryItem.title.toLowerCase().includes(input.toLowerCase()))
    );

    const displayJobs = filteredData.slice(pagesVisited, pagesVisited + jobsPerPage);
    const pageCount = Math.ceil(filteredData.length / jobsPerPage);

    // const changePage = ({ selected }) => {
    //     setPageNumber(selected);
    // };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid upshowing">
            <div className="row">
                <div className="col-lg-6 pt-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/home" className='text-decoration-none'>Home</Link></li> {'/'}
                            <li className="breadcrumb-item"><Link to="/company-dashboard" className='text-decoration-none'>Company Dashboard</Link></li>{'/'}
                            <li className="breadcrumb-item"><Link to="/company-dashboard/job-request" className='text-decoration-none'>Job Request</Link></li>{'/'}
                            <li className="breadcrumb-item active" aria-current="page">Failed-Jobs</li>
                        </ol>
                    </nav>
                </div>

                <div className="row">
                    <div className="">
                        <div className="col-lg-12 d-flex justify-content-end">
                            <div className="row g-3">
                                <div className=" col-lg-10 p-3 m-auto">
                                    <label htmlFor="search" className="visually-hidden">
                                        search
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search here"
                                            onChange={(e) => setInput(e.target.value)}
                                        />
                                        <FaSearch id="srh-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center mb-3">
                            <span className="woocommerce-result-count-left">
                                Showing {filteredData.length} Failed-jobs requests
                            </span>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <table className="table table-striped table-hover text-center">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Vacany</th>
                                    <th scope="col">Subscription Days</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            {displayJobs && displayJobs.length > 0 ? (
                                <tbody>
                                    {displayJobs.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{pagesVisited + index + 1}</td>
                                            <td>{item.transaction_id}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.job_title}</td>
                                            <td>{category.map(categoryItem =>
                                                categoryItem.id == item.job_category && categoryItem.title
                                            )}</td>
                                            <td>{item.total_post}</td>
                                            <td>{item.days || '---'}</td>
                                            <td>
                                                <button type='button' className={`btn btn-danger text-white  ms-1`}>
                                                    {item.payment_status || 'failed'}
                                                </button>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/company-dashboard/job/`}
                                                    state={{ job: item }}
                                                    title={item.job_title || "title"}
                                                    className='btn btn-info'>
                                                    <FaEye className='text-white' />
                                                </Link>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="12" className="no-data-message">No application available</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>

                    </div>
                    <div className='pagination pagination-lg justify-content-center mt-3'>
                        <ReactPaginate
                            nextLabel={<>Next </>}
                            pageCount={pageCount}
                            onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                            disabledClassName={'pagination__link--disabled'}
                            previousLabel="Previous"
                            containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            activeClassName='active  pb-3'
                            pageClassName='page-item ml-1 mr-1'
                            pageLinkClassName='page-link'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FailedJobs;