import React, { useEffect } from 'react'
import AgentSidebar from './Comman-file/AgentSidebar'
import AgentFooter from './Comman-file/AgentFooter'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AgentLayout = () => {
  const path = localStorage.getItem("path");
  const navigate = useNavigate();
 const Id = "agent-dashboard/home";
 
  useEffect(() => {
    if (path !== JSON.stringify(Id)) {
      Swal.fire({
        icon: "error",
        text: "Unauthorized! Please try again",
      })
        navigate('/login');
     
    } 
  }, [path, navigate]);

  // if (path !== JSON.stringify(Id)) {
  //   return null;
  // }
  return (
    <div>
        <AgentSidebar />
        <AgentFooter  />
    </div>
  )
}

export default AgentLayout