import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import { LinearProgress } from "@mui/material";

import Swal from "sweetalert2";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import Contextapi from "../../../Contextpage/Contextapi";

const Slider = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [data, setData] = useState("");
  const [newSliderData, setNewSliderData] = useState({
    slider_image: null,
    alt_text: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  const handleAdd = () => {
    setFormOpen(!formOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-slider`, {
          params,
        });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [formOpen, params, domainname]);

  const handleNewSliderChange = (e) => {
    const { name, value } = e.target;
    setNewSliderData({
      ...newSliderData,
      [name]: value,
    });
  };
  const handleChangeimage = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setNewSliderData((prevData) => ({
      ...prevData,
      slider_image: imageFile,
    }));
  };

  const handleAddSlider = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("slider_image", newSliderData.slider_image);
      formDataToSend.append("alt_text", newSliderData.alt_text);
      const response = await axios.post(
        `${domainname}/api/add-slider`,
        formDataToSend,
        {
          params: {
            Client_ID: keyid,
          },
        }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Slider image successfully!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "There was an error in updating the data. Please try again.",
        });
      }
      handleAdd();
      setNewSliderData({
        slider_image: "",
        alt_text: "",
      });
    } catch (error) {
      console.log(error);
      handleAdd();
    }
  };
  const handleDelete = async (id) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          className: "m-2",
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await axios.post(`${domainname}/api/delete-slider/${id}`, {
          Client_ID: keyid,
        });
        setData((prevData) => prevData.filter((curElm) =>curElm.id !== id  ))
        
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Slider image has been deleted.",
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your file is safe :)",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!data) {
    return (
      <div className="container">
        <div className="row p-5">
          <div className="col-12 p-1">
            <LinearProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="upshowing">
      <div className="row">
        <div className="col-lg-12" id="nav">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
              <li className="breadcrumb-item ">
                <Link
                  to="/admin-dashboard/home"
                  title="Home page"
                  className="text-decoration-none ">
                  Admin
                </Link>
              </li>
              <li className="ms-1" aria-current="page">
                {'/'} Slider-Image
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {formOpen ? (
        <div className="row  py-3 md:mt-2">
          <div className="col-lg-12">
            <div className="col-2 upbtn d-flex justify-content-end">
              <button onClick={handleAdd}> <FaArrowLeft className="ms-1" /> back</button>
            </div>
            <form
              onSubmit={handleAddSlider}
              encType="multipart/form-data"
              className="shadow p-5">
              <div className="mb-3 row">
                <div className="col-lg-6 slider-image-img">
                  <label htmlFor="logoupdate" className="form-label">
                    Slider image
                  </label>
                  <input
                    onChange={handleChangeimage}
                    name="slider_image"
                    className="form-file form-control"
                    accept=".jpg,.jpeg,.png,.svg+xml"
                    type="file"
                    required
                  />
                </div>

                <div className="col-lg-6 slider-image-alt">
                  <label htmlFor="alt_text" className="form-label">
                    Alt Text:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="alt_text"
                    value={newSliderData.alt_text}
                    onChange={handleNewSliderChange}
                    required
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Add Slider
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row align-items-center py-3">
            <div className="col-2 upbtn d-flex justify-content-end">
              <button onClick={handleAdd}>add</button>
            </div>
          </div>

          <div className="row slider_page">
            <div className="col-lg-12">
              <div className="card card_design p-3 mt-3">
                <h3>Slider image Home:</h3>
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Alt-Text</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.status === false ? (
                        <div className="error-msg">No slider image </div>
                      ) : (
                        data &&
                        data.map((value) => (
                          <tr className="table-row--align" key={value.id}>
                            <td>
                              <img
                                src={value.slider_image}
                                alt={value.alt_text}
                                title={value.alt_text}
                              />
                            </td>
                            <td>{value.alt_text}</td>
                            <td>
                              <button
                                className="slider-trash-btn"
                                onClick={() => handleDelete(value.id)}>
                                <FaTrash />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Slider;
