import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaArrowLeft } from "react-icons/fa";
import BasicInformation from "./Employee-subpage/Basicinformation";
import Addressform from "./Employee-subpage/Addressform";
import Bankform from "./Employee-subpage/Bankform";
import Contextapi from "../../Contextpage/Contextapi";

const EmpProfileupdate = ({ handleUpdate }) => {
    const Id = useMemo(() => localStorage.getItem("id") || "", []);
    const id = Id && Id ? Id.replace(/"/g, "") : "";
    const [activeStep, setActiveStep] = useState(1);

    const [formData, setFormData] = useState({
        employee_id: id || id,
        profile_image: null,
        name: "",
        email: "",
        mobile: "",


        resume: null,
        marksheet: null,
        highest_education: "",
        experience_years: "",
        experience_field: "",
        experience_description: "",
        dob: "",
        gender: "",

        address: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        identity: null,
        account_holder_name: null,
        bank_name: null,
        branch_name: null,
        ifsc_code: null,
        account_number: null,
        passbook: null,
    });

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyid = contextdata.keyID;

    const params = useMemo(
        () => ({
            Client_ID: keyid,
        }),
        [keyid]
    );

    // console.log(formData);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleSaveAndContinue = () => {
        const nextStep = activeStep + 1;
        handleStepChange(nextStep);
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        handleStepChange(prevStep);
    };

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get(
                    `${domainname}/api/get-employee/${id}`,
                    { params }
                );
                setFormData(response.data[0]);
                // console.log("main", response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchEmployeeData();
    }, [params, domainname, id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("formdd", formData);
        const Data = new FormData();
        Object.keys(formData).forEach((key) => {
            Data.append(key, formData[key]);
        });

        Data.append("identity", formData.identity);
        Data.append("profile_image", formData.profile_image);
        Data.append("resume", formData.resume);
        Data.append("marksheet", formData.marksheet);
        Data.append("passbook", formData.passbook);

        try {
            const response = await axios.post(`${domainname}/api/update-employee/${id}`,Data,
                {
                    params: {
                        Client_ID: keyid,
                    }
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            // console.log("profile details ", response)
            
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title:response.data.message || "Profile Update Successfully ",
                  });
                handleUpdate();
            } else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "warning",
                    title: response.data.message || "There was an error in updating the data. Please try again."
                 
                });
            }
        } catch (error) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: error.response.data.message || "There was an error in updating the data. Please try again."
             
            });
            console.log("axios", error);
        }
    };

    return (
        <div className="container emp-profile">
            <div className="upbtn">
                <button onClick={handleUpdate} className="">
                    {" "}
                    <FaArrowLeft /> back{" "}
                </button>
            </div>

            <div className="items-center row d-flex justify-content-center">
                <div className="col-md-10">
                    <div className="wizard">
                        <div className="pt-3 wizard-inner">
                            <div className="connecting-line" />
                            <ul className="nav nav-tabs" role="tablist">
                                <li className={activeStep === 1 ? "active" : "disabled"}>
                                    <a
                                        href="#step1"
                                        data-toggle="tab"
                                        role="tab"
                                        onClick={() => handleStepChange(1)}>
                                        <span
                                            className={`round-tab ${activeStep === 1 ? "active" : ""
                                                }`}>
                                            1
                                        </span>{" "}
                                        <i>Basic-Information</i>
                                    </a>
                                </li>
                                <li className={activeStep === 2 ? "active" : "disabled"}>
                                    <a
                                        href="#step2"
                                        data-toggle="tab"
                                        role="tab"
                                        onClick={() => handleStepChange(2)}>
                                        <span
                                            className={`round-tab ${activeStep === 2 ? "active" : ""
                                                }`}>
                                            2
                                        </span>{" "}
                                        <i> Address-Details</i>
                                    </a>
                                </li>
                                <li className={activeStep === 3 ? "active" : "disabled"}>
                                    <a
                                        href="#step3"
                                        data-toggle="tab"
                                        role="tab"
                                        onClick={() => handleStepChange(3)}>
                                        <span
                                            className={`round-tab ${activeStep === 3 ? "active" : ""
                                                }`}>
                                            3
                                        </span>{" "}
                                        <i>Bank-Details</i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <form
                            encType="multipart/form-data"
                            className="login-box"
                            onSubmit={handleSubmit}>
                            <div className="tab-content" id="main_form">
                                <div
                                    className={`tab-pane ${activeStep === 1 ? "active" : ""}`}
                                    role="tabpanel"
                                    id="step1">
                                    <BasicInformation
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                    <ul className="list-inline pull-right">
                                        <li>
                                            <button
                                                type="button"
                                                className="text-white default-btn next-step"
                                                onClick={handleSaveAndContinue}>
                                                Save & Continue
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className={`tab-pane ${activeStep === 2 ? "active" : ""}`}
                                    role="tabpanel"
                                    id="step2">
                                    <Addressform formData={formData} setFormData={setFormData} />
                                    <ul className="list-inline pull-right d-flex justify-content-end">
                                        <li>
                                            <button
                                                type="button"
                                                className="default-btn prev-step"
                                                onClick={handleBack}>
                                                Back
                                            </button>
                                            <button
                                                type="button"
                                                className="text-white default-btn next-step"
                                                onClick={handleSaveAndContinue}>
                                                Save & Continue
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className={`tab-pane ${activeStep === 3 ? "active" : ""}`}
                                    role="tabpanel"
                                    id="step3">
                                    <Bankform formData={formData} setFormData={setFormData} />
                                    <ul className="list-inline pull-right d-flex justify-content-end ">
                                        <li>
                                            <button
                                                type="button"
                                                className="default-btn prev-step"
                                                onClick={handleBack}>
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="text-white default-btn next-step">
                                                Submit
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmpProfileupdate;
