import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RichText from "../Subpages/RichTexteditor";
import { LinearProgress } from "@mui/material";
import Contextapi from "../../../Contextpage/Contextapi";

const AboutUpdate = ({ handleUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    about_image: null,
    seo_title: "",
    seo_description: "",
    seo_keywords: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(() => ({ Client_ID: keyid }), [keyid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-about`, { params });
        setFormData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch About Us data. Please try again later.",
        });
      }
    };
    fetchData();
  }, [domainname, params]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      about_image: imageFile,
    }));
  };

  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("seo_title", formData.seo_title);
    formDataToSend.append("seo_keywords", formData.seo_keywords);
    formDataToSend.append("seo_description", formData.seo_description);

    if (formData.about_image && typeof formData.about_image !== "string") {
      formDataToSend.append("about_image", formData.about_image);
    }

    try {
      const response = await axios.post(
        `${domainname}/api/update-about`,
        formDataToSend,
        { params: { Client_ID: keyid } }
      );

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          text: response.data?.message || "Successfully Updated About Us",
        });
        handleUpdate();
      } else {
        Toast.fire({
          icon: "warning",
          text: response.data?.message || "Something went wrong, please try again.",
        });
        handleUpdate();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Try Again",
        text: error.response?.data?.message || "Server error, please try again later.",
      });
      console.error("Axios error:", error);
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="upbtn">
        <button onClick={handleUpdate}>Back</button>
      </div>
      <form
        className="shadow p-2"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="row m-3">
          <div className="col-lg-6">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              name="title"
              onChange={handleInputChange}
              value={formData.title}
              className="form-control"
              placeholder="Enter title"
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="about_image" className="form-label">
              Image
            </label>
            <input
              type="file"
              name="about_image"
              onChange={handleImageChange}
              className="form-control"
              accept="image/*"
            />
          </div>

          <div className="col-lg-12">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <RichText
              name="description"
              placeholder="Description"
              value={formData.description}
              onChange={handleRichTextChange}
            />
          </div>

          <div className="row mt-3">
            <h6 className="py-2">SEO Parameters</h6>
            <div className="col-lg-6">
              <label htmlFor="seo_title" className="form-label">
                SEO Title
              </label>
              <input
                type="text"
                name="seo_title"
                onChange={handleInputChange}
                value={formData.seo_title}
                className="form-control"
                placeholder="Enter SEO Title"
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="seo_keywords" className="form-label">
                SEO Keywords
              </label>
              <input
                type="text"
                name="seo_keywords"
                onChange={handleInputChange}
                value={formData.seo_keywords}
                className="form-control"
                placeholder="Enter SEO Keywords"
              />
            </div>
            <div className="col-lg-12">
              <label htmlFor="seo_description" className="form-label">
                SEO Description
              </label>
              <textarea
                rows={3}
                name="seo_description"
                onChange={handleInputChange}
                value={formData.seo_description}
                className="form-control"
                placeholder="Enter SEO Description"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AboutUpdate;
