import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Contextapi from "../Contextpage/Contextapi";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import logo from '../../assets/images/logo-png.png';
import { IoIosArrowForward } from "react-icons/io";
import GoogleTranslate from "./GoogleTranslate";
// import '@fortawesome/fontawesome-free/css/all.min.css';


function Footer() {
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    subscribed_email: "",
  });

  const contextData = useContext(Contextapi);
  const domainname = contextData.domainapi;
  const keyid = contextData.keyID;
  const params = useMemo(() => ({ Client_ID: keyid }), [keyid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await contextData.APIdata.siteConfig;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching site config data:", error);
      }
    };
    fetchData();
  }, [contextData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${domainname}/api/newsletter`,
        formData,
        { params }
      );
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setFormData({
        subscribed_email: "",
      })


    } catch (error) {

      if (error.response.status === 422) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: error.response.data.result.subscribed_email[0],
        });
        setFormData({
          subscribed_email: "",
        })

      } else {
        setFormData({
          subscribed_email: "",
        })

        console.error("Error sending message:", error);
      }

    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const ScrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer-dark">
        <div className="container">
          {/* Newsletter Section Start */}
          <div className="ftr-nw-content">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-6">
                <div className="logo-footer clearfix">
                  <Link to="/">
                    <img
                      src={data.logo || logo}
                      alt={data.seo_title || "R&G SHRAMIK MITRA"}
                      title={data.seo_title || "R&G SHRAMIK MITRA"}
                    />
                  </Link>
                </div>
              </div>
              <div className=" col-lg-4 col-md-4 col-6">
                <div className="ftr-nw-title">
                  Join our email subscription now to get updates on new jobs and notifications.
                </div>
              </div>
              <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                  <div className="ftr-nw-form">
                    <input
                      name="subscribed_email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Enter Your Email"
                      type="email"
                      value={formData.subscribed_email}
                      required
                    />
                    <button className="ftr-nw-subscribe-btn">
                      Subscribe Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Newsletter Section End */}
          {/* Footer Blocks Start */}
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="widget widget_about">
                  <h3 className="widget-title">Address</h3>
                  <ul className="ftr-list">
                    <li className="">

                      <p className="adresssftr">{data.address || "indore , MP INDIA"}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_services ftr-list-center">
                      <h3 className="widget-title">Helpful Resources</h3>
                      <ul>
                        <li>
                          <Link
                            to={"/privacy-policy"}
                            title="Privacy-Policy"
                            aria-label="Privacy-Policy"
                          >
                            <span><IoIosArrowForward /></span> Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={"/terms-condition"}
                            title="Terms-Condition"
                            aria-label="Terms-Condition">
                            <span>
                              <IoIosArrowForward />
                            </span> Terms Condition</Link>
                        </li>
                        <li>
                          <Link
                            to={"/payment-policy"}
                            title="Refund-policy"
                            aria-label="Refund-policy"
                          ><span>
                              <IoIosArrowForward />
                            </span> Payment Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_services ftr-list-center">
                      <h3 className="widget-title">Quick Links</h3>
                      <ul>
                        <li>
                          <Link to="/" title="Home page ">  <span>
                            <IoIosArrowForward />
                          </span> Home</Link>
                        </li>
                        <li>
                          <Link to="/about-us">   <span>
                            <IoIosArrowForward />
                          </span> About us</Link>
                        </li>
                        <li>
                          <Link to="/gallery">   <span>
                            <IoIosArrowForward />
                          </span> Gallery</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">   <span>
                            <IoIosArrowForward />
                          </span> Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="widget widget_services ftr-list-center">
                      <h3 className="widget-title">Information</h3>
                      <ul>
                        <li>
                          <p>
                            Email: <span>{data.email || "rgshramikmitra@gmail.com"}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Alternate Email: <span> {data.alternate_email || "rgshramikmitra@gmail.com"}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Contact No: <span className="ms-2">
                              {data.contact_number || '6260838381'}
                            </span>

                          </p>
                        </li>
                        <li>
                          <p>
                            Alternate No:
                            <span className="ms-1">
                              {data.alternate_contact_number || '6260838381'}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Blocks End */}
          {/* Footer Copyright */}
          <div className="footer-bottom">
            <div className="footer-bottom-info">
              <div className="footer-copy-right">
               
                {/* <Link
                  to={"/privacy-policy"}
                  title="Privacy-Policy"
                  aria-label="Privacy-Policy"
                >
                  <span className="copyrights-text"> Privacy Policy | </span>
                </Link>
                <Link
                  to={"/terms-condition"}
                  title="Terms-Condition"
                  aria-label="Terms-Condition"
                >
                  <span className="copyrights-text"> Terms Condition | </span>
                </Link>
                <Link
                  to={"/payment-policy"}
                  title="Refund-policy"
                  aria-label="Refund-policy"
                >
                  <span className="copyrights-text"> Payment Policy</span>
                </Link> */}
              </div>
              <ul className="social-icons">
                {data.facebook && <li>
                  <a href={data.facebook || '#'} className="fab fa-facebook-f" target="_blank" rel="noopener noreferrer" />
                </li>
                }
                {data.twitter &&
                  <li>
                    <a href={data.twitter || '#'} className="fab fa-twitter" target="_blank" rel="noopener noreferrer" />
                  </li>
                }
                {data.instagram &&
                  <li>
                    <a href={data.instagram || '#'} className="fab fa-instagram" target="_blank" rel="noopener noreferrer" />
                  </li>
                }
                {data.youtube &&
                  <li>
                    <a href={data.youtube || '#'} className="fab fa-youtube" target="_blank" rel="noopener noreferrer" />
                  </li>
                }
                {data.whatsapp &&
                  <li>
                    <a href={`https://wa.me/${data.whatsapp || ''}`} className="fab fa-whatsapp" target="_blank" rel="noopener noreferrer" />
                  </li>
                }
                
              </ul>
            {/* <GoogleTranslate /> */}
              <span className="copyrights-text">
                  Copyright © 2024 by R&G SHRAMIK MITRA All Rights Reserved. {" "}
                </span>
            </div>
          </div>
          <div>
            <FaRegArrowAltCircleUp
              className="footer-arrow"
              onClick={ScrollToTop}
            />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;