import React, { useContext, useEffect, useState } from 'react'
import Contextapi from '../Contextpage/Contextapi';
import { LinearProgress } from "@mui/material";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const TermsConditions = () => {
    const [Termsdata, setTermsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const contextData = useContext(Contextapi);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await contextData.APIdata.termsCondition;
                setTermsData(responseData);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching site config data:", error);
            }
        };
        fetchData();
    }, [contextData]);
    // console.log("tersm", Termsdata)
    if (loading) {
        return (
            <div className="container">
                <div className="row p-5">
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className='terms_content'>
            <Helmet>
                <title>{Termsdata && Termsdata.seo_title ? Termsdata.seo_title : 'Terms and Conditions for Manpower Supply Services'}</title>
                <meta name="description" content={Termsdata.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
                <meta property="og:title" content={Termsdata.seo_title || "Terms and Conditions"} />
                <meta name="keywords" content={Termsdata.seo_keywords || "The company engaging our services is responsible for covering the traveling costs of hired labor"} />

                {Termsdata && Termsdata.author ? <meta name="author" content={Termsdata.author} /> : <meta name="author" content="Rohit Patel" />}
                {Termsdata && Termsdata.publisher ? <meta name="publisher" content={Termsdata.publisher} /> : <meta name="publisher" content="Gurjar Rohit patel " />}
                <link rel="canonical" href="https://www.rgshramikmitra.com/" />
            </Helmet>
            <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Terms & Condition</h2>
                            </div>
                        </div>
                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li>
                                    <Link title='home-page ' to="/">Home</Link>
                                </li>
                                <li>terms & condition </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* <h1>Privacy Policy</h1> */}

                {Termsdata ?
                    <div className='row'>
                        <div className='col-12 '>
                            <div dangerouslySetInnerHTML={{ __html: Termsdata.terms }} />
                        </div>
                    </div> :
                    ""
                    // <div className='row ' >
                    //     <div className='col-12 py-4 text-justify'>

                    //         <h1>Terms and Conditions for Manpower Supply Services:</h1>

                    //         <p><span>Payment Schedule :</span>  Salary should be disbursed to employees between the 1st and 5th of every month.</p>

                    //         <p><span>Travel Expenses :</span> The company engaging our services is responsible for covering the traveling costs of hired labor.</p>

                    //         <p> <span>Security Measures :</span> For contracts involving more than 20 laborers, a security cheque will be required.</p>

                    //         <p><span>Employee Rights :</span> Timely payments, holidays, and bonuses, when applicable, must be provided to laborers. In the event of overtime work, the company must compensate laborers accordingly. Failure to do so may result in work stoppage, for which the company will not be held responsible.</p>

                    //         <p><span>Confidentiality Agreement :</span> Both parties agree to maintain the confidentiality of all work-related information, preventing its disclosure by any party.</p>

                    //         <p><span >Indemnity : </span>The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment.</p>

                    //         <p><span>Dispute Resolution :</span> Any disputes arising from this contract will be resolved through arbitration, with both parties agreeing to abide by the decision of the arbitrator.</p>

                    //         <p> <span>Modification Clause :</span> Any modifications or amendments to this contract must be made in writing and agreed upon by both parties.</p>

                    //         <p><i> By engaging our services, the client acknowledges and agrees to abide by these terms and conditions.</i></p>
                    //     </div>
                    // </div>
                }
            </div>
        </div>
    )
}

export default TermsConditions