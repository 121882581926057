import React from 'react';
import { FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AgentHeader = () => {
    const navigate = useNavigate();
    const logoutfun = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('path');
        localStorage.removeItem('role');
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "Log-out successfully"
          });
        navigate('/')
    };
    return (
        <div className='topbar'>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-lg-8 '>
                        <div className=" ">
                            <h1 className='H1'> </h1>
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-end">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="user-dropdown">
                                <FaUser />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item ><Link to={'/agent-dashboard/profile'}>Profile</Link></Dropdown.Item>
                                <Dropdown.Item ><Link to={'/agent-dashboard/agent-change-password/'}>Change Password</Link></Dropdown.Item>
                                <Dropdown.Item onClick={() => logoutfun()}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>


                </div>
            </div>
        </div>

    );
}

export default AgentHeader;