import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RichText from "../../Subpages/RichTexteditor";
import Contextapi from "../../../../Contextpage/Contextapi";
import { FaArrowLeft } from "react-icons/fa";

const AddJobpost = ({ handleUpdate }) => {
  const [category, setCategory] = useState([]);
  const [Company, setCompany] = useState([]);
  const [formData, setFormData] = useState({
    company_id: "",
    job_title: "",
    job_category: "",
    job_mode: "",
    job_type: "",
    total_post: "",
    job_description: "",
    package: "",
    eligibility: "",
    experienced_required: "",
    job_end_date: "",
    job_address: "",
    job_city: "",
    job_state: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-jobcategory`, {
          params,
        });
        const recmp = await axios.get(`${domainname}/api/get-all-company`, {
          params,
        });

        // console.log(recmp);

        setCompany(recmp.data);
        setCategory(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [params, domainname]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${domainname}/api/add-jobpost`, formData, {
        params: {
          Client_ID: keyid,
        },
      });

      // console.log("job post", res);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        text:  res.data?.message || " Add New Job ",
      });
      handleUpdate();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "try Again ",
        text: error.response?.data?.message || "server error this not responding",
      });

      console.log(error, "something is wrong");
    }
  };
  return (
    <div className="container comapnay_form">
      <div className="upbtn">
        <button onClick={handleUpdate} > <FaArrowLeft className="ms-1" /> back </button>
      </div>
      <div className="row mt-2">
        {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                    <img className='cimg img-fluid' src={contactform} />
                </div> */}
        <div className="col-lg-12 mt-5">
          <div className="registerWrapper">
            <div className="twm-tabs-style-2">
              {/* {/Signup Candidate Content/} */}
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-6">
                  <label htmlFor="package" className="frmlabel">
                    {" "}
                    Job Title
                  </label>
                  <input
                    type="text"
                    name="job_title"
                    placeholder={"Title"}
                    value={formData.job_title}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_category">
                      {" "}
                      Job Company
                    </label>
                    <select
                      name="company_id"
                      className="form-control form-select"
                      value={formData.company_id}
                      onChange={handleChange}>
                      <option value="">Select job Company</option>
                      {Company &&
                        Company.map((category) => (
                          <option key={category.id} value={category.company_id}>
                            {category.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_category">
                      {" "}
                      Job category
                    </label>
                    <select
                      name="job_category"
                      className="form-control form-select"
                      value={formData.job_category}
                      onChange={handleChange}>
                      <option value="">Select job category</option>
                      {category &&
                        category.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_mode">
                      {" "}
                      Job Mode
                    </label>
                    <select
                      name="job_mode"
                      className="form-control form-select"
                      value={formData.job_mode}
                      onChange={handleChange}>
                      <option value="">Select job mode</option>
                      <option value="Work from home">Work from home</option>
                      <option value="Work from office">Work from office</option>
                      <option value="Hybird">Hybird</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="job_type">
                      {" "}
                      Job Type
                    </label>
                    <select
                      name="job_type"
                      className="form-control form-select"
                      value={formData.job_type}
                      onChange={handleChange}>
                      <option value="">Select job type</option>

                      <option value="Full time">Full time</option>
                      <option value="Part time">Part time</option>
                      <option value="Internship">Internship</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="package" className="frmlabel">
                      {" "}
                      Experince
                    </label>
                    <input
                      type="text"
                      name="experienced_required"
                      placeholder={"Experience"}
                      value={formData.experienced_required}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="package" className="frmlabel">
                      {" "}
                      package
                    </label>
                    <input
                      type="number"
                      name="package"
                      placeholder={"Package"}
                      value={formData.package}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="Package" className="frmlabel">
                      Eligibility{" "}
                    </label>
                    <input
                      type="text"
                      name="eligibility"
                      placeholder={"Eligibility"}
                      value={formData.eligibility}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="job_post" className="frmlabel">
                      {" "}
                      Total post
                    </label>
                    <input
                      type="number"
                      name="total_post"
                      placeholder={"Number of vacancy"}
                      value={formData.total_post}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="job_end_date" className="frmlabel">
                      {" "}
                      End-date
                    </label>
                    <input
                      type="date"
                      name="job_end_date"
                      placeholder={"dd//MM/yyyy"}
                      value={formData.job_end_date}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label htmlFor="job_address" className="frmlabel">
                      {" "}
                      Address
                    </label>
                    <textarea
                      rows={3}
                      name="job_address"
                      placeholder={"Address"}
                      value={formData.job_address}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="job_address" className="frmlabel">
                      {" "}
                      City
                    </label>
                    <input
                      type="text"
                      name="job_city"
                      placeholder={"City"}
                      value={formData.job_city}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="job_address" className="frmlabel">
                      State
                    </label>
                    <input
                      type="text"
                      name="job_state"
                      placeholder={"State"}
                      value={formData.job_state}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="terms" className="frmlabel">
                    Description
                  </label>
                  <RichText
                    name="job_description"
                    placeholder={"Description"}
                    value={formData.job_description}
                    onChange={handleRichTextChange}
                  />
                </div>
                <div className="col-md-12 justify-content-end ">
                  <button type="submit" className="site-button">
                    Add Job
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddJobpost;
