import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import RichText from "../Subpages/RichTexteditor";
import { Link, useNavigate, useParams } from "react-router-dom";
import Contextapi from "../../../Contextpage/Contextapi";
import { FaArrowLeft } from "react-icons/fa";

const JobcategeoryEdit = () => {
  
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    jobcategory_image: null,
    seo_title: "",
    seo_description: "",
    seo_keywords: "",
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  const { slug } = useParams();
  const navigate = useNavigate();
  const id = slug;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${domainname}/api/get-single-jobcategory/${id}`,
          { params }
        );
        const responseData = response.data[0];

        // console.log("resp", responseData);

        
        setFormData({
          title: responseData.title || "",
          description: responseData.description || "",
          jobcategory_image: responseData.jobcategory_image || null,
          seo_title: responseData.seo_title || null,
          seo_keywords: responseData.seo_keywords || '',
          seo_description: responseData.seo_description || '',
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImage = (e) => {
    const imageFile = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      jobcategory_image: imageFile,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("seo_title", formData.seo_title);
    formDataToSend.append("seo_keywords", formData.seo_keywords);
    formDataToSend.append("seo_description", formData.seo_description);
    if (
      formData.jobcategory_image &&
      typeof formData.jobcategory_image !== "string"
    ) {
      formDataToSend.append("jobcategory_image", formData.jobcategory_image);
    }

    try {
      const res = await axios.post(
        `${domainname}/api/update-jobcategory/${id}`,
        formDataToSend,
        {
          params: {
            Client_ID: keyid,
          },
        }
      );

      // console.log("Updated Job Category:", res);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
       
        text:res.data.message || "Updated Job Category",
      });

      navigate("/admin-dashboard/general-settings/view-jobcategory");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Try Again",
        text: error.response.data.message || "Server error, not responding",
      });
      console.error("Error updating job category:", error);
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container upshowing comapnay_form">
       <div className="upbtn">
        <Link to={'/admin-dashboard/general-settings/view-jobcategory/'} title="back"> <button title="no update site-button"> <FaArrowLeft className="ms-1" /> back </button></Link>
      </div>
      <div className="row mt-2">
        <div className="col-lg-12 mt-5">
          <div className="registerWrapper">
            <div className="twm-tabs-style-2">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-6 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="title">
                      {" "}
                      Title
                    </label>
                    <input
                      name="title"
                      type="text"
                      className="form-control"
                      value={formData.title}
                      placeholder="Jobcategory title"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="logo">
                      Logo
                    </label>
                    <input
                      name="jobcategory_image"
                      type="file"
                      className="form-control file-control"
                      onChange={handleImage}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <label htmlFor="terms" className="form-label">
                    Description
                  </label>
                  <RichText
                    name="description"
                    placeholder={"Description"}
                    value={formData.description}
                    onChange={handleRichTextChange}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <label htmlFor="seo_title" className="form-label">
                    Seo-Title
                  </label>
                  <input
                    type="text"
                    name="seo_title"
                    onChange={handleChange}
                    value={formData.seo_title}
                    className="form-control"
                    placeholder="Enter Seo title"
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <label htmlFor="seo_keywords" className="form-label">
                    Seo-Keywords
                  </label>
                  <input
                    type="text"
                    name="seo_keywords"
                    onChange={handleChange}
                    value={formData.seo_keywords}
                    className="form-control"
                    placeholder="Enter Seo keywords"
                  />
                </div>
                <div className="col-lg-12">
                  <label htmlFor="seo_description" className="form-label">
                    Seo-Description
                  </label>
                  <textarea
                    type="text"
                    rows={3}
                    name="seo_description"
                    onChange={handleChange}
                    value={formData.seo_description}
                    className="form-control"
                    placeholder="Enter Seo Description"
                  />
                </div>
                <div className="col-md-12 ">
                  <button type="submit" className="site-button">
                    Update Job Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobcategeoryEdit;
