import React from 'react'
import { BsChat } from "react-icons/bs";
import { IoDiamondOutline, IoRocketOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { GiRotaryPhone } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";

const WhyChooseUs = () => {
    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-12'>
                        <div className='why-choose-us mt-3'>
                            <h3 className='text-center fs-2 fw-semi-bold my-2 text-secondary'>Why Choose Us</h3>
                            <p className='text-center'> Who are in extremely love with eco friendly system.</p>

                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><CiUser className='fs-4' /></span> Expert Technicians</h4>
                                        <div className='card-content'>
                                            <p className='text-left'>Our team consists of highly skilled technicians who have years of experience in the field. They are equipped with the latest tools and techniques to provide the best service possible.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><TbCertificate className='fs-4' /></span> Professional Service</h4>
                                        <div className='card-content'>
                                            <p className='text-left'> We pride ourselves on delivering professional services that meet the highest standards of quality. Our team is dedicated to ensuring your satisfaction. </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><IoDiamondOutline className='fs-4' /></span>Highly Recomended</h4>
                                        <div className='card-content'>
                                            <p className='text-left'> We come highly recommended by our clients, thanks to our commitment to excellence and our ability to deliver on our promises.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><GiRotaryPhone className='fs-4' /></span>Great Support</h4>
                                        <div className='card-content'>
                                            <p className='text-left'>
                                                We offer 24/7 customer support to ensure that all your queries and issues are resolved promptly. Our support team is always ready to assist you. </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>

                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><IoRocketOutline className='fs-4' /></span>Technical Skills</h4>
                                        <div className='card-content'>
                                            <p className='text-left'> Our team has exceptional technical skills, ensuring that we can handle any challenge. From complex installations to intricate repairs, we have got you covered.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>

                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><BsChat className='fs-4' /></span>Positive Reviews</h4>
                                        <div className='card-content'>
                                            <p className='text-left'>  Our services have received numerous positive reviews from satisfied customers who appreciate our dedication and hard work.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhyChooseUs
