import React, { useContext, useEffect, useMemo, useState } from 'react';
import 'react-data-table-component-extensions/dist/index.css';
import Swal from 'sweetalert2';
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import Contextapi from '../../../Contextpage/Contextapi';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AllJobs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");
  // const getid = useMemo(() => localStorage.getItem('id') || '', []);
  // const id = getid && getid ? getid.replace(/"/g, '') : '';
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyID = contextdata.keyID;
  const params = useMemo(() => ({
    Client_ID: keyID,
  }), [keyID]);

  const handleStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'btn-warning';
      case 'approved':
        return 'btn-primary';
      case 'rejected':
        return 'btn-danger';
      default:
        return '';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-jobapplications`, { params });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [domainname, params, contextdata.APIdata.jobs]);

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.post(
        `${domainname}/api/update-jobapplication-status/${id}`,
        { status: newStatus },
        { params: { Client_ID: keyID } }
      );
      setData(data.map((item) => (item.job_application_id === id ? { ...item, status: newStatus } : item)));
      Swal.fire({
        icon: 'success',
        title: 'Status updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: error.response.data.message || 'Something went wrong! Please try again.',
      });
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllJobs");
    XLSX.writeFile(wb, "AllJobs.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("All Jobs Report", 20, 10);
    doc.autoTable({
      head: [['#', 'Job Title', 'All Vacancy', 'Expired date', 'Company Name', 'Company Mobile', 'Employee Name', 'Employee Mobile', 'Status']],
      body: data.map((item, index) => [
        index + 1,
        item.job_title || '--',
        item.total_post || '--',
        format(new Date(item.job_end_date), "d/MMMM/y") || '--',
        item.company_name || '--',
        item.company_mobile || '--',
        item.employee_name || '--',
        item.employee_mobile || '--',
        item.status || '--'
      ]),
    });
    doc.save('AllJobs.pdf');
  };

  if (loading) {
    return (
      <div className='container'>
        <div className='row p-3 py-10'>
          <LinearProgress />
        </div>
      </div>
    );
  }

  const columns = [
    // {
    //   name: '#',
    //   selector: (row, index) => index + 1,
    //   sortable: true,
    // },
    {
      name: 'Job Title',
      selector: row => row.job_title || '--',
      sortable: true,
    },
    {
      name: 'All Vacancy',
      selector: row => row.total_post || '--',
      sortable: true,
    },
    {
      name: 'Expired date',
      selector: row => format(new Date(row.job_end_date), "d/MMMM/y") || '--',
      sortable: true,
    },
    {
      name: 'Company Name',
      selector: row => row.company_name || '--',
      sortable: true,
    },
    {
      name: 'Company Mobile',
      selector: row => row.company_mobile || '--',
      sortable: true,
    },
    {
      name: 'Employee Name',
      selector: row => row.employee_name || '--',
      sortable: true,
    },
    {
      name: 'Employee Mobile',
      selector: row => row.employee_mobile || '--',
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (
        <select
          className={`form-select text-white ${handleStatusColor(row.status)}`}
          value={row.status}
          onChange={(e) => handleStatusChange(row.job_application_id, e.target.value)}
        >
          <option value='pending'>Pending</option>
          <option value='approved'>Approved</option>
          <option value='rejected'>Rejected</option>
        </select>
      ),
      sortable: true,
    }
  ];

  const filteredData = data.filter((item) =>
    input.toLowerCase() === '' ? true : item.job_city.toLowerCase().includes(input.toLowerCase())
  );

  const customStyles = {
    headCells: {
      style: {
        
        fontWeight: 'bold',
        color: 'gray', 
        backgroundColor: '#f1f1f1', 
      },
    },
  };

  // const tableData = {
  //   columns,
  //   data: filteredData,
  //   exportHeaders: true,
  // };

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
              <li className="breadcrumb-item"><Link to="/admin-dashboard/home" className='text-decoration-none ms-1'>Admin</Link></li>
              <li className="breadcrumb-item" aria-current="page"> {' / '} All-Applied-Jobs</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-end">
            <div className="row g-3 p-3">
              <div className="col-lg-10 p-3 m-auto">
                <label htmlFor="search" className="visually-hidden">Search</label>
                <div className="input-group">
                  <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive'>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success me-2" onClick={exportToExcel}>Export to Excel</button>
              <button className="btn btn-danger" onClick={exportToPDF}>Export to PDF</button>
            </div>
            {/* <DataTableExtensions {...tableData}> */}
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                paginationPerPage={7}
                customStyles={customStyles}
              />
            {/* </DataTableExtensions> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllJobs;