import React, { useContext } from 'react'
import HeroSection from './Herosection'
import JobListing from './JobListing'
import Testimonial from './Testimonial'
import Jobcategory from './Jobcategory'
import WhyChooseUs from './WhyChooseUs'
import JoinUsToday from './JoinUsToday'
import ApplyProcess from './ApplyProcess'
import { Helmet } from 'react-helmet'
import Contextapi from '../../Contextpage/Contextapi'

const Home = () => {
  const contextData = useContext(Contextapi);
  const Sitedata = contextData.APIdata.siteConfig;
  return (
    <div>
      <Helmet>
        <title>{Sitedata && Sitedata.seo_title ? Sitedata.seo_title : 'R&G SHRAMIK MITRA - Find Latest Jobs, Employment Opportunities | Job Portal'}</title>
        <meta name="description" content={Sitedata.seo_description || "The company holds no liability for any damages, losses, or injuries incurred by the client due to the actions or negligence of hired laborers during the course of their employment"} />
        <meta name="keywords" content={Sitedata.seo_keywords || "job portal, latest jobs, employment opportunities, job vacancies, job search, job seekers, career opportunities, city jobs ,hired labor"} />
        <meta name="author" content=" Frontend: Rohit Patel & backend: Udit Chouhan  " />
        {/* <meta name="publisher" content="Rohit Patel" /> */}
      </Helmet>
      <HeroSection />
      <Jobcategory />
      <JobListing />
      {/* <AppAds/> */}
      <WhyChooseUs />
      <JoinUsToday />
      <ApplyProcess />
      <Testimonial />
    </div>
  )
}

export default Home