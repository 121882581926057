import React from 'react';

const Bankform = ({ formData, setFormData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            passbook: file,
            identity: file,
        });
    };

    return (
        <div className=' row'>
            <div className='col-lg-4'>
                <div className=" form-group mb-3">
                    <label htmlFor="identity">Identity</label>
                    <input
                        type="file"
                        className="form-control"
                        name="identity"
                        onChange={handleFileChange}
                    />
                </div>
            </div>
            <div className='col-lg-4'>
                <div className=" form-group mb-3">
                    <label htmlFor="account_holder_name">Account Holder Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="account_holder_name"
                        name="account_holder_name"
                        placeholder="Enter account holder name"
                        value={formData.account_holder_name || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className='col-lg-4'>

                <div className=" form-group mb-3">
                    <label htmlFor="bank_name">Bank Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="bank_name"
                        name="bank_name"
                        placeholder="Enter bank name"
                        value={formData.bank_name || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className='col-lg-4'>

                <div className=" form-group mb-3">
                    <label htmlFor="branch_name">Branch Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="branch_name"
                        name="branch_name"
                        placeholder="Enter branch name"
                        value={formData.branch_name || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className='col-lg-4'>

                <div className=" form-group mb-3">
                    <label htmlFor="ifsc_code">IFSC Code</label>
                    <input
                        type="text"
                        className="form-control"
                        id="ifsc_code"
                        name="ifsc_code"
                        placeholder="Enter IFSC code"
                        value={formData.ifsc_code || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className='col-lg-4'>


                <div className=" form-group mb-3">
                    <label htmlFor="account_number">Account Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="account_number"
                        name="account_number"
                        placeholder="Enter account number"
                        value={formData.account_number || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className='col-lg-4'>

                <div className=" form-group mb-3">
                    <label htmlFor="passbook">Passbook</label>
                    <input
                        type="file"
                        className="form-control"
                        id="passbook"
                        name="passbook"
                        onChange={handleFileChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Bankform;
