import React, { useContext, useEffect, useMemo, useState } from "react";
import Reactpaginate from "react-paginate";
// import Swal from "sweetalert2";
import { FaRegEye, FaSearch } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";

import { Link } from "react-router-dom";
import Contextapi from "../../Contextpage/Contextapi";

const Viewjobemp = () => {
  const [data, SetData] = useState("");
  const [Loading, setLoading] = useState(true);
  const [input, Setinput] = useState("");
  const [category, setCategory] = useState([]);
  const [Company, setCompany] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 7;
  const pageCount = Math.ceil(data.length / userPerPage);
  const offset = pageNumber * userPerPage;

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-jobpost`, {
          params,
        });
        const rescategory = await axios.get(
          `${domainname}/api/get-jobcategory`,
          { params }
        );
        setCategory(rescategory.data);
        const rescompany = await axios.get(
          `${domainname}/api/get-all-company`,
          { params }
        );
        const jobsData = response.data;

        const todayDate = new Date();
        SetData(jobsData.filter((item) => {
            const jobEndDate = new Date(item.job_end_date);
            return jobEndDate.setHours(0, 0, 0, 0) > todayDate.setHours(0, 0, 0, 0);
        }));
        setCompany(rescompany.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [params, domainname]);

  if (Loading) {
    <div className="container">
      <div className="row p-3 py-10">
        <LinearProgress />
      </div>
    </div>;
  }
  if (!data) {
    <div className="container">
      <div className="row p-5 mt-10">
        <LinearProgress />
      </div>
    </div>;
  }

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label=" breadcrumb ">
            <ol className="breadcrumb  p-3">
              <li className="breadcrumb-item ">
                <Link to="/employee-dashboard/home" className="text-decoration-none ">
                  Profile {' /'}
                </Link>
              </li>
              <li className="ms-1 breadcrumb-item active" aria-current="page">
              Jobs-Available
              </li>
            </ol>
          </nav>
        </div>

        <div className="row">
          <div className="">
            <div className="col-lg-12 d-flex justify-content-end">
              <div className="row align-items-center "></div>
              <div className="row g-3 p-3">
                <div className=" col-lg-10 p-3 m-auto">
                  <label htmlFor="search" className="visually-hidden">
                    search
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Search here"
                      onChange={(e) => Setinput(e.target.value)}
                    />
                    <FaSearch id="srh-icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="table-responsive">
        <table className="table table-striped table-hover text-center ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Company</th>
                <th scope="col">Category</th>
                <th scope="col">Mode</th>
                <th scope="col">Type</th>
                <th scope="col">Package</th>
                <th scope="col">Total jobs</th>
                <th scope="col">End-date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
                {data && data
                    .filter((item) =>
                      input.toLowerCase() === ""
                        ? true
                        : item.job_city
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    )
                    .slice(offset, offset + userPerPage)
                    .map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        {Company && Company.map((items) =>
                            items.company_id === item.company_id ? (
                              <span key={items.id}>
                                <span>{items.name ? items.name : "-"}</span>
                              </span>
                            ) : ""
                          )}
                      </td>
                      <td>
                        {category && category.map((items) =>
                            items.id == item.job_category ? (
                              <span key={items.id}>
                                <span> {items.title ? items.title : "-"}</span>
                              </span>
                            ) : ""
                          )}
                      </td>
                      <td>{item.job_mode}</td>
                      <td>{item.job_type}</td>
                      <td>{item.package}</td>
                      <td>{item.total_post}</td>
                      <td>{new Date(item.job_end_date).toLocaleDateString("en-GB")}</td>
                      <td className="">
                        <Link
                          to={`/job/${item.slug}`}
                          type="button"
                          className="btn btn-primary ms-2">
                          <FaRegEye />
                        </Link>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
          <div className="pagination pagination-lg justify-content-center text-center mt-3">
            <Reactpaginate
              nextLabel={<>Next </>}
              pageCount={pageCount}
              onPageChange={(selectedPage) =>
                setPageNumber(selectedPage.selected)
              }
              disabledClassName={"pagination__link--disabled"}
              previousLabel="Previous"
              containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active  pb-3"
              pageClassName="page-item ml-1 mr-1"
              pageLinkClassName="page-link" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewjobemp;




  // this code use safter some time 
//   import React, { useContext, useEffect, useMemo, useState } from "react";
// import ReactPaginate from "react-paginate";
// import { FaRegEye, FaSearch } from "react-icons/fa";
// import { LinearProgress } from "@mui/material";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import Contextapi from "../../Contextpage/Contextapi";

// const Viewjobemp = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [input, setInput] = useState("");
//   const [category, setCategory] = useState([]);
//   const [company, setCompany] = useState([]);
//   const [pageNumber, setPageNumber] = useState(0);

//   const userPerPage = 5;

//   const contextData = useContext(Contextapi);
//   const domainName = contextData.domainapi;
//   const keyId = contextData.keyID;

//   const params = useMemo(() => ({ Client_ID: keyId }), [keyId]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [jobsResponse, categoryResponse, companyResponse] = await Promise.all([
//           axios.get(`${domainName}/api/get-jobpost`, { params }),
//           axios.get(`${domainName}/api/get-jobcategory`, { params }),
//           axios.get(`${domainName}/api/get-all-company`, { params })
//         ]);

//         const today = new Date();
//         const filteredJobs = jobsResponse.data.filter(job => new Date(job.job_end_date) >= today);

//         setCategory(categoryResponse.data);
//         setCompany(companyResponse.data);
//         setData(filteredJobs);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [params, domainName]);

//   const filteredData = useMemo(() => {
//     return data.filter(item =>
//       input.toLowerCase() === ""
//         ? true
//         : item.job_city.toLowerCase().includes(input.toLowerCase())
//     );
//   }, [data, input]);

//   const sortedData = useMemo(() => {
//     return filteredData.sort((a, b) => new Date(b.job_end_date) - new Date(a.job_end_date));
//   }, [filteredData]);

//   const pageCount = Math.ceil(sortedData.length / userPerPage);
//   const offset = pageNumber * userPerPage;

//   if (loading) {
//     return (
//       <div className="container">
//         <div className="row p-3 py-10">
//           <LinearProgress />
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid upshowing">
//       <div className="row">
//         <div className="col-lg-6">
//           <nav aria-label="breadcrumb">
//             <ol className="breadcrumb p-3">
//               <li className="breadcrumb-item">
//                 <Link to="/general-setting" className="text-decoration-none">
//                   Home {' /'}
//                 </Link>
//               </li>
//               <li className="breadcrumb-item active ms-1" aria-current="page">
//                 jobs-availble
//               </li>
//             </ol>
//           </nav>
//         </div>

//         <div className="row">
//           <div className="col-lg-12 d-flex justify-content-end">
//             <div className="input-group col-lg-10 p-3 m-auto">
//               <input
//                 type="text"
//                 className="form-control"
//                 id="search"
//                 placeholder="Search here"
//                 onChange={(e) => setInput(e.target.value)}
//               />
//               <FaSearch id="srh-icon" />
//             </div>
//           </div>
//         </div>

//         <div className="table-responsive">
//           <table className="table table-striped table-hover text-center">
//             <thead>
//               <tr>
//                 <th scope="col">#</th>
//                 <th scope="col">Category</th>
//                 <th scope="col">Mode</th>
//                 <th scope="col">Type</th>
//                 <th scope="col">Company</th>
//                 <th scope="col">Package</th>
//                 <th scope="col">Total jobs</th>
//                 <th scope="col">End-date</th>
//                 <th scope="col">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {sortedData.slice(offset, offset + userPerPage).map((item, index) => (
//                 <tr key={item.id}>
//                   <td>{index + 1}</td>
//                   <td>
//                     {category
//                       .filter(cat => cat.id === item.job_category)
//                       .map(cat => <span key={cat.id}>{cat.title || "-"}</span>)}
//                   </td>
//                   <td>{item.job_mode}</td>
//                   <td>{item.job_type}</td>
//                   <td>
//                     {company
//                       .filter(comp => comp.company_id === item.company_id)
//                       .map(comp => <span key={comp.id}>{comp.name || "-"}</span>)}
//                   </td>
//                   <td>{item.package}</td>
//                   <td>{item.total_post}</td>
//                   <td>{item.job_end_date}</td>
//                   <td>
//                     <Link to={`/job/${item.slug}`} className="btn btn-primary ms-2">
//                       <FaRegEye />
//                     </Link>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
        
//         <div className="pagination pagination-lg justify-content-center text-center mt-3">
//           <ReactPaginate
//             nextLabel="Next"
//             pageCount={pageCount}
//             onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
//             disabledClassName="pagination__link--disabled"
//             previousLabel="Previous"
//             containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
//             breakClassName="page-item"
//             breakLinkClassName="page-link"
//             previousClassName="page-item"
//             previousLinkClassName="page-link"
//             nextClassName="page-item"
//             nextLinkClassName="page-link"
//             activeClassName="active pb-3"
//             pageClassName="page-item ml-1 mr-1"
//             pageLinkClassName="page-link"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Viewjobemp;
