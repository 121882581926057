import React, { useContext, useEffect, useMemo, useState } from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import stupro from "../../../../assets/images/contactform.png";
import { Link, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Contextapi from "../../../Contextpage/Contextapi";
import { FaArrowLeft } from "react-icons/fa";


const Profileofcompanyuser = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { company_id } = useParams();
  console.log("company id ", company_id)
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;


  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    fetchData();
  }, [company_id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        ` ${domainname}/api/get-single-company/${company_id}`,
        { params }
      );
      console.log("response from server : ", response);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  if (loading) {
    return (
      <div className="container">
        <div className="row p-3 py-10">
          <LinearProgress />
        </div>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="container">
        <div className="row p-5 mt-10">
          <LinearProgress />
        </div>
      </div>
    );
  }


  console.log("CompanyData", data);


  return (
    <>
      <section className="admin_profile">
        <div className="container">
          <div className="row">
            <Breadcrumb className="p-3">
              <Breadcrumb.Item as={Link} to="/admin-dashboard">
                Home /
              </Breadcrumb.Item>
              <Breadcrumb.Item as={Link} to="/admin-dashboard/company/all-company">
                All Companies /
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>

            <Link to="/admin-dashboard/company/all-company">
              <div className="back-button d-flex justify-content-end me-3 mb-3">

                <button className="site-button px-2 py-1  d-flex gap-2 align-items-center">
                  <FaArrowLeft className="text-light fs-5" />
                  <span>
                    Back
                  </span>
                </button>

              </div>
            </Link>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">

              <div className="shadow ">
                <h3 className="personal-details-heading"> Company Information </h3>
                <div className="personal-details">
                  <div className="row">

                    <div className="col-lg-6 col-md-8 col-12">

                      <div className="comapny-details">
                        <h5>Name  :  <span>{data.name || "------"}</span></h5>
                        <h5>Email  :  <span>{data.email || "------"}</span></h5>
                        <h5>Alternate Email :    <span>{data.alternate_email || "------"}</span></h5>
                        <h5>Contact No  : <span>{data.mobile || "------"}</span></h5>
                        <h5>Alternate Contact No  : <span>{data.alternate_mobile || "------"}</span></h5>
                        <h5>City: <span>{data.location || "--------"}</span></h5>
                        <h5>Employee Strength:  <span className="text-primary">{data.employee_strength || "------"}</span> </h5>
                        <h5>Industry Type: <span className="text-primary">{data.industry_type || "------"}</span> </h5>
                        <h5>Company Website:  <Link to={data.company_website || "------"} target="_blank" > <span className="text-primary">{data.company_website || "------"}</span> </Link></h5>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">

                      <div className="comapny-image">
                        <img src={data.company_logo || stupro} alt={data.name} title={data.name} className="img-fluid" />
                      </div>
                    </div>
                  </div>




                </div>

              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default Profileofcompanyuser;