import React, { useContext, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import stupro from "../../../../assets/images/contactform.jpg";
import { Link, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Contextapi from "../../../Contextpage/Contextapi";
import Allemployees from "./Allemployees";
import { MdBackHand } from "react-icons/md";

const Profileofemployeeuser = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams()

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyid = contextdata.keyID;

    const params = useMemo(
        () => ({
            Client_ID: keyid,
        }),
        [keyid]
    );

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                ` ${domainname}/api/get-single-employee/${id}`,
                { params }
            );
            console.log("response from employee : ", response.data);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    if (loading) {
        return (
            <div className="container">
                <div className="row p-3 py-10">
                    <LinearProgress />
                </div>
            </div>
        );
    }

    if (!data || data.length === 0) {
        return (
            <div className="container">
                <div className="row p-5 mt-10">
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (

        <section className="admin_profile">
            <div className="container">
                <div className="row align-items-center top   ">
                    <div className="col-lg-6">
                        <h3 className="m-2">
                            {data.name || "Profile"} welcome
                            <MdBackHand className="text-warning anim" /> !
                        </h3>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end">
                        <Breadcrumb className="p-3">
                            <Breadcrumb.Item as={Link} to="/employee-dashboard">
                                Home /
                            </Breadcrumb.Item>
                            <Breadcrumb.Item as={Link} to="/employee-dashboard/employee/all-employee">
                                all-employees /
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{data.name || "Profile"}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12 col-md-12 col">
                        <div className=" card px-auto hit">
                            <div className="row align-items-start">
                                <div className="col-lg-5 col-md-12">
                                    <div className="student_image d-flex justify-content-center mt-3">
                                        <img src={data.profile_image || stupro} alt={data.name || "student_image"} title={data.name || "profile"} className="w-50" />
                                    </div>
                                    <div className="name my-2">
                                        <h3 className="clip_stu text-center">
                                            Name : <span> {data.name || "--"}  </span>
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-lg-7 col-md-12 ">
                                    <ul className="nav nav-pills nav_of_stu">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                data-bs-toggle="pill"
                                                href="#overview"
                                                role="tab"
                                                aria-bs-controls="pills-overview"
                                                aria-bs-selected="true">
                                                Overview
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-bs-toggle="pill"
                                                href="#address"
                                                role="tab"
                                                aria-bs-controls="pills-address"
                                                aria-bs-selected="true">
                                                Address
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-bs-toggle="pill"
                                                href="#education"
                                                role="tab"
                                                aria-bs-controls="pills-education"
                                                aria-bs-selected="false">
                                                Education
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-bs-toggle="pill"
                                                href="#bank"
                                                role="tab"
                                                aria-bs-controls="pills-bank"
                                                aria-bs-selected="false">
                                                Bank Details
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content mt-3">
                                        <div
                                            className="tab-pane fade show active"
                                            id="overview"
                                            role="tabpanel"
                                            aria-labelledby="overview-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="hback">Personal Details</h3>

                                                    <div className="second ">
                                                        <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="email">Name : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.name || "----"}</span>
                                                            </div>
                                                        </div>
                                                        <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="email">Email : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.email || "----"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="rel">Mobile : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.mobile || "----"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="gender">Gender : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.gender || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="category">Category : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.category || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="dob">D.O.B : </span>{" "}
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span >{data.dob || "--/--"}</span>
                                                            </div>{" "}
                                                        </div>

                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">religion : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.religion || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Registered By: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.registered_by || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Profession : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.profession || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div
                                            className="tab-pane fade "
                                            id="address"
                                            role="tabpanel"
                                            aria-labelledby="address-tab">

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="hback">Address</h3>

                                                    <div className="second">
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">Country : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.country || "India"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">State : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.state || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">city : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.city || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">Current Address : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span >{data.address || "----"}</span>
                                                            </div>{" "}
                                                        </div>


                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">zipcode : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span >{data.pincode || "----"}</span>
                                                            </div>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>







                                        <div
                                            className="tab-pane fade "
                                            id="education"
                                            role="tabpanel"
                                            aria-labelledby="education-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="hback">Education Details</h3>
                                                    <div className="second">
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Identity: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                {data.identity ? (
                                                                    <a href={data.identity} target="__blank" download="identity">
                                                                        Download Identity
                                                                    </a>
                                                                ) : (
                                                                    <span>---</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Resume: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                {data.resume ? (
                                                                    <a href={data.resume} target="__blank" download="resume">
                                                                        Download Resume
                                                                    </a>
                                                                ) : (
                                                                    <span>---</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="dob">Highest Education: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.highest_education || "---"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span>Degree: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                {data.marksheet ? (
                                                                    <a href={data.marksheet} target="__blank" download="degree">
                                                                        Download Degree
                                                                    </a>
                                                                ) : (
                                                                    <span>---</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="gender">Experience Years: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.experience_years || "---"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Experience Field: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.experience_field || "---"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Description: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span dangerouslySetInnerHTML={{ __html: data.experience_description || "---" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="tab-pane fade  "
                                            id="bank"
                                            role="tabpanel"
                                            aria-labelledby="bank-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="hback">Bank Details</h3>

                                                    <div className="second ">
                                                        <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="name">
                                                                    Account Holder Name :{" "}
                                                                </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.account_holder_name || "---"}</span>
                                                            </div>
                                                        </div>
                                                        <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="name">Bank Name: </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.bank_name || "---"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="name">Branch Name : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{data.branch_name || "----"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="number">Account Number : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.account_number || "----"} </span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="text">IFSC Code : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="lowercase">{data.ifsc_code || "--/--"} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Profileofemployeeuser;