import React, { useContext, useEffect, useMemo, useState } from "react";
import Reactpaginate from "react-paginate";
import Swal from "sweetalert2";
import { FaSearch, FaTrash } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import GalleryAdd from "./GallleryAdd";
import Contextapi from "../../../Contextpage/Contextapi";

const Gallery = () => {
  const [data, SetData] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Addform, setAddform] = useState(false);
  const [input, Setinput] = useState("");
  const handleUpdate = () => {
    setAddform(!Addform);
  };

  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 5;
  const pageCount = Math.ceil(data.length / userPerPage);
  const offset = pageNumber * userPerPage;

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-gallery`, {
          params,
        });
// console.log("gallerr",response)
        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [domainname, params,handleUpdate]);

  const handleDelete = async (id) => {
    try {
      await axios.post(`${domainname}/api/delete-gallery/${id}`, {
        Client_ID: keyid,
      });

      // console.log(response);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "sucessfully Deleted",
      });

      handleUpdate();
    } catch (error) {
      console.error("There was a problem with your delete operation:", error);
    }
  };
  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }

  // console.log(data);
  return (
    <div className="container-fluid upshowing">
      <div className="row ">
        <div className="col-lg-6">
          <nav aria-label=" breadcrumb ">
            <ol className="breadcrumb  p-3">
              <li className="breadcrumb-item ">
                <Link to="/admin-dashboard/home" className="text-decoration-none ">
                 Admin
                </Link>
              </li>
              <li className="ms-1" aria-current="page">
                {'/'} Gallery
              </li>
            </ol>
          </nav>
        </div>

        {Addform ? (
          <GalleryAdd handleUpdate={handleUpdate} />
        ) : (
          <div className="row slider_page">
            <div className=" ">
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="row align-items-center ">
                  <div className=" upbtn d-flex justify-content-end">
                    <button onClick={handleUpdate}>Add</button>
                  </div>
                </div>
                <div className="row g-3 p-3">
                  <div className=" col-lg-10 p-3 m-auto">
                    <label htmlFor="search" className="visually-hidden">
                      search
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Search here"
                        onChange={(e) => Setinput(e.target.value)}
                      />
                      <FaSearch id="srh-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="table table-striped table-hover text-center ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Logo</th>
                  <th scope="col">Title</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data
                    .filter((item) =>
                      input.toLowerCase() === ""
                        ? true
                        : item.title.toLowerCase().includes(input.toLowerCase())
                    )
                    .slice(offset, offset + userPerPage)
                    .map((item, index) => (
                      <tr key={item.id} className="content-related">
                        <td>{index + 1}</td>
                        <td>
                          <img
                            src={item.gallery_image}
                            title={item.title}
                            alt={item.title}
                          />
                        </td>
                        <td>{item.title}</td>
                        <td>
                          <div
                            className="discription_content"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </td>
                        <td className="">
                          <button
                            type="button"
                            className="btn btn-danger ms-2"
                            onClick={() => handleDelete(item.id)}>
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <div className="pagination pagination-lg justify-content-center text-center mt-3">
              <Reactpaginate
                nextLabel={<>Next </>}
                pageCount={pageCount}
                onPageChange={(selectedPage) =>
                  setPageNumber(selectedPage.selected)
                }
                disabledClassName={"pagination__link--disabled"}
                previousLabel="Previous"
                containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active  pb-3"
                pageClassName="page-item ml-1 mr-1"
                pageLinkClassName="page-link"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Gallery;
