import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Contextapi from "../../../Contextpage/Contextapi";
import StarRating from "../../../Pages/Star/RatingStar";

const ViewTestimonial = () => {
  const [data, setData] = useState([]);
  const keyID = "9|tdyfgjcgvjhbkvjhbkj@hcgvjh8465132ghvjb";
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      const res = await axios.post(`
        ${domainname}/api/update-testimonial-status/${id}`,
        { status: newStatus },
        {
          params: {
            Client_ID: keyID,
          },
        }
      );

      // console.log(res)

      setData(
        data.map((item) =>
          item.id === id ? { ...item, status: newStatus } : item
        )
      );

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Testimonial status updated successfully!",
      });
      // handleCloseModal();
    } catch (error) {
      console.error("Error updating testimonial status:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again.",
      });
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 7;
  const pageCount = Math.ceil(data.length / usersPerPage);
  const offset = pageNumber * usersPerPage;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`
        ${domainname}/api/get-all-testimonials`,
        { params }
      );
      // console.log("response from server : ", response.data);

      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div className="row p-3 py-10">
          <LinearProgress />
        </div>
      </div>
    );
  }

  const handleStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'btn-warning';
      case 'approved':
        return 'btn-success';
      case 'rejected':
        return 'btn-danger';
      default:
        return '';
    }
  };

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb  p-3">
              <li className="breadcrumb-item ">
                <Link
                  to="/admin-dashboard/home"
                  title="Home page"
                  className="text-decoration-none ">
                  Admin
                </Link>
              </li>
              <li className="ms-1" aria-current="page">
                {'/'} Testimonial
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="">
            <div className="col-lg-12 d-flex justify-content-end">
              <div className="row g-3 p-3">
                <div className=" col-lg-10 p-3 m-auto">
                  <label htmlFor="search" className="visually-hidden">
                    search
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Search here"
                      onChange={(e) => setInput(e.target.value)}
                    />
                    <FaSearch id="srh-icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover text-center ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Rating</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>
            {data.length > 0 ? (
              <tbody>
                {data
                  .filter((item) =>
                    input.toLowerCase() === ""
                      ? true
                      : item.name.toLowerCase().includes(input.toLowerCase())
                  )
                  .slice(offset, offset + usersPerPage)
                  .map((item, index) => (
                    <tr key={item.id} className="content-related align-middle">
                      <td>{index + 1}</td>
                      <td>
                        <img
                          className="textimage"
                          src={item.testimonial_image}
                          title={item.name}
                          alt={item.name}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td className="rating_star_testi">
                        <div>
                          <StarRating rating={item.rating} />
                        </div>
                      </td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </td>

                      <td>
                        <select
                          className={`form-select rounded-md text-white ${handleStatusColor(item.status)}`}
                          value={item.status}
                          onChange={(e) => handleStatusUpdate(item.id, e.target.value)}
                        >
                          <option value='pending' className='text-lg'>Pending</option>
                          <option value='approved'>Approved</option>
                          <option value='rejected'>Rejected</option>
                        </select>
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="8" className="no-data-message">No Testimonial</td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="pagination pagination-lg justify-content-center text-center mt-3">
            <ReactPaginate
              nextLabel={<>Next </>}
              pageCount={pageCount}
              onPageChange={(selectedPage) =>
                setPageNumber(selectedPage.selected)
              }
              disabledClassName={"pagination__link--disabled"}
              previousLabel="Previous"
              containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active  pb-3"
              pageClassName="page-item ml-1 mr-1"
              pageLinkClassName="page-link"
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default ViewTestimonial;