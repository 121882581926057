import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { LinearProgress } from '@mui/material';
import { FaPen } from 'react-icons/fa';
import ProfileEditcmp from './ProfileEditcmp';
import Contextapi from '../../Contextpage/Contextapi';
import { MdBackHand } from 'react-icons/md';

const CompanyProfile = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [formOpen, setFormOpen] = useState(false);

    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const setname = useMemo(() => localStorage.getItem('name') || '', []);
    const id = getid ? getid.replace(/"/g, '') : '';
    const cmp_name = setname ? setname.replace(/"/g, '') : '';

    const { domainapi: domainname, keyID } = useContext(Contextapi);

    const handleUpdate = () => {
        setFormOpen(!formOpen);
    };

    const params = useMemo(() => ({
        Client_ID: keyID,
    }), [keyID]);

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get(`${domainname}/api/get-company/${id}`, { params });
                setData(response.data[0]);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching company data:", error);
                setLoading(false);
            }
        };
        fetchCompanyData();
    }, [formOpen, params, domainname, id]);

    if (loading) {
        return (
            <div className="bodyloading">
                <div className="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        );
    }

    return (
        <div className="container profile_of_student mt-5 mb-5 upshowing">
            <div className="row common_dash">
                <div className="col-lg-12" id='nav'>
                    <nav aria-label="breadcrumb">
                        <h3>
                            Welcome {cmp_name}
                            <MdBackHand className="text-warning anim" /> !
                        </h3>
                    </nav>
                </div>
            </div>
            {formOpen ? (
                <ProfileEditcmp handleUpdate={handleUpdate} />
            ) : !data ? (
                <div className='row align-items-center'>
                    <div className='col-12 p-1'>
                        <div>Profile data is not updated. Please update profile.</div>
                        <div className='col-2 upbtn d-flex justify-content-end'>
                            <button onClick={handleUpdate} className='site-button'>
                                Update Profile
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row align-items-center'>
                        <div className='col-2 upbtn d-flex justify-content-end'>
                            <button onClick={handleUpdate} className='site-button'>
                                Update Profile
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col">
                            <div className="hit border-0">
                                <div className='card border-0 p-2 card-1'>
                                    <div className="student_image mt-3">
                                        <img
                                            src={data.company_logo || "https://img.freepik.com/free-photo/html-system-websites-collage-design_23-2150432963.jpg?t=st=1710146930~exp=1710150530~hmac=9d062679e34b54a60eb687d36e0541f2c33b4a54122283295d3cc91f50bbe3b3&w=740"}
                                            alt={data.name}
                                            title={data.name}
                                        />
                                    </div>
                                    <div id="name" className='d-flex pt-2 justify-content-center'>
                                        <h3>
                                            Name: <span>{data.name === 'null'  ? 'No Data ' : data.name }</span>
                                        </h3>
                                    </div>
                                    <div className="location">
                                        <span className="icon">Industry Type: </span>
                                        <span className="ltext">{data.industry_type === 'null' ? ' No Data ' : data.industry_type }</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2">
                            <div className='card shadow p-3 card-2'>
                                <div className="second">

                                    <div id="display" className="mb-2">
                                        <span id="email">Email: </span>
                                        <span>{data.email === 'null' ? 'No Data' : data.email}</span>
                                    </div>
                                    <div id="display" className="mb-2">
                                        <span id="email">Alternate Email: </span>
                                        <span>{data.alternate_email === 'null' ? 'No Data' : data.alternate_email}</span>
                                    </div>
                                    <div className="mb-2">
                                        <span id="id">Mobile: </span> <span>{data.mobile === 'null' ? 'No Data' : data.mobile}</span>
                                    </div>
                                    <div className="mb-2">
                                        <span id="id">Alternate Mobile: </span> <span>{data.alternate_mobile === 'null' ? 'No Data' : data.alternate_mobile}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2">
                            <div className='card shadow p-3 card-2'>
                                <div className="second">
                                   <div className="mb-2">
                                        <span id="rel">Website: </span>
                                        {data.company_website === 'null' ? 'No Data' : <a href={data.company_website} target='_blank' rel="noopener noreferrer" className="text-primary">{data.company_website}</a> }
                                    </div>
                                   
                                    <div className="mb-2">
                                        <span id="rel">Industry Type: </span>
                                        <span>{data.industry_type === 'null' ? 'No Data' : data.industry_type}</span>
                                    </div>
                                    <div className="mb-2">
                                        <span id="rel">Employee Strength: </span>
                                        <span>{data.employee_strength === 'null' ? 'No Data' : data.employee_strength}</span>
                                    </div>
                                    <div className="mb-2">
                                        <span id="rel">Address: </span>
                                        <span className="lowercase">{data.location === 'null' ? 'No Data' : data.location}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CompanyProfile;