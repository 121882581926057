import React, { useContext, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Contextapi from "../../../Contextpage/Contextapi";

function AdvisorSignup() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  const validatePassword = (password) => {
    // Add your custom password validation logic here
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  const validateMobile = (mobile) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateMobile(formData.mobile)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Mobile Number",
        text: "Mobile number must have exactly 10 digits",
      });
      return;
    }
    if (!validatePassword(formData.password)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Password",
        text: "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one digit, and one special character",
      });
      return;
    }
    try {
      const res = await axios.post(`${domainname}/api/add-agent`, formData, {
        params: {
          Client_ID: keyid,
        },
      });
      console.log("Advisor", res);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "Success",
        text: "Advisor Registration Successfully",
      });
      navigate("/admin-dashboard/agent/all-agent");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "try Again....",
        text: "server error this not responding",
      });
      console.log(error, "something is wrong");
    }
  };
  return (
    <div className="container comapnay_form">
      <div className="row mt-2">
        <div className="col-lg-9 mx-auto mt-5">
          <div className="registerWrapper">
            <div className="twm-tabs-style-2">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-12 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="username">
                      Agent Name
                    </label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Agent Name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="email">
                      Email
                    </label>
                    <input
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="phone">
                   Mobile No
                    </label>
                    <input
                      name="mobile"
                      type="number"
                      maxLength={10}
                      inputMode="numeric"
                      className="form-control"
                      placeholder="Mobile-number"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="password">
                      Password
                    </label>
                    <input
                      name="password"
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12 ">
                  <button type="submit" className="site-button">
                    Add Agent
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvisorSignup;
