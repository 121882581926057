import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import Contextapi from "../../Contextpage/Contextapi";
import { format } from 'date-fns';

const ApplyApplication = () => {
  const getid = localStorage.getItem("id");
  const empid = getid ? getid.replace(/"/g, "") : "";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const userPerPage = 9;

  const contextData = useContext(Contextapi);
  const domainName = contextData.domainapi;
  const keyId = contextData.keyID;

  const params = useMemo(() => ({ Client_ID: keyId }), [keyId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainName}/api/get-jobapplication/${empid}`, { params });
        setData(response.data);
        // const resCategory = await axios.get(`${domainName}/api/get-jobcategory`, { params });
        // const resCompany = await axios.get(`${domainName}/api/get-all-company`, { params });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [domainName, params, empid]);

  const filteredData = useMemo(() => {
    return data.filter(item =>
      input.toLowerCase() === ""
        ? true
        : item.job_title.toLowerCase().includes(input.toLowerCase())
    );
  }, [data, input]);
  // console.log("view filter ",filteredData)

  const pageCount = Math.ceil(data.length / userPerPage);
  const offset = pageNumber * userPerPage;



  if (loading) {
    return (
      <div className="container">
        <div className="row p-3 py-10">
          <LinearProgress />
        </div>
      </div>
    );
  }


  // console.log("response reequest jobs", data)

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
              <li className="breadcrumb-item">
                <Link to="/employee-dashboard/home" className="text-decoration-none">
                  Profile {'/'}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Job-Request
              </li>
            </ol>
          </nav>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-end">
            <div className="row align-items-center "></div>
            <div className="row g-3 p-3">
              <div className=" col-lg-10 p-3 m-auto">
                <label htmlFor="search" className="visually-hidden">
                  search
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder="Search here"
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <FaSearch id="srh-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <table className="table table-striped table-hover text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Job Title</th>
              <th scope="col">Monthly Package</th>
              <th scope="col">Job End Date</th>
              <th scope="col">Company</th>
              <th scope="col">Mobile</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          {data.length > 0 ?
            (
              <tbody>
                {filteredData && filteredData.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.job_title}</td>
                    <td>{item.package}</td>
                    <td>{format(item.job_end_date, 'd-MMMM-y')}</td>
                    <td>{item.company_name}</td>
                    <td>{item.company_mobile}</td>
                    <td>
                      {item.status === "pending" ? (
                        <button className="btn btn-warning text-white">Pending</button>
                      ) : item.status === "approved" ? (
                        <button className="btn btn-success text-white">Approved</button>
                      ) : (
                        <button className="btn btn-danger text-white">Rejected</button>
                      )}
                    </td>

                  </tr>
                ))}
              </tbody>
            )


            : (
              <tbody>
                <tr>
                  <td colSpan="8" className="no-data-message">No applied application</td>
                </tr>
              </tbody>
            )}
        </table>

        <div className="pagination pagination-lg justify-content-center text-center mt-3">
          <ReactPaginate
            nextLabel="Next"
            pageCount={pageCount}
            onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
            disabledClassName="pagination__link--disabled"
            previousLabel="Previous"
            containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active pb-3"
            pageClassName="page-item ml-1 mr-1"
            pageLinkClassName="page-link"
          />
        </div>
      </div>
    </div>
  );
};

export default ApplyApplication;
