import React from 'react'

const AgentFooter = () => {
    return (
        <>
            <section className='dashboard_footer'>
                <div className='container_fluid'>
                    <h6 className='text-center'> <i>Copyright © 2024{' |'}</i> R&G Sharmik Mitra. All rights reserved.</h6>
                </div>
            </section>
        </>
    )
}

export default AgentFooter