import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';

const RichText = ({ placeholder, name, onChange, value }) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(value);
  }, [value]);

  const config = useMemo(() => ({
    readonly: false,
    placeholder: placeholder || 'Start typing...',
    removeButtons: ['fullsize', 'preview', 'print'], // Customize toolbar buttons
    enter: 'p', // Use 'br' instead of 'p' for new lines
    defaultActionOnPaste: 'insert_as_text', // Paste plain text by default
    toolbarSticky: false, // Sticky toolbar disabled
    toolbarAdaptive: false, // Adaptive toolbar disabled
    showCharsCounter: false, // Disable character counter
    showWordsCounter: false, // Disable word counter
    showXPathInStatusbar: false, // Disable XPath in status bar
    buttons: [
      'source', 'bold', 'italic', 'underline', 'strikethrough', 'eraser',
      'ul', 'ol', 'outdent', 'indent', 'image', 'link', 'table', 'hr',
      'align', 'undo', 'redo', 'copyformat', 'cut', 'copy', 'paste', 'brush', 'paragraph'
    ], // Custom toolbar buttons
  }), [placeholder]);

  const handleContentChange = (newContent) => {
    setContent(newContent); // Update content state with new content
    onChange(name, newContent); // Call the onChange prop with updated content
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onBlur={(newContent) => handleContentChange(newContent)} // Use onBlur to handle content changes
    />
  );
};

export default RichText;