import React, { useContext, useEffect, useMemo, useState } from "react";
import Reactpaginate from "react-paginate";
import Swal from "sweetalert2";
import { FaEye, FaSearch } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import * as XLSX from 'xlsx';

import { Link } from "react-router-dom";
import Contextapi from "../../Contextpage/Contextapi";

const Newsletter = () => {
  const [data, SetData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [input, Setinput] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 5;
  const pageCount = Math.ceil(data.length / userPerPage);
  const offset = pageNumber * userPerPage;

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-newsletter`, {
          params,
        });
        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [params, domainname]);

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "NewsLetter");
    XLSX.writeFile(workbook, "newsletter.xlsx");
};
  const handleCopy = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Copied to clipboard!",
          text: `${email} has been copied.`,
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
        Swal.fire({
          icon: "error",
          title: "Failed to copy",
          text: "An error occurred while copying.",
        });
      });
  };

  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }
  if (!data) {
    return <div>
      <p>no record</p>
    </div>;
  }

  return (
    <div className="container-fluid upshowing">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label=" breadcrumb ">
            <ol className="breadcrumb  p-3">
              <li className="breadcrumb-item ">
                <Link to="/admin-dashboard/home" className="text-decoration-none ">
                  Admin{'/'}
                </Link>
              </li>
              <li className="ms-1" aria-current="page">
                Newsletter
              </li>
            </ol>
          </nav>
        </div>

        <div className="row">
          <div className="">
            <div className="col-lg-12 d-flex justify-content-end">
              <div className="row g-3 p-3 align-items-center">
                <div className=" col-lg-8 p-3 m-auto">
                  <label htmlFor="search" className="visually-hidden">
                    search
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Search here"
                      onChange={(e) => Setinput(e.target.value)}
                    />
                    <FaSearch id="srh-icon" />
                  </div>
                </div>
                <div className="col-4">
                  <button className=" btn-success btn-sm d-flex gap-1 align-items-center" onClick={handleExportExcel}> Export To Excel</button>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover text-center ">
            <thead>
              <tr>
                <th scope="col">#</th>

                <th scope="col">email</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            {data.length > 0 ? (
              <tbody>
                {data &&
                  data
                    .filter((item) =>
                      input === ""
                        ? true
                        : item.subscribed_email.toLowerCase().includes(input.toLowerCase())
                    )
                    .slice(offset, offset + userPerPage)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td >{item.subscribed_email}</td>
                        <td>
                          <button className="btn btn-primary btn-sm" onClick={() => handleCopy(item.subscribed_email)}>Copy</button>
                        </td>

                      </tr>
                    ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="8" className="no-data-message">No subscribe </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="pagination pagination-lg justify-content-center text-center mt-3">
            <Reactpaginate
              nextLabel={<>Next </>}
              pageCount={pageCount}
              onPageChange={(selectedPage) =>
                setPageNumber(selectedPage.selected)
              }
              disabledClassName={"pagination__link--disabled"}
              previousLabel="Previous"
              containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active  pb-3"
              pageClassName="page-item ml-1 mr-1" // Custom class for pagination item
              pageLinkClassName="page-link" // Custom class for pagination link
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;