import React from 'react';
import { FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Tobbar = () => {
    const navigate = useNavigate();
    const logoutfun = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('path');
        localStorage.removeItem('role');
        localStorage.removeItem('name');
        localStorage.removeItem('id');
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            }
        });
        Toast.fire({
            icon: "success",
            title: "Log-out successfully"
        });
        navigate('/')
    };
    return (
        <div className='topbar'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-end">
                        <Dropdown >
                            <Dropdown.Toggle variant="light" id="user-dropdown">
                                <FaUser />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* <Dropdown.Item ><Link to={'/profile'} className='btn'>Profile</Link></Dropdown.Item> */}
                                <Dropdown.Item ><Link to={'/admin-dashboard/admin-change-password'} className='btn'>Password-change</Link></Dropdown.Item>
                                <Dropdown.Item>
                                    <button onClick={() => logoutfun()} className='btn'>Log-out</button>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>


                </div>
            </div>
        </div>

    );
}

export default Tobbar;
