import React, { useContext, useEffect, useMemo, useState } from 'react';
import Reactpaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch} from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Contextapi from '../../../../Contextpage/Contextapi';
import { format } from 'date-fns';

const AppliedJobs = () => {
    const [data, SetData] = useState("");
    const [Loading, setLoading] = useState(true);
    const [input, Setinput] = useState("");
    const [category, setCategory] = useState([]);
    const [Company, setCompany] = useState([]);
    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const id = getid && getid ? getid.replace(/"/g, '') : '';
    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyID = contextdata.keyID;
    const params = useMemo(() => ({
        Client_ID: keyID,
    }), [keyID]);

    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 7;
    const pageCount = Math.ceil(data.length / userPerPage);
    const offset = pageNumber * userPerPage;

    const handleStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'btn-warning';
            case 'approved':
                return 'btn-primary';
            case 'rejected':
                return 'btn-danger';
            default:
                return '';
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${domainname}/api/get-jobapplications`, { params });
                const rescategory = await axios.get(`${domainname}/api/get-jobcategory`, { params });
                const rescompany = await axios.get(`${domainname}/api/get-all-company`, { params });
                
                const jobPostIds = response.data.map(item => item.job_post_id);
                // console.log("jobsid", jobPostIds);

                const JobsData = await contextdata.APIdata.jobs;
                const jobStatusMap = response.data.reduce((acc, curr) => {
                    acc[curr.job_post_id] = curr.status;
                    return acc;
                }, {});
                const filteredJobs = JobsData.filter(job => jobPostIds.includes(String(job.id)));

                const jobsWithStatus = filteredJobs.map(job => ({
                    ...job,
                    status: jobStatusMap[job.id] || "Unknown"
                }));
                // console.log("dafa", jobsWithStatus);
                SetData(jobsWithStatus);
                setCategory(rescategory.data);
                setCompany(rescompany.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [domainname, params, contextdata.APIdata.jobs]);

    const handleDelete = async (id) => {
        try {
            const response = await axios.post(`${domainname}/api/delete-jobrequest/${id}`);
            console.log(response);

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: 'success',
                title: "Successfully Deleted",
            });
        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const handleStatusChange = async (id, newStatus) => {
        try {
            const res = await axios.post(
                `${domainname}/api/update-jobrequest-status/${id}`,
                { status: newStatus },
                { params: { Client_ID: keyID } }
            );
            SetData(data.map((item) => (item.id === id ? { ...item, status: newStatus } : item)));
            Swal.fire({
                icon: 'success',
                title: 'Status updated successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error('Error updating status:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops ',
                text:  error.response.data.message || 'Something went wrong! Please try again.',
            });
        }
    };

    if (Loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }
    if (!data) {
        return (
            <div className='container'>
                <div className='row p-5 mt-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }
    // console.log(data);
    return (
        <div className="container-fluid upshowing">
            <div className="row">
                <div className="col-lg-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb p-3">
                            <li className="breadcrumb-item"><Link to="/admin-dashboard/home" className='text-decoration-none ms-1'>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page"> {' / '} Applied-jobs</li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="">

                        <div className="col-lg-12 d-flex justify-content-end">

                            <div className="row g-3 p-3">
                                <div className="col-lg-10 p-3 m-auto">
                                    <label htmlFor="search" className="visually-hidden">Search</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => Setinput(e.target.value)} />
                                        <FaSearch id='srh-icon' />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped table-hover text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Category</th>
                                <th scope="col">Mode</th>
                                <th scope="col">Type</th>
                                <th scope="col">Company</th>
                                <th scope="col">Vacancy</th>
                                <th scope="col">Expired date</th>
                                <th scope="col">Status</th>
                                {/* <th scope="col">Action</th> */}
                            </tr>
                        </thead>
                        { data.length > 0 ? (
                        <tbody>
                            {data && data
                                .filter((item) =>
                                    input.toLowerCase() === '' ? true : item.job_city.toLowerCase().includes(input.toLowerCase()))
                                .slice(offset, offset + userPerPage)
                                .map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.job_title}</td>
                                        <td>{category && category.map((items) =>
                                            items.id == item.job_category ? (
                                                <span key={items.id}>
                                                    <span>{items.title ? items.title : '-'}</span>
                                                </span>
                                            ) : null
                                        )}</td>
                                        <td>{item.job_mode}</td>
                                        <td>{item.job_type}</td>
                                        <td>
                                            {Company &&
                                                Company.map((items) =>
                                                    items.company_id == item.company_id ? (
                                                        <span key={items.id}>
                                                            <span>{items.name ? items.name : '-'}</span>
                                                        </span>
                                                    ) : null
                                                )}
                                        </td>
                                        <td>{item.total_post}</td>
                                        <td>{format(item.job_end_date , "dd/MM/yyyy")}</td>
                                        <td>
                                            <select
                                                className={`form-select text-white ${handleStatusColor(item.status)}`}
                                                value={item.status}
                                                onChange={(e) => handleStatusChange(item.id, e.target.value)}
                                            >
                                                <option value='pending'>Pending</option>
                                                <option value='approved'>Approved</option>
                                                <option value='rejected'>Rejected</option>
                                            </select>
                                        </td>
                                        
                                    </tr>
                                ))}
                        </tbody>
                    ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="no-data-message">No jobs application</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                    <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                        <Reactpaginate
                            nextLabel={<>Next </>}
                            pageCount={pageCount}
                            onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                            disabledClassName={'pagination__link--disabled'}
                            previousLabel="Previous"
                            containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            activeClassName='active pb-3'
                            pageClassName='page-item ml-1 mr-1'
                            pageLinkClassName='page-link'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppliedJobs;