import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaArrowDown, FaCheckCircle, FaRegClock } from 'react-icons/fa'
import { RxCross2 } from "react-icons/rx";
import { FaRupeeSign } from "react-icons/fa";
import axios from 'axios';
import Contextapi from '../../Contextpage/Contextapi';
import { Link } from 'react-router-dom';


const Subscription = ({ formData, setFormData, jobrequest }) => {

    const [subscriptionData, setSubscriptioData] = useState([]);
    // const [offerCard, setOfferCard] = useState(false);

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyID = contextdata.keyID;
    const params = useMemo(
        () => ({
            Client_ID: keyID,
        }),
        [keyID]
    )

    // /get-all-subscription-plan

    useEffect(() => {
        const fetchSubscription = async () => {
            try {

                const res = await axios.get(`${domainname}/api/get-subscription-plan`, {
                    params,
                });
                setSubscriptioData(res.data);

            } catch (error) {
                console.log("Api Error", error)
            }
        }
        fetchSubscription();

    }, [domainname,])


    // const handelClose = () => {
    //     setOfferCard(true);
    // }

    const handelBuy = async (item) => {
        const amount = parseInt(item.price - ((item.price * item.discount) / 100));

        // const amount = 1;
        const redtUrl = "https://www.rgshramikmitra.com/company-dashboard/view-jobs";
        const days = item.days;

        setFormData((prevData) => ({
            ...prevData,
            amount: amount,
            redtUrl: redtUrl,
            days: days,

        }));
        const data = {
            ...formData,
            amount: amount,
            redtUrl: redtUrl,
            days: days,
        }



        // console.log("Subscription Formdata ", formData)
        // console.log("this send data data ", data)

        // Navigate  payment gatway to inisiate payment......        
        try {

            const res = await axios.post(`${domainname}/api/pay-now`, data,
                {
                    params: {
                        Client_ID: keyID,
                    },
                }
            )
            // console.log("data in url", res);
            let url = res.data.response.data.instrumentResponse.
                redirectInfo.url
            let paymentId = res.data.response.data.merchantTransactionId;
            // console.log("my url ", url)
            if (url) {
                localStorage.setItem('paymentId', paymentId);
                window.location.href = url
            } else {
                alert("not found")
            }
        } catch (error) {

            console.log("ApiError", error)

        }
    }

    return (
        <>
            <div className='container-fluid p-0'>
                <section className='subscription-section'>

                    <div className='row'>
                        {/* <div className={offerCard ? "d-none" : "d-block"}>
                            <div className='weekend-offer d-flex justify-content-between align-items-center px-5 bg-success'>

                                <h3 className='main-heading p-3 fs-5'>Weekend Offer ! Upgrade now for EXTRA validity and more benifits ! </h3>
                                <h4 className='text-light p-3 fs-6 d-flex  align-items-center gap-3'>
                                    <FaRegClock className='subscription-clock' /> <span>5 days Left </span> <RxCross2 className='subscription-cross-btn fs-3 fw-bold' onClick={handelClose} />
                                </h4>
                            </div>
                        </div> */}

                        <div className='col-lg-12 col-12'>
                            <div className='d-flex justify-content-evenly align-items-center gap-5 p-3 bg-light'>
                                <div className='subs-plan-heading justify-content-cneter'>
                                    <h3 className='fs-5 text-secondary text-decoration-underline '>Subscription Plan's  <FaArrowDown className='down-arrow' /></h3>
                                </div>
                                {/* <div>
                                    <h4 className=' fs-6'>
                                        Multiple
                                        <span className='text-secondary'> Days </span>  plan
                                    </h4>
                                </div>
                                <div >
                                    // <p className='p-0 m-0 '>Valid Till</p> 
                                    <h5> <span className='text-secondary'> Expiry </span>  Days</h5>
                                </div> */}
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='row justify-content-evenly align-items-center '>
                                {
                                    subscriptionData.map((item) =>

                                    (

                                        <div className='col-lg-3 col-md-4 col-sm-6' key={item.id}>
                                            <div className='card subscription-main-card rounded-3 m-3'>
                                                <div className='card-heading p-3'>
                                                    <h3 className='p-0 m-1 fs-7 text-primary text-center mb-4 '>{item.title}</h3>
                                                    <h4> <span>{item.days}</span> Days plan </h4>
                                                    <p className='text-secondary'>Valid till your plan's expiry   </p>

                                                    <h4 className='price-detils d-flex align-items-center gap-1 fs-4 my-2'>
                                                        <FaRupeeSign /><span className='text-decoration-line-through '>{item.price}</span> <span className='text-success ms-1 fs-6'>{parseInt(item.price - ((item.price * item.discount) / 100))}</span>
                                                    </h4>
                                                    <div className='card-description p-2'>
                                                        <div className='d-flex justify-content-start' >
                                                            <span> <FaCheckCircle className='text-success fs-5' /></span>  <span className='ms-2 ' dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                                        </div>
                                                    </div>
                                                    {jobrequest ? <button className='w-100 site-button' onClick={() => handelBuy(item)}>Buy Now </button> :
                                                        <Link to={'/company-dashboard/view-jobs'} title='add job request'> <button className='w-100 site-button' > Choose now </button> </Link>
                                                    }
                                                </div>
                                                <hr />

                                            </div>
                                        </div>
                                    )).reverse()
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Subscription