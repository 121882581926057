import React from 'react'
import RichText from '../../../Admin-dashboard/pages/Subpages/RichTexteditor';

const BasicInformation = ({ formData, setFormData }) => {
    
    const handleChange = (e) => {
        const { name, value ,type } = e.target;
        if(type === "file"){
            setFormData({
                ...formData,
                [name]: e.target.files[0],
            });
        }else{

            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    // const handleProfileChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({
    //         ...formData,
    //         profile_image: file,
    //     });
    // };
    // const handleResumeChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({
    //         ...formData,
    //         resume: file,
    //     });

    // };
    // const handlemarksheet = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({
    //         ...formData,
    //         marksheet: file,
    //     });

    // };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    return (
        <div className='container-fluid'>
            <div className='row'>

                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="profile_image">Profile Image</label>
                        <input
                            type="file"
                            className="form-control"
                            name="profile_image"
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="mobile">Mobile</label>
                        <input
                            type="text"
                            className="form-control"
                            name="mobile"
                            placeholder="Enter your mobile number"
                            value={formData.mobile}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                </div>
                <div className='col-lg-4'>

                    <div className="  form-group mb-3  ">
                        <label htmlFor="dob">Date of Birth</label>
                        <input
                            type="date"
                            className="form-control"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="  form-group mb-3  ">
                        <label>Gender </label>
                        <select
                            name="gender"
                            className=" form-select form-control"
                            value={formData.gender}
                            onChange={handleChange}
                        >
                            <option value="">Select Gender</option>
                            <option value='MALE'>MALE</option>
                            <option value='FEMALE'>FEMALE</option>
                            <option value='OTHERS'>OTHERS</option>
                        </select>
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="highest_education">Highest Education</label>
                        <input
                            type="text"
                            className="form-control"
                            name="highest_education"
                            placeholder="Enter your highest education"
                            value={formData.highest_education}
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3">
                        <label htmlFor="experience_years">Experience Year</label>
                        <input
                            type="text"
                            className="form-control"
                            name="experience_years"
                            placeholder="Enter your hexperience years"
                            value={formData.experience_years}
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="experience_field">Experience Field</label>
                        <input
                            type="text"
                            className="form-control"
                            name="experience_field"
                            placeholder="Enter your hexperience field"
                            value={formData.experience_field}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="resume">Resume</label>
                        <input
                            type="file"
                            className="form-control"
                            name="resume"
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="  form-group mb-3  ">
                        <label htmlFor="marksheet">Marksheet</label>
                        <input
                            type="file"
                            className="form-control"
                            name="marksheet"
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div className="col-lg-12">
                    <label htmlFor="terms" className="form-label">Descrption</label>
                    <RichText
                        name="experience_description"
                        placeholder={"description"}
                        value={formData.experience_description}
                        onChange={handleRichTextChange}
                    />
                </div>

            </div>
        </div>
    )
}

export default BasicInformation