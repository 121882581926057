import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RichText from "../Subpages/RichTexteditor";
import { FaArrowLeft } from "react-icons/fa";
import Contextapi from "../../../Contextpage/Contextapi";

const JobcategoryUpdated = ({ handleUpdate }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    jobcategory_image: null,
    
  });

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleimage = (e) => {
    const imageFile = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      jobcategory_image: imageFile,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);

    if (
      formData.jobcategory_image &&
      typeof formData.jobcategory_image !== "string"
    ) {
      formDataToSend.append("jobcategory_image", formData.jobcategory_image);
    }
    try {
      const res = await axios.post(
        `${domainname}/api/add-jobcategory`,
        formDataToSend,
        {
          params: {
            Client_ID: keyid,
          },
        }
      );
      // console.log("Job Categiory Add ", res);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
    
        text: res.data?.message || " Add Job category ",
      });
      handleUpdate();
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response?.data?.message || "Server error, please try again.",
      });
      console.log(error, "something is wrong");
    }
  };
  return (
    <div className="container comapnay_form">
      <div className="upbtn">
        <button onClick={handleUpdate} title="back">
          {" "}
          <FaArrowLeft />
          back{" "}
        </button>
      </div>
      <div className="row mt-2">
        <div className="col-lg-9 mt-5">
          <div className="registerWrapper">
            <div className="twm-tabs-style-2">
              {/* {/Signup Candidate Content/} */}
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-12 ">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="title">
                      {" "}
                      Title
                    </label>
                    <input
                      name="title"
                      type="text"
                      className="form-control"
                      placeholder="Jobcategory title"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="frmlabel" htmlFor="logo">
                      Logo
                    </label>
                    <input
                      name="jobcategory_image"
                      type="file"
                      className="form-control file-control"
                      onChange={handleimage}
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <label htmlFor="terms" className="form-label">
                    Description
                  </label>
                  <RichText
                    name="description"
                    placeholder={"description"}
                    value={formData.description}
                    onChange={handleRichTextChange}
                  />
                </div>
                <div className="col-md-12 ">
                  <button type="submit" className="site-button mt-3 mx-auto">
                    Add Job Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobcategoryUpdated;
