import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { MdSettingsApplications } from "react-icons/md";
import { useContext, useEffect, useMemo, useState } from "react";
import { IoExit } from "react-icons/io5";
import axios from "axios";
import Contextapi from "../../Contextpage/Contextapi";
import Swal from "sweetalert2";
import { FaUsersViewfinder } from "react-icons/fa6";
import empimgae from "../../../assets/images/user.png"
const routes = [
  {
    path: "/employee-dashboard/home",
    name: "Profile",
    icon: <FaUser />,
    title: "Profile",
  },

  {
    path: "/employee-dashboard/view-jobs",
    name: "View Jobs",
    icon: <FaUsersViewfinder />,
    title: "View Job",
  },
  {
    path: "/employee-dashboard/view-jobapplication",
    name: "Job Application",
    icon: <MdSettingsApplications />,
    title: "Job Application",
  },
];

const EmpSidebar = ({ children }) => {

  const Id = useMemo(() => localStorage.getItem("id") || "", []);
  const id = Id.replace(/"/g, "");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const contextData = useContext(Contextapi);
  const domainName = contextData.domainapi;
  const keyId = contextData.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyId,
    }),
    [keyId]
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${domainName}/api/get-employee/${id}`,
          { params }
        );

        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetch();
  }, [params, domainName, id]);


  const navigate = useNavigate();

  const logoutfun = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('path');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('id');

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: "Log-out successfully"
    });
    navigate("/");
  };

  // console.log("employee data ", data)
  return (
    <>
      <div className="main-container">

        <div className="employee-sidebar">

          <div className="employee-profile d-flex justify-content-center ">
            <Link to="/" title="Home Page" className="  justify-content-center ">
              <div className="user-employee-profile">
                <img className="img-fluid" src={data.profile_image ? data.profile_image : empimgae} />
              </div>
              <h4 className="m-0 py-2 profile_name_emp text-center">{data.name ? data.name : "**********"}</h4>
            </Link>

          </div>

          <div className="user-details-tab ms-4">
            <div className="emp-main-route">
              {
                routes.map((item , index) => (
                  <NavLink to={item.path} title={item.title} key={index} className="emp-route">
                    <div className="emp-details-tabs py-2 my-2 px-2 fs-6  d-flex align-items-center gap-2">
                      <span className="fs-5"> {item.icon}</span>
                      <span > {item.name} </span>
                    </div>
                  </NavLink>
                )
                )
              }
            </div>
            <div className="employee-last-details py-3 px-2 fs-6 d-flex  align-items-center gap-2" title="Logout">
              <button className="border-0 bg-transparent" onClick={logoutfun}> <span> <IoExit /></span> <span> Logout </span> </button>
            </div>
          </div>
        </div>


        <main className="emp-centerbar">
          {children}
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default EmpSidebar;