import MUIDataTable from 'mui-datatables'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Contextapi from '../../../Contextpage/Contextapi';
import axios from 'axios';

const AdminPlanDetails = () => {
    const [data, SetData] = useState([]);

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyid = contextdata.keyID;

    const params = useMemo(
        () => ({
            Client_ID: keyid,
        }),
        [keyid]
    );



    useEffect(() => {
        try {
            const getSubscription = async () => {
                const res = await axios.get(`${domainname}/api/get-subscription-plan`, {
                    params,
                });

                if (res.status === 200) {

                    console.log("admin subscription ", res.data);
                    SetData(res.data);
                    // setLoading(!loading)

                }

            }
            getSubscription();

        } catch (error) {

            console.log("api Error", error)

        }

    }, [domainname])



    const columns = [
        {

            label: "S No.",
            name: "id",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, action) =>
                    // console.log(action),
                    value ? action.rowIndex + 1 : " "
            }

        },
        {
            label: "Title",
            name: "title",

        },
        {
            label: "Days",
            name: "days",


        },
        {
            label: "Price",
            name: "price",

        },
        {
            label: "Description",
            name: "description",
            options: {
                customBodyRender: (value) => (
                    <tr dangerouslySetInnerHTML={{ __html: value }} />

                )
            }

        },


    ]

    const options = {
        filterType: "checkBox",
        selectableRows: "none",
        rowsPerPageOptions: [5, 10, 15, 20, 15, 30]

    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6">
                    <nav aria-label=" breadcrumb ">
                        <ol className="breadcrumb  p-3 m-0">
                            <li className="breadcrumb-item ">
                                <Link to="/admin-dashboard/home" className="text-decoration-none ">
                                    Admin {'/'}
                                </Link>
                            </li>
                            <li className="ms-1" aria-current="page">
                                Subscription Plan Deatils
                            </li>
                        </ol>
                    </nav>
                </div>

                <div className='col-12'>
                    <MUIDataTable
                        title="Plan details "
                        data={data}
                        columns={columns}
                        options={options}
                    />

                </div>
            </div>
        </div>
    )
}

export default AdminPlanDetails