import React, { useState } from 'react';
import loginpic from '../../assets/images/loginpic.png';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

const CompanyLogin = () => {
    const [showModal, setShowModal] = useState(false);
    const [emailForReset, setEmailForReset] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [viewPassword, setViewPassword] = useState(false);

    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const res = await axios.post('https://portal.rgshramikmitra.com/api/company-login', formData);
            // console.log("login-response", res);
            const data = res.data;

            if (res.status === 200) {
                localStorage.setItem('token', data.token.plainTextToken
                );
                localStorage.setItem('id', JSON.stringify(data.id));
                localStorage.setItem('name', JSON.stringify(data.name));
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Logged in successfully',
                });
                if (data.role === "company") {
                    localStorage.setItem('path', JSON.stringify('company-dashboard/home'));
                    navigate('/company-dashboard/home');
                }
                // console.warn("login data", data);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Try Again",
                    text: res.data.comment || 'Useremail or Password Incorrect',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Try Again",
                text: error.response.data.comment || 'Useremail or Password Incorrect',
            });
            console.log(error, 'something is wrong');
        }
    };

    const handleForgotPassword = () => {
        setShowModal(true);
    };
    const handleResetPassword = () => {
        if (!emailForReset) {
            Swal.fire({
                icon: "error",
                title: "Email Required",
                text: 'Please enter your registered email address.',
            });
            return;
        }

        axios.post('https://portal.rgshramikmitra.com/api/reset-company-password', { email: emailForReset })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: response.data?.message || 'Password reset instructions sent',
                    text: 'Please check your email for further instructions.',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
                setShowModal(false);
                // console.log("forget response ", response)
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response?.data?.error || 'An error occurred, please try again.',
                });
                console.error("Forget API ", error)
            });
    };

    return (
        <div id="main-wrapper" className="container">
            <div className="row justify-content-center">
                <div className="login col-xl-10">
                    <div className=" card ">
                        <div className="  card-body p-0">
                            <div className="row no-gutters">
                                <div className="col-lg-6  d-flex align-items-center">
                                    <div className="lpding">

                                        <h6 className="h5 mb-3">Welcome back!</h6>

                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group mb-4">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="text"
                                                    name='email'
                                                    placeholder="Enter username"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-5 mainpass">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    name='password'
                                                    type={viewPassword ? 'text' : 'password'}
                                                    placeholder="Enter your password"
                                                    className="form-control passfiled"
                                                    onChange={handleChange}
                                                />
                                                <p className='password-eye' onClick={passwordViewHandler}> {viewPassword ? <FaEye /> : <FaRegEyeSlash />} </p>

                                                <button type="button" onClick={handleForgotPassword} className="forgot-link forgot-btn btn float-end my-3">
                                                    Forgot password?
                                                </button>
                                            </div>
                                            <div className="col-md-12">
                                                <button type="submit" className="site-button">
                                                    Login Now
                                                </button>
                                            </div>

                                            <p className="text-muted mt-3 mb-0">
                                                Don't have an account?{" "}
                                                <Link to="/registration" className="text-primary ml-1">
                                                    Register
                                                </Link>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-none mt-5 d-lg-inline-block">
                                    <img className='cimg' src={loginpic} alt="Login" title='loginpage' />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Forgot Password Modal */}
                <Modal show={showModal} onHide={() => setShowModal(false)} className='forget-content'>
                    <Modal.Header closeButton>
                        <Modal.Title>Reset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type='email'
                            placeholder='Enter Your Registered Email'
                            className="form-control "
                            value={emailForReset}
                            onChange={(e) => setEmailForReset(e.target.value)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleResetPassword}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default CompanyLogin;
