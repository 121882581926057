import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
// import Reactpaginate from "react-paginate";
// import Swal from "sweetalert2";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import Contextapi from "../../../Contextpage/Contextapi";
// import Profileofadviseruser from "./Profileofadviseruser";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";

const AllAdvisor = () => {
  const [data, SetData] = useState([]);
  const [Loading, setLoading] = useState(true);
  // const [input, Setinput] = useState("");
  // const navigate = useNavigate();

  // react pagination
  // const [pageNumber, setPageNumber] = useState(0);
  // const userPerPage = 5;
  // const pageCount = Math.ceil(data.length / userPerPage);
  // const offset = pageNumber * userPerPage;

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-all-agent`, {
          params,
        });

        // console.log(response);

        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [domainname, params]);

  // const handleDelete = async (id) => {
  //   try {
  //     await axios.post(`${domainname}/api/delete-agent/${id}`, {
  //       Client_ID: keyid,
  //     });
  //     const Toast = Swal.mixin({
  //       toast: true,
  //       position: "top-end",
  //       showConfirmButton: false,
  //       timer: 2000,
  //       timerProgressBar: true,
  //       didOpen: (toast) => {
  //         toast.addEventListener("mouseenter", Swal.stopTimer);
  //         toast.addEventListener("mouseleave", Swal.resumeTimer);
  //       },
  //     });
  //     Toast.fire({
  //       icon: "success",
  //       title: "sucessfully Deleted",
  //     });
  //     navigate("/admin-dashboard/agent/all-agent");
  //   } catch (error) {
  //     console.error("There was a problem with your delete operation:", error);
  //   }
  // };


  const handleEdit = async (id, value) => {

    console.log(id, value)
    const active = value;
    try {
      const res = await axios.post(`${domainname}/api/update-agent-status/${id}`, { active }, {

        params: {
          Client_ID: keyid,
        }

      });

      console.log("data update ", res);
      console.log("id", id)

      const updatedData = data.map(item =>
        item.agent_id === id ? { ...item, active } : item
      );

      console.log("updatedata", updatedData)

      SetData(updatedData);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: res.data.message,
      });
    } catch (error) {

      console.error("There was a problem with your update operation:", error);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: error.res.data.message,
      });
    }
  };


  const columns = [
    {
      name: "agent_id",
      label: "S No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, action) =>
          // console.log(action),
          value ? action.rowIndex + 1 : " "
      }

    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, action) =>
        (
          <Link title={`${action.rowData[1]} view-profile`}
            // employee name
            to={`/admin-dashboard/agent/all-agent/user-profile/advisor/${action.rowData[0]}`}>
            {/* employee_id */}
            <span >{value}</span>
          </Link >
        )
      }

    },
    {
      name: "email",
      label: "Email",

    },
    {
      name: "mobile",
      label: "Mobile",

    },
    {
      name: "active",
      label: "Active",
      options: {
        customBodyRender: (item, action) => (
          <select
            className={`form-select ${item == 'true' ? 'text-success' : 'text-danger'}`}
            value={item}
            onChange={(e) => handleEdit(action.rowData[0], e.target.value)}
          >
            <option value='true'>True</option>
            <option value='false'>False</option>
          </select>
        )
      }

    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,

        customBodyRender: (value, action) => (
          <div>
            <Link title={`${action.rowData[1]} view-profile`}
              // employee name
              to={`/admin-dashboard/agent/all-agent/user-profile/advisor/${action.rowData[0]}`}>
              {/* employee_id */}
              <button type="button" className="btn btn-primary ms-2"><FaEye /></button>
            </Link >

            {/* <button
              type="button"
              className="btn btn-danger ms-2"
            // onClick={() => handleDelete(item.agent_id)}
            >
              <FaTrashAlt />
            </button> */}


          </div>)

      },
    }
  ];
  const options = {
    selectableRows: "none",
    filterType: 'checkbox',
    rowsPerPageOptions: [5, 10, 15, 20, 25, 30],
  };
  if (Loading) {
    <div className="container">
      <div className="row p-5">
        <LinearProgress />
      </div>
    </div>;
  }
  // console.log("agent", data)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="shadow">
          <div className="row">
            <div className="col-lg-6">
              <nav aria-label=" breadcrumb ">
                <ol className="breadcrumb  p-3">
                  <li className="breadcrumb-item ">
                    <Link
                      to="/admin-dashboard/home"
                      className="text-decoration-none ">
                     Admin
                    </Link>
                  </li>
                  <li className="ms-1 breadcrumb-item active" aria-current="page">
                    {'/'} Agents
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="col-lg-6">
              <div className="row g-3 p-3">
                <div className=" col-lg-10 p-3 m-auto">
                  <label htmlFor="search" className="visually-hidden">
                    Password
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Search here"
                      onChange={(e) => Setinput(e.target.value)}
                    />
                    <FaSearch id="srh-icon" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="table-responsive">
            <table className="table table-striped table-hover text-center ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">logo</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Secondry Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Action</th>

                </tr>
              </thead>
              <tbody>
                {data &&
                  data
                    .filter((item) =>
                      input.toLowerCase() === ""
                        ? true
                        : item.name.toLowerCase().includes(input.toLowerCase())
                    )
                    .slice(offset, offset + userPerPage)
                    .map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td> <Link to={`/admin-dashboard/agent/all-agent/user-profile/advisor/${item.agent_id}`} title={`${item.name} view-profile`} >{item.name} </Link></td>
                        <td>{item.email}</td>
                        <td>{item.mobile}</td>
                        <td className="">
                          <Link
                            title={`${item.name} view-profile`}
                            to={`/admin-dashboard/agent/all-agent/user-profile/advisor/${item.agent_id}`}
                          >
                            <button
                              type="button"
                              className="btn btn-primary ms-2"
                            >
                              <FaEye />
                            </button>
                          </Link>
                          <button
                            type="button"
                            className="btn btn-danger ms-2"
                            onClick={() => handleDelete(item.agent_id)}>
                            <FaTrashAlt />
                          </button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="pagination pagination-lg justify-content-center text-center mt-3">
            <Reactpaginate
              nextLabel={<>Next </>}
              pageCount={pageCount}
              onPageChange={(selectedPage) =>
                setPageNumber(selectedPage.selected)
              }
              disabledClassName={"pagination__link--disabled"}
              previousLabel="Previous"
              containerClassName="pagination justify-content-center pagination-lg pagination-sm pagination-col"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active  pb-3"
              pageClassName="page-item ml-1 mr-1"
              pageLinkClassName="page-link"
            />
          </div> */}


          <MUIDataTable
            title={"Agent List"}
            data={data}
            columns={columns}
            options={options}

          />
        </div>
      </div>
    </div>
  );
};

export default AllAdvisor;
