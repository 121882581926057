import React from 'react'
import { Link } from 'react-router-dom'

const ErrorHandle = () => {
  return (
    <div className=''>
       <div className="breadcrumbimg wt-bnr-inr overlay-wraper bg-center">
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">!Opps Error</h2>
                            </div>
                        </div>
                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li>
                                    <Link title='Error | Back To Home page ' to="/">Home</Link>
                                </li>
                                <li>Error Somting Went Wrong</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='error-page'>
                <div className="main-wrapper">
                    <div className="error-box">
                        <h1>500</h1>
                        <h3 className="h2">
                            <i className="fas fa-exclamation-triangle" /> Oops! Error Occurred!
                        </h3>
                        <p className="h4 font-weight-normal">
                            This time Server Not Responed.
                        </p>
                        <Link to="/" title='Error | Back To Home page ' className="btn">
                           <button className='site-button'> Back to Home</button>
                        </Link>
                    </div>
                </div>


            </div>
    </div>
  )
}

export default ErrorHandle
