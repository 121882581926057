import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaEdit, FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import Contextapi from '../../../Contextpage/Contextapi';
import EditSubscription from './EditSubscription';
import AddSubscription from './AddSubscription';
import Swal from 'sweetalert2';


const AdminViewSubscription = () => {
    const [data, SetData] = useState([]);
    const [editData, setEditData] = useState([])
    // const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyid = contextdata.keyID;

    const params = useMemo(
        () => ({
            Client_ID: keyid,
        }),
        [keyid]
    );



    // console.log("final data ", data);
    
    useEffect(() => {
        try {
            const getSubscription = async () => {
                const res = await axios.get(`${domainname}/api/get-subscription-plan`, {
                    params,
                });

                // console.log("admin subscription ", res.data);F
                if (res.status === 200) {
                    SetData(res.data);
                    // setLoading(!loading)
                }

            }
            getSubscription();

        } catch (error) {
            console.log("api Error", error)
        }

    }, [domainname, isOpen])

    const navigate = useNavigate();


    const handelAdd = () => {
        setIsOpen(!isOpen);

    }

    const handelEdit = (data) => {
        setEditData(data);
        setIsOpen(!isOpen);
        setIsEdit(!isEdit);

        // console.log("editData", data);


    }
    const handelDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            console.log(result);
            if (result.isConfirmed) {
                try {
                    const res = await axios.post(
                        `${domainname}/api/delete-subscription-plan/${id}`,
                        {
                            Client_ID: keyid,
                        }
                    );

                    if (res.status === 200) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        });
                        Toast.fire({
                            icon: "success",
                            title: "sucessfully Deleted",
                        });

                        navigate("/admin-dashboard/subscription/view-subscription");
                    }
                } catch (error) {
                    console.error("There was a problem with your delete operation:", error);
                }
            }
        }).catch((err) => console.log(err))
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <nav aria-label=" breadcrumb ">
                        <ol className="breadcrumb  p-3 m-0">
                            <li className="breadcrumb-item ">
                                <Link to="/admin-dashboard/home" className="text-decoration-none ">
                                    Admin {'/'}
                                </Link>
                            </li>
                            <li className="ms-1" aria-current="page">
                                Subscription Plan
                            </li>
                        </ol>
                    </nav>
                </div>
                {
                    isOpen ? (
                        isEdit ? (
                            <EditSubscription handelEdit={handelEdit} data={editData} id={editData[0]} />
                        ) : (
                            <AddSubscription handelAdd={handelAdd} />
                        )
                    ) :
                        <div className="row">
                            <div className="">
                                <div className="col-lg-12 d-flex justify-content-end">
                                    <div className="row g-3 p-3">
                                        <div className=" col-lg-10 p-3 m-auto">
                                            <div className='subscription-add'>
                                                <button className='site-button px-3 d-flex ' onClick={handelAdd}> <span> <FaPlus className='text-light me-2' /></span> Add </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <MUIDataTable
                                title="Subscription plans"
                                data={data}
                                columns={columns}
                                options={options}
                            /> */}
                            <div className='table-responsive '>
                                <table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Days</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Discount%</th>
                                            <th scope="col">Dis. Price</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    {data.length > 0
                                        ?
                                        (
                                            <tbody>
                                                {data && data.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.days}</td>
                                                        <td>{item.price}</td>
                                                        <td>{item.discount}</td>
                                                        <td>{(item.price * (1 - item.discount / 100)).toFixed(2)}</td>
                                                        <td>
                                                            <button type="button" title={`edit ${item.title}`} className=" me-1 btn btn-primary" onClick={() => handelEdit(item)}><FaEdit /></button>
                                                            <button type="button" title={`delete ${item.title}`} className=" ms-1 btn btn-danger" onClick={() => handelDelete(data.id)}><FaTrash /></button>
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        )
                                        :
                                        (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="8" className="no-data-message">No jobs application</td>
                                                </tr>
                                            </tbody>
                                        )}
                                </table>

                            </div>

                        </div>

                }
            </div>
        </div>
    )
}

export default AdminViewSubscription