import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaCheck, FaEye,  FaSearch } from "react-icons/fa";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Regstrationemp from "./Regstrationemp";
import Contextapi from "../../../Contextpage/Contextapi";
import { RxCross1 } from "react-icons/rx";
import empimage from "../../../../assets/images/user.png"
import { TiArrowBack } from "react-icons/ti";

const Viewemployee = () => {
  const [data, SetData] = useState([]);
  const [isView, setIsView] = useState(false);

  const [viewData, setViewData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [input, Setinput] = useState("");
  const getid = useMemo(() => localStorage.getItem("id") || "", []);
  const id = getid && getid ? getid.replace(/"/g, "") : "";

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyID = contextdata.keyID;
  const params = useMemo(
    () => ({
      Client_ID: keyID,
    }),
    [keyID]
  );
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${domainname}/api/get-my-employee/${id}`,
          { params }
        );
        SetData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [navigate, params, domainname, id, formOpen]);

  const handleEdit = async (Eid, value) => {
    const active = value;
    try {
      await axios.post(`${domainname}/api/update-my-employee-status/${Eid}/${id}`, { active }, {
        params: {
          Client_ID: keyID,
        },
      });
      const updatedData = data.map(item =>
        item.employee_id === Eid ? { ...item, active } : item
      );
      SetData(updatedData);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "Change Status successfully",
      });
    } catch (error) {
      console.error("There was a problem with your update operation:", error);
    }
  };

  const handelView = async (value) => {
    // console.log("vakluew  ", value)

    const res = await axios.get(`${domainname}/api/get-my-employee/${id}`, {
      params: {
        Client_ID: keyID,
      },
   })

    // console.log(res)

    if (res.status === 200) {

      const filterData = res.data.filter((item) => item.employee_id == value.employee_id);

      setViewData(filterData);
      setIsView(true)
      // console.log("vewData ", viewData)
    }

  }

  if (Loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid upshowing admin_profile comany-dashboard">
      <div className="row">
        <div className="col-lg-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
              <li className="breadcrumb">
                <Link to="/agent-dashboard" title="home" className="text-decoration-none">
                  Agent {'/'}
                </Link>
              </li>
              {/* <li className="breadcrumb">
                <Link to="/agent-dashboard" title="agent-dashboard" className="text-decoration-none">
                  Agent-Dashboard {'/'}
                </Link>
              </li> */}
              <li className="breadcrumb active">
                Employees
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {
        isView ?
          <div className="row">
            <div className="col-12 d-flex justify-content-end upbtn  ">
              <button className="btn-sm site-button" onClick={() => setIsView(!isView)}> <TiArrowBack /> Back</button>
            </div>
            <div className="col-lg-12 col-md-12 col ">
              <div className="company-view-details shadow py-4 mb-3">
                <h5 className="job-view m-2"> Employee Details  :  </h5>
                <div className="personal-details">
                  <div className="comapny-details">
                    <div className='row'>
                      {
                        viewData && viewData.map((item) => (
                          <div className="agent-employee-view-data">
                            <div className="emp-image  d-flex justify7-content-center w-25 my-3">
                              <img className="" src={item.profile_image ? item.profile_image : empimage} />
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className='my-2' title={item.name}>
                                  <strong>Name : </strong> <span> {item.name || "No Name"} </span>
                                </div>
                                <div className='my-2' title={item.email}>
                                  <strong>Email : </strong> <span> {item.email || "No Email"} </span>
                                </div>
                                <div className='my-2' title={item.dob}>
                                  <strong>DOB : </strong> <span> {item.dob || "No DOB"} </span>
                                </div>
                                <div className='my-2' title={item.mobile}>
                                  <strong>Mobile : </strong> <span> {item.mobile || "No Mobile"} </span>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className='my-2' title={item.address}>
                                  <strong>Address : </strong> <span> {item.address || "No Address"} </span>
                                </div>
                                <div className='my-2' title={item.city}>
                                  <strong>City : </strong> <span> {item.city || "No City"} </span>
                                </div>
                                <div className='my-2' title={item.state}>
                                  <strong>State : </strong> <span> {item.state || "No State"} </span>
                                </div>
                                <div className='my-2' title={item.pincode}>
                                  <strong>Pincode : </strong> <span> {item.pincode || "No Pincode"} </span>
                                </div>
                              </div>

                              <div className="col-4">
                                <div className='my-2' title={item.account_holder_name}>
                                  <strong>Account Holder Name : </strong> <span> {item.account_holder_name || "No  Name "} </span>
                                </div>
                                <div className='my-2' title={item.account_number}>
                                  <strong>Account Number : </strong> <span> {item.account_number || "No Account No"} </span>
                                </div>
                                <div className='my-2' title={item.bank_name}>
                                  <strong>Bank Name : </strong> <span> {item.bank_name || "No Bank Name"} </span>
                                </div>
                                <div className='my-2' title={item.branch_name}>
                                  <strong>Branch Name : </strong> <span> {item.branch_name || "No Branch Name"} </span>
                                </div>
                              </div>
                            </div>


                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          :

          formOpen ? (
            <Regstrationemp handleUpdate={handleUpdate} />
          ) : (
            <div className="row">
              <div className="upbtn">
                <button onClick={handleUpdate} className="site-button">
                 Add
                </button>
              </div>
              <div className="col-lg-12">
                <div className="row justify-content-end g-3 p-3">
                  <div className="col-lg-4 p-3 main-section-search">
                    <label htmlFor="search" className="visually-hidden">
                      Search
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Search here"
                        onChange={(e) => Setinput(e.target.value)}
                      />
                      <FaSearch id="srh-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <table className="table table-striped table-hover text-center shadow">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Profession</th>
                      <th scope="col">Active</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data
                        .filter((item) =>
                          input.toLowerCase() === ""
                            ? true
                            : item.name.toLowerCase().includes(input.toLowerCase())
                        )
                        .map((item, index) => (
                          <tr  key={item.employee_id} className="table-row--align">
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                            <td>{item.profession}</td>
                            <td className="">
                              <select
                                className={`form-select ${item.active === 'true' ? 'text-success' : 'text-danger'}`}
                                value={item.active}
                                onChange={(e) => handleEdit(item.employee_id, e.target.value)}
                              >
                                <option value='true'> <FaCheck className="text-success" /> True</option>
                                <option value='false'> <RxCross1 className="text-danger" /> False</option>
                              </select>
                            </td>
                            <td> <button className="btn btn-primary" onClick={() => handelView(item)}><FaEye className="" /> </button> </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="pagination pagination-lg justify-content-center text-center mt-3"></div>
              </div>
            </div>
          )}
    </div >
  );
};

export default Viewemployee;