import React, { useEffect, useState } from 'react';

const CountUp = ({ target }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count < target) {
      const interval = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 100); 

      return () => clearInterval(interval); 
    }
  }, [count, target]);

  return <span>{count}</span>;
};

export default CountUp;
