import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LinearProgress } from '@mui/material';
import Contextapi from '../../Contextpage/Contextapi';
import RichText from '../../Admin-dashboard/pages/Subpages/RichTexteditor';

const CmpAddTestimonial = ({ handleAdd , profiledata }) => {
    const getid = localStorage.getItem('id') || '';
    const id = getid.replace(/"/g, '');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: profiledata.name || '',
        description: '',
        rating: '',
        submitted_by: id,
        testimonial_image: profiledata.company_logo || "https://www.rgshramikmitra.com/static/media/logo-png.6aa7f2a0bf0a46bceda7.png"
    });
    const contextData = useContext(Contextapi);
    const domainName = contextData.domainapi;
    const ClientID = contextData.keyID;

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            testimonial_image: imageFile,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('rating', formData.rating);
            formDataToSend.append('submitted_by', formData.submitted_by);
            formDataToSend.append('testimonial_image', formData.testimonial_image);

            // if (formData.testimonial_image && typeof formData.testimonial_image !== 'string') {
            //     formDataToSend.append('testimonial_image', formData.testimonial_image);
            // }

            const response = await axios.post(`${domainName}/api/add-company-testimonial`, formData, { params: { Client_ID: ClientID } });
            // console.log(response)
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    text: "Add testimonial successfully!",
                  });
                handleAdd();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error in adding the testimonial. Please try again.",
                });
                handleAdd();
            }
        } catch (error) {
            console.error("Error adding testimonial:", error);
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-5'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className='upbtn'>
                <button onClick={handleAdd}>Back</button>
            </div>
            <form className='shadow p-2' onSubmit={handleSubmit} encType='multipart/form-data'>
                <div className="row m-3">
                    <div className="col-lg-6">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            name='name'
                            onChange={handleInputChange}
                            value={formData.name}
                            className="form-control"
                            placeholder="Enter name"
                            readOnly
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="rating" className="form-label">Rating</label>
                        <select
                            name='rating'
                            onChange={handleInputChange}
                            value={formData.rating}
                            className="form-select"
                            required
                        >
                            <option value="">Select Rating</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>

                    {/* <div className="col-lg-4">
                        <label htmlFor="testimonial_image" className="form-label">Image</label>
                        <input
                            type="file"
                            name='testimonial_image'
                            onChange={handleImageChange}
                            className="form-control"
                            accept='*'
                        />
                    </div> */}
                    <div className="col-lg-12">
                        <label htmlFor="description" className="form-label">Description</label>
                        <RichText
                            name="description"
                            placeholder="Enter description"
                            value={formData.description}
                            onChange={handleRichTextChange}
                        />
                    </div>
                </div>
                <div className='d-flex float-right'>
                    <button type='submit' className="btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default CmpAddTestimonial;
