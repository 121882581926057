import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {  FaEye, FaSearch } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Contextapi from '../../Contextpage/Contextapi';

const JobrequestStatus = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [input, setInput] = useState("");
    const [category, setCategory] = useState([]);
    const [statusFilter, setStatusFilter] = useState("all");
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 8;
    const offset = pageNumber * usersPerPage;

    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const id = getid && getid ? getid.replace(/"/g, '') : '';
    const contextData = useContext(Contextapi);
    const domainName = contextData.domainapi;
    const keyID = contextData.keyID;
    const params = useMemo(() => ({ Client_ID: keyID }), [keyID]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${domainName}/api/get-jobrequest/${id}`, { params });
                const categoryResponse = await axios.get(`${domainName}/api/get-jobcategory`, { params });
                setCategory(categoryResponse.data);
                setData(response.data.filter(item => item.payment_status === 'success'));

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [domainName, params, id]);

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    if (!data) {
        return (
            <div className='container'>
                <div className='row p-5 mt-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    const handleStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'btn-warning';
            case 'approved':
                return 'btn-primary';
            case 'reject':
                return 'btn-danger';
            default:
                return '';
        }
    };

    const filteredData = data.filter((item) =>
        input.toLowerCase() === '' ? true : item.job_title.toLowerCase().includes(input.toLowerCase())
    ).filter((item) =>
        statusFilter === 'all' ? true : item.status == statusFilter
    );

    const pageCount = Math.ceil(filteredData.length / usersPerPage);
    const totalJobRequest = filteredData.length;

    return (
        <div className="container-fluid upshowing">
            <div className="row">
                <div className="col-lg-6 mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb"><Link to="/" title='Home' className='text-decoration-none'>Home</Link></li> {'/'}
                            <li className="breadcrumb"><Link to="/company-dashboard/home" title='companay-dashboard' className='text-decoration-none'>Company-Dashboard</Link></li> {'/'}
                            <li className="breadcrumb ms-1" aria-current="page">Job-Requests</li>
                        </ol>
                    </nav>
                </div>

                <div className="row">

                    <div className='col-lg-12 col-md-6'>
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center">
                            <span className="woocommerce-result-count-left">
                                {totalJobRequest} Job Requests
                            </span>
                            <div className="woocommerce-ordering twm-filter-select" method="get">
                                <span className="woocommerce-result-count">Sort By</span>
                                <select className="wt-select-bar-2" data-live-search="true" data-bv-field="size" onChange={(e) => setStatusFilter(e.target.value)}>
                                    <option value='all'>All Job Requests</option>
                                    <option value='pending'>Pending</option>
                                    <option value='approved'>Approved</option>
                                    <option value='reject'>Reject</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 d-flex justify-content-end mb-3">
                        <div className="row">
                            <div className="col-lg-10 main-section-search">
                                <label htmlFor="search" className="visually-hidden">search</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                    <FaSearch id='srh-icon' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover text-center ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Title</th>
                                <th scope="col">Category</th>
                                <th scope="col">Vacany</th>
                                <th scope="col">Subscription Days</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>

                        {filteredData.length > 0 ? (
                            <tbody>
                                {filteredData.slice(offset, offset + usersPerPage).map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.transaction_id}</td>
                                        <td>{item.amount}</td>
                                        <td >{item.job_title || '---'}</td>
                                        <td>{category ? category.map((categoryItem) =>
                                            categoryItem.id == item.job_category && categoryItem.title
                                        ) : "----"}</td>

                                        <td>{item.total_post}</td>
                                        <td>{item.days || '---'}</td>

                                        <td className=''>
                                            <button type='button' title={item.job_title || "title"} className={`btn ${handleStatusColor(item.status)} text-white ms-2`} >{item.status}</button>

                                        </td>
                                        <td>
                                            <Link
                                                to={`/company-dashboard/job/`}
                                                state={{ job: item }}
                                                title={item.job_title || "title"}
                                                className='btn btn-info'>
                                                <FaEye className='text-white' />
                                            </Link>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="12" className="no-data-message">No jobs available</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                    <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                        <ReactPaginate
                            nextLabel={<>Next </>}
                            pageCount={pageCount}
                            onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                            disabledClassName={'pagination__link--disabled'}
                            previousLabel="Previous"
                            containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            activeClassName='active  pb-3'
                            pageClassName='page-item ml-1 mr-1'
                            pageLinkClassName='page-link'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobrequestStatus;