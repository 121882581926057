import axios from 'axios';
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {TiArrowBack} from 'react-icons/ti';
import Contextapi from '../../../Contextpage/Contextapi';
import Swal from "sweetalert2";
import RichText from '../Subpages/RichTexteditor';

const EditSubscription = ({handelEdit, data, id}) => {

  // console.log("edit compoonent Data", data, "Id Is ", id);

  const [newData,setNewData] = useState(data);

  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(() => ({Client_ID: keyid}), [keyid]);
  

  // useEffect(() => {
  //   let filterData = data.filter((item) => {
  //     return item.id === id;
  //   })

  //   console.log("filterData ", filterData);

  //   filterData.map((item) => {
  //     setNewData(item);

  //   })

  // }, [data, id])

  const [formData,
    setFormData] = useState({
    title: newData.title || "",
    days: newData.days || "",
    price: newData.price || "",
    discount: newData.discount || " ",
    description: newData.description || ""
  })
  useEffect(() => {
    setFormData(newData)
  }, [newData])

  const handelChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

  }
  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handelSubmit = async(e) => {
    try {
      e.preventDefault();
      

      let res = await axios.post(`${domainname}/api/update-subscription-plan/${id}`, formData, {
        params: {
          Client_ID: keyid
        }
      })
      // console.log(res.data)

      if (res.status == 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({icon: "success", title: "Successfully", text: "Subscription Plan updated "});
        handelEdit();
      }

    } catch (error) {

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
      Toast.fire({icon: "error", title: "Error", text: "Plan Can't updated "});

      console.log(error);
    }
  }

  return (
    <div className='container upshowing'>
      <div className='row'>

        <div className='col-lg-12 col-12 upbtn p-2 mb-5'>
          <div className='button '>
            <button className='btn float-end ' onClick={handelEdit}>
              <TiArrowBack/>Back
            </button>
          </div>
        </div>

        <div className='col-lg-12 my-3'>
          <form
            className=' subscription-edit-form shadow px-5 py-3 rounded row '
            onSubmit={handelSubmit}>
            <div className='form-subscriprion-data col-md-6'>
              <label className='form-group'>Title</label>
              <input
                className='form-control'
                name='title'
                type='text'
                placeholder='Enter you Title'
                value={formData.title}
                onChange={handelChange}/>
            </div>
            <div className='form-subscriprion-data col-md-6'>
              <label className='form-group'>Price</label>
              <input
                className='form-control'
                name='price'
                type='number'
                placeholder='Enter you Price'
                value={formData.price}
                onChange={handelChange}/>
            </div>
            <div className='form-subscriprion-data col-md-6'>
              <label className='form-group'>Days</label>
              <input
                className='form-control'
                name='days'
                type='number'
                placeholder='Enter you Days'
                value={formData.days}
                onChange={handelChange}/>
            </div>
            <div className='form-subscriprion-data col-md-6'>
              <label className='form-group'>Discount %</label>
              <input
                className='form-control'
                name='discount'
                type='number'
                min={0}
                max={100}
                placeholder='Enter you Discount'
                value={formData.discount}
                onChange={handelChange}/>
            </div>
            <div className='form-subscriprion-data'>
              <label className='form-group'>Description</label>
              <RichText
                name="description"
                placeholder={"description"}
                value={formData.description}
                onChange={handleRichTextChange}/> {/* <textarea className='form-control' rows='3' name='description' type='number' placeholder='Enter you Discription' value={formData.description} onChange={handelChange} /> */}
            </div>
            <div
              className='form-subscriprion-data d-flex justify-content-end align-items-center mt-2'>
              <input className='site-button' type='submit' value="Update"/>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

export default EditSubscription