import React, { useState, useEffect, useMemo, useContext } from "react";
import Siteconfigform from "../Subpages/Siteconfigform";
// import image from '../Assets/LOGO.jpg'
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import Contextapi from "../../../Contextpage/Contextapi";

const SiteConfig = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [siteconfig, setSiteconfig] = useState({});
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };
  const contextdata = useContext(Contextapi);
  const domainname = contextdata.domainapi;
  const keyid = contextdata.keyID;

  const params = useMemo(
    () => ({
      Client_ID: keyid,
    }),
    [keyid]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domainname}/api/get-site-config`, {
          params,
        });
        setSiteconfig(response.data);

        // console.log("siteconfig", response);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [params, domainname,formOpen]);

  if (loading) {
    return (
      <div className="container">
        <div className="row p-5">
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <section className="upshowing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12" id="nav">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb p-3">
                <li className="breadcrumb-item ">
                  <Link
                    to="/admin-dashboard/home"
                    title="Home page"
                    className="text-decoration-none ">
                    Admin
                  </Link>
                </li>
                <li className="ms-1" aria-current="page">
                 {'/'} Site-Config
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {formOpen ? (
          <Siteconfigform handleUpdate={handleUpdate} />
        ) : (
          <div>
            <div className="row align-items-center justify-content-center p-3">
              <div className="col-lg-6">
                <div className="pic">
                  <img
                    src={siteconfig.logo}
                    alt={siteconfig.seo_title}
                    title={siteconfig.seo_title}
                  />
                </div>
                <h6 className="mt-3">Logo image</h6>
              </div>
              <div className="col-lg-6 ">
                <div className="pic">
                  <img
                    src={siteconfig.fav_icon}
                    alt={siteconfig.seo_title}
                    title={siteconfig.seo_title}
                  />
                </div>
                <h6 className="mt-3">Fav icon</h6>
              </div>
            </div>
            <div className="col-2 upbtn">
              <button onClick={handleUpdate}>
                <FaPen className="py-0.5" /> Update
              </button>
            </div>
            <div className="row ">
              <div className="col-lg-12">
                <div className="card card_design p-3 mt-3">
                  <h3>Personal Info:</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Email </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p>{siteconfig.email}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Alternate Email </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.alternate_email}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Mobile </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p>{siteconfig.contact_number}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Alternate Mobile </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p>{siteconfig.alternate_contact_number}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Address : </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.address ? siteconfig.address : ""}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card  card_design p-3 mt-3">
                  <h3>Social links:</h3>
                  <div className="row">
                    <div className=" col-md-12">
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Twitter </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.twitter}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Whatsapp</h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.whatsapp}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Google Map </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.google_map}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Youtube </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.youtube}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>LinkedIn </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.linkedin}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Instagram </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.instagram}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Facebook </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.facebook}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card_design p-3 mt-3">
                  <h3>Seo Parameters</h3>
                  <div className="row">
                    <div className="first col-md-12">
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>SEO Title </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.seo_title}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>SEO Keywords</h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.seo_keywords}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>SEO Description </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.seo_description}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" first col-md-6 col-sm-12">
                          <h4>Canonical Links </h4>
                        </div>
                        <div className=" second col-md-6 col-sm-12">
                          <p> {siteconfig.canonical_links}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SiteConfig;
