import React, { useContext, useEffect, useMemo, useState } from 'react';
import Reactpaginate from 'react-paginate';
import { FaCheck, FaEye, FaPen, FaPlus, FaSearch, } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CommpanyAddjob from './CompanyAddjob';
import Contextapi from '../../Contextpage/Contextapi';
import { RxCross1 } from 'react-icons/rx';

const CompanyViewJobs = () => {
    const [data, SetData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Addform, setAddform] = useState(false);
    const [input, Setinput] = useState("");
    const [category, setCategory] = useState([]);
    const handleUpdate = () => {
        setAddform(!Addform);
    };
    const getid = useMemo(() => localStorage.getItem('id') || '', []);
    const id = getid && getid ? getid.replace(/"/g, '') : '';
    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyID = contextdata.keyID;
    const params = useMemo(() => ({
        Client_ID: keyID,
    }), [keyID]);
    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 8;
    const pageCount = Math.ceil(data.length / userPerPage);
    const offset = pageNumber * userPerPage;

    // ................................................
    const handleStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'btn-warning';
            case 'approved':
                return 'btn-primary';
            case 'reject':
                return 'btn-danger';
            default:
                return '';
        }
    };
    // console.log("job data ", data)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${domainname}/api/get-jobrequest/${id}`, { params });
                // console.log(response)
                const rescategory = await axios.get(`${domainname}/api/get-jobcategory`, { params });
                setCategory(rescategory.data);
                SetData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [Addform, params, domainname, id]);

    if (Loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (

        <div className="container-fluid upshowing">
            <div className="row">
                <div className="col-lg-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb p-3">
                            <li className="breadcrumb"><Link to="/" title='Home' className='text-decoration-none'>Home</Link></li> {'/'}
                            <li className="breadcrumb-item breadcrumb-hover"><Link to="/company-dashboard/home" title='company-dashboard' className='text-decoration-none'>Company-Dashboard</Link></li> {'/'}
                            <li className="breadcrumb-item active" aria-current="page">Job-Request</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                    <div className='row align-items-center'>
                        <div className='upbtn d-flex justify-content-end'>
                            {!Addform && <button className='d-flex align-items-center gap-2 site-button ' onClick={handleUpdate}> Add Job Request</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {Addform ? (
                <CommpanyAddjob handleUpdate={handleUpdate} />
            ) : (
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-end">
                        <div className="row g-3 py-2 px-3">
                            <div className=" col-lg-12 p-3  main-section-search">
                                <label htmlFor="search" className="visually-hidden">search</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => Setinput(e.target.value)} />
                                    <FaSearch id='srh-icon' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='table-responsive'>
                            <table className="table table-striped table-hover text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">TransactionID</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Vacany</th>
                                        <th scope="col">Subscription Days</th>
                                        <th scope="col">Payment status</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody>
                                        {data && data.filter((item) =>
                                            input.toLowerCase() === '' ? true : item.job_title.toLowerCase().includes(input.toLowerCase())
                                        ).slice(offset, offset + userPerPage).map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.transaction_id}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.job_title}</td>
                                                <td>{category.find((items) => items.id == item.job_category)?.title || '---'}</td>
                                                <td>{item.total_post}</td>
                                                <td>{item.days}</td>
                                                <td>
                                                    <button type='button' title={item.payment_status || "title"} className={`btn text-white `} >{item.payment_status === 'success' ? <FaCheck className='text-success' /> : <RxCross1 className='text-danger' />}</button>
                                                </td>
                                                <td>
                                                    <button type='button' title={item.job_title || "title"} className={`btn ${handleStatusColor(item.status)} text-white ms-1`} >{item.status}</button>
                                                </td>
                                                <td className=''>
                                                    {item.status == 'pending' && item.payment_status == 'success' && <Link to={`/company-dashboard/view-jobreqedit/${item.id}`} type='button' className='btn btn-primary ms-1'><FaPen /></Link>
                                                    }
                                                    <Link
                                                        to={`/company-dashboard/job/`}
                                                        state={{ job: item }}
                                                        title={item.job_title || "title"}
                                                        className='btn btn-info ms-1'>
                                                        <FaEye className='text-white' />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="12" className="no-data-message">No jobs request</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                    <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                        <Reactpaginate
                            nextLabel={<> Next </>}
                            pageCount={pageCount}
                            onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                            disabledClassName={'pagination__link--disabled'}
                            previousLabel="Previous"
                            containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            activeClassName='active pb-3'
                            pageClassName='page-item ml-1 mr-1'
                            pageLinkClassName='page-link'
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyViewJobs;