import axios from 'axios';
import React, { useContext, useMemo, useState } from 'react'
import { TiArrowBack } from 'react-icons/ti';
import Contextapi from '../../../Contextpage/Contextapi';
import RichText from '../Subpages/RichTexteditor';
import Swal from 'sweetalert2';

const AddSubscription = ({ handelAdd }) => {
    const [formData, setFormData] = useState({
        title: "",
        days: "",
        price: "",
        discount: "",
        description: "",
    });
    console.log("addSusbscription", formData)

    const contextdata = useContext(Contextapi);
    const domainname = contextdata.domainapi;
    const keyid = contextdata.keyID;

   

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })

    }
    const handleRichTextChange = (name, value) => {
        setFormData({ ...formData, [name]: value })

    }


    const handelSubmit = async (e) => {
        e.preventDefault();

        console.log("AddSubscriptionData", formData);
        try {
            const res = await axios.post(`${domainname}/api/add-subscription-plan`, formData, {
                params: {
                    Client_ID: keyid,
                }
            })

            console.log(res);

            if (res.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: "success",
                    title: "Successfully",
                    text: "Subscription Plan Added ",
                });
                handelAdd()
            }

        } catch (error) {
            console.log("Api Error", error)
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: "Error",
                text: "Subscription Plan can't Added ",
            });

        }
    }

    return (

        <div className='container'>
            <div className='row'>

                <div className='col-lg-12 col-12'>
                    <div className='button'>
                        <button className='btn btn-info float-end me-5' onClick={handelAdd}>
                            <TiArrowBack />Back
                        </button>
                    </div>
                </div>
                <div className='col-lg-12 my-3'>
                    <form className='subscription-edit-form shadow px-5 py-3 rounded row' onSubmit={handelSubmit}>
                        <h3 className='text-center my-2 text-secondary'>Add Subscription Plan </h3>
                        <div className='form-subscriprion-data col-md-6'>
                            <label className='form-group'>Title</label>
                            <input className='form-control' name='title' type='text' placeholder='Enter you Title' onChange={handelChange} />
                        </div>
                        <div className='form-subscriprion-data col-md-6'>
                            <label className='form-group'>Price</label>
                            <input className='form-control' name='price' type='number' placeholder='Enter you Price' onChange={handelChange} onWheel={(e) => e.target.blur()} />
                        </div>
                        <div className='form-subscriprion-data col-md-6'>
                            <label className='form-group'>Days</label>
                            <input className='form-control' name='days' type='number' placeholder='Enter you Days' onChange={handelChange} onWheel={(e) => e.target.blur()} />
                        </div>
                        <div className='form-subscriprion-data col-md-6'>
                            <label className='form-group'>Discount%</label>
                            <input className='form-control' name='discount' type='number' placeholder='Enter you Discount' onChange={handelChange} onWheel={(e) => e.target.blur()} />
                        </div>
                        <div className='form-subscriprion-data'>
                            <label className='form-group'>Description</label>
                            <RichText
                                name="description"
                                placeholder={"description"}
                                onChange={handleRichTextChange}
                            />
                            {/* <textarea className='form-control' rows='3' name='description' type='number' placeholder='Enter you Discription' onChange={handelChange} /> */}
                        </div>
                        <div className='form-subscriprion-data d-flex justify-content-start align-items-center mt-2'>
                            <input className='site-button' type='submit' value="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default AddSubscription